import React from 'react'
import { Toast, ToastBody, ToastHeader } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { Text } from '..'
import { useAppDispatch } from '../../helpers'
import s from './styles.module.scss'

import { toastSelectors } from './toast.selectors'
import { toastActions } from './toast.slice'

const ToastMessage = () => {
  const d = useAppDispatch()
  const { title, content, isVisible, type } = useSelector(toastSelectors.toast)

  return (
    <Toast
      className={`${s.toast} ${s[type]} fixed-top left-unset shadow-lg`}
      onClose={() => d(toastActions.hideToast())}
      delay={5000}
      show={isVisible}
      autohide
    >
      <ToastHeader className={s.header}>
        <Text size="14">{title || 'no-title'}</Text>
      </ToastHeader>
      <Text size="14" className={s.body}>
        {content || 'no-content'}
      </Text>
    </Toast>
  )
}

export default ToastMessage
