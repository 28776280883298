import cn from 'classnames'
import { FC } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import Text from '../../typography/text/Text'

import './index.scss'

interface IProps {
  field?: ControllerRenderProps<any, any>
  type?: 'number' | 'text' | 'date' | 'tel'
  as?: 'textarea'
  textAreaHeight?: number
  label?: string
  placeholder?: string
  disabled?: boolean
  error?: string
  hint?: string
  min?: string | number
  max?: string | number
  labelClassName?: string
  inputClassName?: string
  errorClassName?: string
  required?: boolean
}

const Input: FC<IProps> = ({
  field,
  type = 'text',
  as,
  textAreaHeight,
  label,
  placeholder,
  error,
  hint,
  min,
  max,
  disabled = false,
  required = false,
  labelClassName = '',
  inputClassName = '',
  errorClassName = '',
}) => {
  return (
    <div className="Input">
      {/* Label */}
      {label && (
        <div className="Input__label_container">
          <label className={cn([labelClassName, 'Input__label_label'])}>
            {label}{' '}
            {required && <span className="Input__label_asteriks">*</span>}
          </label>
          {hint && (
            <div title={hint} className="Input__label_hint">
              i
            </div>
          )}
        </div>
      )}

      {/* Input */}
      {as ? (
        <textarea
          className={cn([
            inputClassName,
            'Input__input',
            'Input__input_text-area',
            { ['Input__input_error']: error },
          ])}
          style={{ height: textAreaHeight + 'rem' || 'auto' }}
          placeholder={placeholder}
          disabled={disabled}
          {...field}
        />
      ) : (
        <input
          className={cn([
            inputClassName,
            'Input__input',
            { ['Input__input_error']: error },
          ])}
          type={type}
          placeholder={placeholder}
          min={min}
          max={max}
          disabled={disabled}
          {...field}
        />
      )}

      {/* Error */}
      {error && (
        <div className="Input__error_container">
          <Text
            type="b3"
            color="secondary-red-100"
            className={cn([errorClassName, 'Input__error_text'])}
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  )
}

export default Input
