import { authActions } from './../../../store/auth/authSlice'
import { AppDispatch } from './../../../store/store'
import type {
  CheckCodeReq,
  CheckCodeRes,
  SetPhoneReq,
  SetPhoneRes,
  UpdateTokenReq,
} from './types'
import { Headers, post } from '../../api'
import { setItem } from '../../../helpers/local-storage'
import { RSA_PUBLIC_KEY, WORKER_API } from '../../api/api.config'
import { toastActions } from '../../../components/toast/toast.slice'
import { importPublicKeyAndEncrypt } from '../../../helpers/RSAEncryption'

export const setPhone = async (
  reqData: Omit<SetPhoneReq, 'message'>,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = `/auth/set-phone`

  try {
    const encryptedNumber = await importPublicKeyAndEncrypt(
      RSA_PUBLIC_KEY,
      reqData.phone
    )
    const body: SetPhoneReq = {
      ...reqData,
      message: encryptedNumber,
    }
    const { data, status } = await post<SetPhoneRes>(url, body, {
      headers: Headers.Auth,
      baseURL: WORKER_API,
    })

    if (status === 200) {
      dispatch(authActions.setStep({ step: 'check-code', code: data.code }))
    }

    return data
  } catch (error: any) {
    const { status } = error.response

    switch (status) {
      case 400:
        dispatch(
          toastActions.showToast({
            key: 'set-phone-format-error',
            type: 'failed',
          })
        )
        break

      case 403:
        dispatch(
          toastActions.showToast({
            key: 'set-phone-access-error',
            type: 'failed',
          })
        )
        break

      case 500:
        dispatch(
          toastActions.showToast({
            key: 'server-error',
            type: 'failed',
          })
        )
        break

      default:
        break
    }
  }
}

export const checkCode = async (
  reqData: CheckCodeReq,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = `auth/check-code`

  try {
    const res = await post<CheckCodeRes>(url, reqData, {
      headers: Headers.Auth,
      baseURL: WORKER_API,
    })

    setItem('auth', JSON.stringify(res.data.auth))
    return res.data
  } catch (error: any) {
    const { status } = error.response

    switch (status) {
      case 400:
        dispatch(
          toastActions.showToast({
            key: 'check-code-error',
            type: 'failed',
          })
        )
        break
      case 500:
        dispatch(
          toastActions.showToast({
            key: 'server-error',
            type: 'failed',
          })
        )
        break
      default:
        break
    }
  }
}

export const updateToken = async (reqData: UpdateTokenReq) => {
  const url = `auth/update-token`
  const res = await post<CheckCodeRes>(url, reqData, {
    headers: Headers.Auth,
    baseURL: WORKER_API,
  })

  setItem('auth', JSON.stringify(res.data.auth))
  return res.data
}

export const logOut = async () => {
  const url = 'auth/logout'

  const { data } = await post(url, undefined, {
    baseURL: WORKER_API,
    headers: {
      accept: 'application/x-www-form-urlencoded',
      'Content-Type': ' multipart/form-data',
    },
  })

  return data
}
