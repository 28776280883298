import { GetMapCoordsBySearchReq } from './types'

export const getMapCoordsBySearch = async ({
  search,
  orderId,
}: GetMapCoordsBySearchReq) => {
  const reqParams: google.maps.places.TextSearchRequest = {
    query: search,
  }

  const service = new google.maps.places.PlacesService(
    document.createElement('div')
  )

  const getCoords = async (
    req: google.maps.places.TextSearchRequest
  ): Promise<{ lat: number; lon: number } | null> => {
    return new Promise((res) => {
      service.textSearch(req, (data) => {
        if (data) {
          const lat = data[0].geometry?.location?.lat()
          const lon = data[0].geometry?.location?.lng()
          if (lat && lon) res({ lat, lon })
          else res(null)
        } else {
          res(null)
        }
      })
    })
  }

  const coords = await getCoords(reqParams)

  return { coords, orderId }
}
