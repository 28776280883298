import type { Worker } from './types'

import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { geWorkersThunk, resolveWorkersThunk } from './workers.thunks'

export const workersAdapter = createEntityAdapter<Worker>({
  selectId: (worker: Worker) => worker._id,
  sortComparer: (a: Worker, b: Worker) =>
    a.createdAt.localeCompare(b.createdAt),
})

const initialState = {
  loading: null as null | boolean,
  error: null as null | string,
  ...workersAdapter.getInitialState(),
}

const workersSlice = createSlice({
  name: 'workers',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resolveWorkersThunk(builder)
  },
})

export type WorkersState = typeof initialState

export const workersReducer = workersSlice.reducer

export const workerApi = {
  geWorkers: geWorkersThunk,
}
