import { Image } from 'react-bootstrap'
import bgImg from '../../../assets/images/Background.png'
import s from './styles.module.scss'

import { FC } from 'react'
import { Outlet } from 'react-router'

export const AuthLayout: FC = ({ children }) => {
  return (
    <div className={s.wrapper}>
      <div className={s.map}></div>
      <main className={s.form}>
        <Outlet />
      </main>
    </div>
  )
}
