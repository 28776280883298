import { IUserProfileRes } from './types'
import { get } from '../../api'
import { WORKER_API } from '../../api/api.config'

export const getUserProfile = async () => {
  const url = `/worker/`
  try {
    const { data } = await get<IUserProfileRes>(url, {
      baseURL: WORKER_API,
    })
    return data
  } catch (error: any) {
    const { data: errorData, status } = error.response
    throw { errorData, status }
  }
}

export const getUserCurrency = async () => {
  const url = '/worker/partner_currency'

  try {
    const { data } = await get(url, {
      baseURL: WORKER_API,
    })
    return data
  } catch (error: any) {
    const { data: errorData, status } = error.response
    throw { errorData, status }
  }
}
