import { ToastState } from './toast.slice'
import { createAction, ActionReducerMapBuilder } from '@reduxjs/toolkit'
import { t } from 'i18next'

export const hideToast = createAction('toast/hide')
export const resolveHideToast = (
  builder: ActionReducerMapBuilder<ToastState>
) => {
  builder.addCase(hideToast, (state, { payload }) => {
    state.isVisible = false
  })
}

export const showToast = createAction<{
  customToast?: { title: string; content: string }
  withVariable?: { variable: string; variableValue: string }
  key: string
  type: 'success' | 'failed'
}>('toast/show')
export const resolveShowToast = (
  builder: ActionReducerMapBuilder<ToastState>
) => {
  builder.addCase(showToast, (state, { payload }) => {
    const { key, type, customToast, withVariable } = payload
    state.type = type
    state.isVisible = true
    if (!customToast && !withVariable) {
      state.title = t(`toasts.${key}.${type}.title`)
      state.content = t(`toasts.${key}.${type}.body`)
    } else if (!customToast && withVariable) {
      state.title = t(`toasts.${key}.${type}.title`)
      state.content = t(`toasts.${key}.${type}.body`, {
        [withVariable.variable]: [withVariable.variableValue],
      })
    } else if (customToast) {
      state.title = customToast.title
      state.content = customToast.content
    }
  })
}
