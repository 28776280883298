import { FC, MouseEventHandler, useRef } from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'

import { modalState } from './modal.slice'
import { modalActions } from './modal.actions'
import { OrderPopup } from './components/order-popup/order-popup'
import { OrderFullscreenModal } from './components/order-fullscreen-modal/order-fullscreen-modal'
import { ConfirmationModal } from './components/confirmation-modal/confirmation-modal'

import s from './styles.module.scss'
import { ImageZoomer } from './components/image-zoomer/image-zoomer'
import { IModalTypes } from './modal.types'
import { AddCoinsModal } from './components/add-coins-modal/add-coins-modal'
import { EditOrderModal } from './components/edit-order-modal/edit-order-modal'
import { AddDiscountModal } from './components/add-discount-modal/add-discount-modal'
import { ChangeClientAddressModal } from './components/change-client-address-modal/change-client-address-modal'

export const Modal: FC<modalState> = ({
  type,
  isOpen,
  backdropColor,
  details,
}) => {
  const backdropRef = useRef()
  const dispatch = useDispatch()

  const handleBackdropClick: MouseEventHandler<HTMLDivElement> = (e) => {
    const { current } = backdropRef

    if (current && e.target !== current) return
    dispatch(modalActions.closeModal())
  }

  const render = (type: IModalTypes | null) => {
    switch (type) {
      case 'order-popup':
        return (
          <OrderPopup
            data={details?.data}
            position={{
              x: details?.eventData.clientX,
              y: details?.eventData.clientY,
            }}
            pageStatus={details?.pageStatus}
          />
        )
      case 'order-fullscreen-modal':
        return (
          <OrderFullscreenModal
            data={details?.data}
            pageStatus={details?.pageStatus}
          />
        )
      case 'confirmation-modal':
        return <ConfirmationModal data={details?.data} />
      case 'image-zoomer':
        return <ImageZoomer img={details?.data} />
      case 'add-coins-modal':
        return (
          <AddCoinsModal
            clientId={details?.data.clientId}
            clientFullName={details?.data.clientFullName}
          />
        )
      case 'edit-order-modal':
        return (
          <EditOrderModal
            order={details?.data.order}
            currency={details?.data.currency}
            pageStatus={details?.data.pageStatus}
          />
        )
      case 'add-discount-modal':
        return (
          <AddDiscountModal
            order={details?.data.order}
            currency={details?.data.currency}
            pageStatus={details?.data.pageStatus}
          />
        )

      case 'change-client-address-modal':
        return <ChangeClientAddressModal client={details?.data} />

      default:
        return null
    }
  }

  return (
    <div
      //   @ts-ignore
      ref={backdropRef}
      onClick={handleBackdropClick}
      className={
        isOpen
          ? cn([s['backdrop'], s['backdrop-visible']])
          : cn([s['backdrop'], s['backdrop-hidden']])
      }
      style={{
        backgroundColor:
          backdropColor === 'dark' ? 'rgba(0,0,0,0.4)' : backdropColor,
      }}
    >
      {render(type)}
    </div>
  )
}
