import { createEntityAdapter, createSlice, Dictionary } from '@reduxjs/toolkit'
import { IOrder } from '../../app-types/entities-types/order.types'
import { MapCoord } from '../../services/requests/map/types'
import {
  IGetOrderTotalCountsRes,
  IGetTodayOrdersCountsRes,
  IOrderStatus,
} from '../../services/requests/orders/orders-new.types'
import { getMapCoordsBySearchThunk } from '../orders/orders.thunks'
import {
  clearBulkOrders,
  clearBulkState,
  clearFilters,
  selectAllOrders,
  selectOrder,
  setFilters,
  toggleBulk,
} from './orders.actions'
import {
  bulkResetOrdersThunk,
  bulkUpdateOrdersStatusesThunk,
  bulkUpdateOrdersWorkerThunk,
  getOrdersListThunk,
  getOrdersTotalCountsThunk,
  getTodayOrdersCountsThunk,
  resetOrderThunk,
  syncOrderListAfterPushThunk,
  syncOrderListThunk,
  updateOrderAdminNoteThunk,
  updateOrderDiscountThunk,
  updateOrderInfoThunk,
  updateOrderStatusThunk,
  updateOrderWorkerThunk,
} from './orders.thunks'

export interface IGroupOrder {
  group: string
  info: {
    orders: IOrder[]
    totalCount: number
    totalPrice: number
  }
}

export type MixedOrder = IOrder | IGroupOrder

export type Orders = Dictionary<Array<MixedOrder>>

export interface IOrderFilters {
  orderId: string[] | undefined
  clientId: string[] | undefined
  dogId: string[] | undefined
  workerId: string[] | undefined
  serviceTypeId: string[] | undefined
  startDate: string | undefined
  endDate: string | undefined
}

export interface IBulkItem {
  id: IOrder['id']
  oldStatus: IOrderStatus
}

export interface IBulkState {
  isBulkOn: boolean
  orders: Dictionary<IBulkItem[]>
}

interface IOrdersState {
  error: null | string
  loading: null | boolean
  orders: {
    orders: Orders | null
    totalCount: number | null
  }

  selectedOrder: null | IOrder
  bulk: IBulkState
  filters: IOrderFilters
  map: {
    inOrders: Dictionary<MapCoord>
    loading: null | boolean
  }
  totalCountsLoading: null | boolean
  totalCounts: IGetOrderTotalCountsRes | null
  countsLoading: null | boolean
  counts: IGetTodayOrdersCountsRes | null
}

export const ordersEntityAdapter = createEntityAdapter({
  selectId: (order: IOrder) => order._id,
})

export const groupOrderEntityAdapter = createEntityAdapter({
  selectId: (order: IGroupOrder) => order.group,
})

const initialState: IOrdersState = {
  error: null,
  loading: null,
  orders: {
    orders: null,
    totalCount: null,
  },
  selectedOrder: null,
  bulk: {
    isBulkOn: false,
    orders: {},
  },
  filters: {
    orderId: undefined,
    clientId: undefined,
    dogId: undefined,
    workerId: undefined,
    serviceTypeId: undefined,
    startDate: undefined,
    endDate: undefined,
  },
  totalCountsLoading: null,
  countsLoading: null,
  totalCounts: null,
  counts: null,
  map: {
    inOrders: {},
    loading: null,
  },
}

const ordersSlice = createSlice({
  name: 'orders-new-realization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //----GETTING ORDERS TOTAL COUNT---
    builder.addCase(getOrdersTotalCountsThunk.pending, (state) => {
      state.totalCountsLoading = true
    })
    builder.addCase(
      getOrdersTotalCountsThunk.fulfilled,
      (state, { payload }) => {
        state.totalCountsLoading = false
        state.totalCounts = payload
      }
    )
    builder.addCase(getOrdersTotalCountsThunk.rejected, (state) => {
      state.totalCountsLoading = false
    })
    //----GETTING TODAY ORDERS COUNT---
    builder.addCase(getTodayOrdersCountsThunk.pending, (state) => {
      state.countsLoading = true
    })
    builder.addCase(
      getTodayOrdersCountsThunk.fulfilled,
      (state, { payload }) => {
        state.countsLoading = false
        state.counts = payload
      }
    )
    builder.addCase(getTodayOrdersCountsThunk.rejected, (state) => {
      state.countsLoading = false
    })
    ////???????GETORDERBYID???????????
    //----GETTING ORDER LIST---
    builder.addCase(getOrdersListThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getOrdersListThunk.fulfilled, (state, { payload }) => {
      state.error = null
      state.orders = payload
      state.loading = false
    })
    builder.addCase(getOrdersListThunk.rejected, (state, { payload }) => {
      state.error = payload!.errorData.message
      state.loading = false
    })
    //----SYNCHRONIZATING ORDER LIST---
    builder.addCase(syncOrderListThunk.fulfilled, (state, { payload }) => {
      state.error = null
      state.orders = payload
    })
    builder.addCase(syncOrderListThunk.rejected, (state, { payload }) => {
      state.error = payload!.errorData.message
    })
    //---------BULK OPTIONS------
    builder.addCase(toggleBulk, (state, { payload }) => {
      if (payload === true) {
        state.bulk.isBulkOn = payload
      } else {
        state.bulk.orders = {}
        state.bulk.isBulkOn = payload
      }
    })
    builder.addCase(selectOrder, (state, { payload }) => {
      const { order, pageNumber } = payload

      if (state.bulk.orders[pageNumber]) {
        if (state.bulk.orders[pageNumber]?.some((o) => o.id === order.id)) {
          state.bulk.orders[pageNumber] = state.bulk.orders[pageNumber]?.filter(
            (o) => o.id !== order.id
          )
        } else {
          state.bulk.orders[pageNumber]?.push(order)
        }
      } else {
        state.bulk.orders[pageNumber] = [order]
      }
    })
    builder.addCase(selectAllOrders, (state, { payload }) => {
      const { orders, pageNumber } = payload
      state.bulk.orders[pageNumber] = orders
    })
    builder.addCase(clearBulkOrders, (state) => {
      state.bulk.orders = {}
    })

    builder.addCase(clearBulkState, (state) => {
      state.bulk.orders = {}
      state.bulk.isBulkOn = false
    })
    //--------MAP FOR ORDER-------//
    builder.addCase(getMapCoordsBySearchThunk.pending, (state) => {
      state.map.loading = true
    })
    builder.addCase(
      getMapCoordsBySearchThunk.fulfilled,
      (state, { payload }) => {
        const { orderId, coords } = payload
        state.map.loading = false
        if (coords) state.map.inOrders[orderId] = coords
      }
    )
    builder.addCase(getMapCoordsBySearchThunk.rejected, (state) => {
      state.map.loading = false
    })

    //--------FILTERS-------//
    builder.addCase(clearFilters, (state) => {
      state.filters = {
        clientId: undefined,
        dogId: undefined,
        orderId: undefined,
        serviceTypeId: undefined,
        workerId: undefined,
        startDate: undefined,
        endDate: undefined,
      }
    })
    builder.addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })
  },
})

export const ordersReducer = ordersSlice.reducer
export const ordersActions = {
  toggleBulk,
  selectOrder,
  selectAllOrders,
  clearBulkState,
  clearBulkOrders,
  setFilters,
  clearFilters,
}
export const ordersApi = {
  getTotalOrdersCounts: getOrdersTotalCountsThunk,
  getTodayOrdersCounts: getTodayOrdersCountsThunk,
  getOrdersList: getOrdersListThunk,
  // getOrderById:getOrdersByGroupIds
  updateOrderStatus: updateOrderStatusThunk,
  bulkUpdateOrdersStatuses: bulkUpdateOrdersStatusesThunk,
  updateOrderWorker: updateOrderWorkerThunk,
  bulkUpdateOrdersWorker: bulkUpdateOrdersWorkerThunk,
  resetOrder: resetOrderThunk,
  bulkResetOrders: bulkResetOrdersThunk,
  getMapCoordsBySearch: getMapCoordsBySearchThunk,
  updateOrderDiscount: updateOrderDiscountThunk,
  updateOrderAdminNote: updateOrderAdminNoteThunk,
  syncOrderListAfterPush: syncOrderListAfterPushThunk,
  updateOrderInfo: updateOrderInfoThunk,
}
