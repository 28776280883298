import { FC } from 'react'
import { ControllerRenderProps } from 'react-hook-form'

import s from './styles.module.scss'

interface IProps {
  field: ControllerRenderProps<any, any>
  label: string
  value: string | number
  disabled?: boolean
}

const Radio: FC<IProps> = ({ field = {}, label, value, disabled = false }) => {
  return (
    <div className={s['radio']}>
      <label className={s['radio-container']}>
        <input
          type="radio"
          {...field}
          value={value}
          disabled={disabled}
          className={s['radio-input']}
        />
        <span className={s['radio-button']}></span>
        <span role="label" className={s['radio-label']}>
          {label}
        </span>
      </label>
    </div>
  )
}

export default Radio
