import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { initMessaging } from '../../firebase-init'
import { getItem } from '../../helpers/local-storage'

const Notifications = () => {
  const dispatch = useDispatch()
  const fcm = getItem('fcm')

  useEffect(() => {
    initMessaging(fcm, dispatch)
  }, [fcm, dispatch])

  return <></>
}

export default Notifications
