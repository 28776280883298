import cn from 'classnames'
import { t } from 'i18next'
import { ChangeEvent, ReactNode } from 'react'
import { Form } from 'react-bootstrap'
import { ControllerRenderProps } from 'react-hook-form'

import { Text } from '../../typography/Text'

import s from './styles.module.scss'

type Props = {
  controlId: string
  fieldProps: ControllerRenderProps<any, any>
  label?: string
  renderLabel?: JSX.Element | null
  className?: string
  isTextArea?: boolean
  isDisabled?: boolean
  type?: 'email' | 'text' | 'password' | 'phone' | 'date' | 'number'
  inputClassName?: string
  placeholder?: string
  defaultValue?: string
  error?: string | null
  onValueChange?: (value: string) => void
  phoneCode?: string
  preLabel?: string | ReactNode
  min?: string
  max?: string
  required?: boolean
}

export const TextInput = ({
  controlId,
  fieldProps: { onChange, name, ref, value, onBlur },
  label = '',
  className = '',
  inputClassName = '',
  placeholder = '',
  isTextArea = false,
  isDisabled = false,
  renderLabel = null,
  type = 'text',
  defaultValue,
  error,
  onValueChange,
  phoneCode,
  preLabel,
  min,
  max,
  required = false,
}: Props) => {
  const handleChange = <T extends HTMLInputElement | HTMLTextAreaElement>(
    e: ChangeEvent<T>
  ) => {
    onValueChange?.(e.target.value)
    onChange(e)
  }

  return (
    <Form.Group className={className} controlId={controlId}>
      {renderLabel
        ? renderLabel
        : label && (
            <Form.Label className={s['label']}>
              <Text
                className={cn([
                  s['label-text'],
                  { [s['label-text_asterics']]: required },
                ])}
                size="14"
                weight="500"
              >
                {label}
              </Text>
              <Text className={s['error-text']} size="12" weight="normal">
                {error ? error : ''}
              </Text>
            </Form.Label>
          )}
      <div className={s['input-container']}>
        {type === 'phone' && (
          <div className={cn([s['phone-code'], { [s.disabled]: isDisabled }])}>
            <p>{phoneCode}</p>
          </div>
        )}
        {preLabel && <div className={s['pre-label']}>{preLabel}</div>}
        <Form.Control
          onChange={handleChange}
          onBlur={onBlur}
          name={name}
          ref={ref}
          value={value || ''}
          as={isTextArea ? 'textarea' : undefined}
          disabled={isDisabled}
          defaultValue={defaultValue}
          //@ts-ignore
          min={min}
          max={max}
          className={cn([
            s.input,
            'fs-14px',
            inputClassName,
            { [s.disabled]: isDisabled },
            { [s.error]: error },
            { [s.phone]: type === 'phone' },
            { [s['with-pre-label']]: preLabel },
          ])}
          type={type}
          placeholder={t(placeholder)}
        />
      </div>
    </Form.Group>
  )
}
