import { createAsyncThunk } from '@reduxjs/toolkit'

import { IResponseError } from '../../app-types/response-types/response-error'
import { IUserProfile } from '../../app-types/states-types/user-state.types'
import { ECurrencies } from '../../app-types/_enums/currencies'
import { IUserProfileRes } from '../../services/requests/user/types'
import {
  getUserCurrency,
  getUserProfile,
} from '../../services/requests/user/user'
import { EUserActions } from './user.action-types'

export const getUserProfileThunk = createAsyncThunk<
  IUserProfileRes,
  any,
  { rejectValue: IResponseError }
>(EUserActions.GET_USER, async (arg, { dispatch, rejectWithValue }) => {
  try {
    const data = await getUserProfile()

    dispatch(getUserCurrencyThunk(null))
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const getUserCurrencyThunk = createAsyncThunk<
  { currency: keyof typeof ECurrencies },
  any,
  { rejectValue: IResponseError }
>(EUserActions.GET_USER_CURRENCY, async (arg, { rejectWithValue }) => {
  try {
    const data = await getUserCurrency()
    return data
  } catch (err) {
    return rejectWithValue(err as IResponseError)
  }
})
