import {
  resolveHideToast,
  hideToast,
  resolveShowToast,
  showToast,
} from './toast.actions'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isVisible: false,
  title: null as null | string,
  content: null as null | string,
  type: 'success' as 'success' | 'failed',
}

const toastSlice = createSlice({
  name: 'toast',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resolveHideToast(builder)
    resolveShowToast(builder)
  },
})

export type ToastState = typeof initialState
export const toastReducer = toastSlice.reducer
export const toastActions = {
  hideToast,
  showToast,
}
