import cn from 'classnames'
import { useSelector } from 'react-redux'

import { synchronizationSelectors } from '../../store/synchronization/synchronization.selectors'

import s from './styles.module.scss'

export const SyncIndicator = () => {
  const { synchronization } = useSelector(
    synchronizationSelectors.synchronization
  )
  return (
    <div
      className={cn([
        s['sync-indicator'],
        { [s['sync-indicator_visible']]: synchronization },
      ])}
    >
      <div>Synchronization</div>
      <div className={s['indicator']}></div>
    </div>
  )
}
