import { RootState } from '../../store/store'

export const ordersSelectors = {
  orders: (state: RootState) => state.ordersNew.orders,
  loading: (state: RootState) => state.ordersNew.loading,
  error: (state: RootState) => state.ordersNew.error,
  bulk: (state: RootState) => state.ordersNew.bulk,
  filters: (state: RootState) => state.ordersNew.filters,

  map: {
    inOrders: (state: RootState) => state.ordersNew.map.inOrders,
    loading: (state: RootState) => state.ordersNew.map.loading,
  },

  countsLoading: (state: RootState) => state.ordersNew.countsLoading,
  selectedOrder: (state: RootState) => state.ordersNew.selectedOrder,
  totalCounts: (state: RootState) => state.ordersNew.totalCounts,
  totalCountsLoading: (state: RootState) => state.ordersNew.totalCountsLoading,
  counts: (state: RootState) => state.ordersNew.counts,
}
