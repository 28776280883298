import { createSlice } from '@reduxjs/toolkit'
import { resetCities } from './cities.actions'
import { citiesSelectors as selectors } from './cities.selectors'
import { getCitiesThunk } from './cities.thunks'

import { ICity } from './cities.types'

const initialState = {
  isLoading: false,
  error: '',
  data: null as null | Array<ICity>,
  total: 0,
}

export const countriesSlice = createSlice({
  name: 'cities',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCitiesThunk.pending, (state) => {
      state.error = ''
      state.total = 0
      state.isLoading = true
    })

    builder.addCase(getCitiesThunk.fulfilled, (state, { payload }) => {
      state.error = ''
      state.data = payload.cities
      state.total = payload.cities.length
      state.isLoading = false
    })

    builder.addCase(getCitiesThunk.rejected, (state, { payload }) => {
      state.error = payload?.errorData.message || 'failed'
      state.data = null
      state.total = 0
      state.isLoading = false
    })

    builder.addCase(resetCities, () => {
      return initialState
    })
  },
})

export const citiesReducer = countriesSlice.reducer

export const citiesSelectors = selectors

export const citiesActions = {
  reset: resetCities,
}

export const citiesApi = {
  getCities: getCitiesThunk,
}
