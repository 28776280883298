import { t as translate } from 'i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { IOrder } from '../../app-types/entities-types/order.types'

import { ISort } from './components/orders-list-header/orders-list-header'
import { Days, Months } from '../../constants/date'
import { OrderStatuses } from '../../services/requests/orders/constants'
import { IPageStatus } from '../../services/requests/orders/orders-new.types'
import { OrderPageStatuses } from '../orders/constants'
import { ordersSelectors } from './orders.selectors'
import {
  IGroupOrder,
  IOrderFilters,
  MixedOrder,
  ordersActions,
  ordersApi,
} from './orders.slice'

interface IOrdersSort {
  title: 'name' | 'time' | 'service' | 'date' | 'walker' | 'price' | undefined
  dir: 1 | -1 | undefined
}

interface IOnAcceptArgs {
  updatedStatus: keyof typeof OrderStatuses
  pageStatus: keyof typeof OrderPageStatuses
  orderId: IOrder['id']
  groupId?: IOrder['groupId']
  oldStatus: keyof typeof OrderStatuses
  isGroupCard?: boolean
  cancelReason?: string
}

export type IUseChangeOrderStatus = (params: IOnAcceptArgs) => void

const t = (value: string): string => {
  return translate(value).toUpperCase()
}

export const useOrdersSorts = (pageStatus: IPageStatus) => {
  const [activeSort, setActiveSort] = useState<IOrdersSort>({
    title: undefined,
    dir: undefined,
  })
  const sorts: Array<ISort> = [
    {
      title: t('t.name'),
      isSortable: true,
      isActive: activeSort.title === 'name',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'name', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    {
      title: t('t.time-slot'),
      isSortable: true,
      isActive: activeSort.title === 'time',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'time', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    { title: t('t.recurring'), isSortable: false },
    {
      title: t('t.service'),
      isSortable: true,
      isActive: activeSort.title === 'service',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'service', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    {
      title: t('date'),
      isSortable: true,
      isActive: activeSort.title === 'date',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'date', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    {
      title: t('t.walker'),
      isSortable: true,
      isActive: activeSort.title === 'walker',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'walker', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    {
      title: t('t.price'),
      isSortable: true,
      isActive: activeSort.title === 'price',
      callbackFn: () => {
        setActiveSort((p) => ({ title: 'price', dir: p.dir === -1 ? 1 : -1 }))
      },
    },
    {
      title: pageStatus === 'ACCEPTED' ? t('t.live-order') : '',
      isSortable: false,
    },
  ]

  useEffect(() => {
    return () => setActiveSort({ title: undefined, dir: undefined })
  }, [])

  return { sorts, activeSort }
}

export const isGroupOrder = (order: MixedOrder): order is IGroupOrder => {
  return (order as IGroupOrder).group !== undefined
}

export const useOrdersFilters = () => {
  const dispatch = useDispatch()
  const filters = useSelector(ordersSelectors.filters)
  const setFilters = (filters: IOrderFilters) =>
    dispatch(ordersActions.setFilters(filters))
  const clearFilters = () => dispatch(ordersActions.clearFilters())
  return { filters, setFilters, clearFilters }
}

export const useChangeOrderStatus = (): IUseChangeOrderStatus => {
  const dispatch = useDispatch()
  const changeOrderStatus = (params: IOnAcceptArgs) => {
    dispatch(ordersApi.updateOrderStatus(params))
  }
  return changeOrderStatus
}

export const generateStatusesForPage = (
  pageStatus: keyof typeof OrderPageStatuses
): Array<keyof typeof OrderStatuses> => {
  switch (pageStatus) {
    case OrderPageStatuses.TODAY:
      return [OrderStatuses.PENDING, OrderStatuses.ACCEPTED, OrderStatuses.LIVE]
    case OrderPageStatuses.ACCEPTED:
      return [OrderStatuses.ACCEPTED, OrderStatuses.LIVE]
    case OrderPageStatuses.HISTORY:
      return [OrderStatuses.CANCEL_BY_USER_AFTER_8, OrderStatuses.FINISHED]
    case OrderPageStatuses.DISMISSED:
      return [
        OrderStatuses.DECLINE_BY_SERVICE,
        OrderStatuses.DECLINE_BY_USER,
        OrderStatuses.CANCEL_BY_USER,
        OrderStatuses.CANCEL_BY_SERVICE,
      ]
    default:
      return [OrderStatuses.PENDING]
  }
}

export const calkRecurring = () => {
  const memo: { [key: string]: string } = {}

  return function calkDays(order: IOrder, isGroupCard: boolean) {
    const recurring =
      order.days.join('') + isGroupCard.toString() + order.startDate

    if (memo[recurring]) {
      return memo[recurring]
    }

    let formattedDays: string = 'error-days'

    if (order.days.length === 0) {
      memo[recurring] = translate('t.every-day')
      return memo[recurring]
    }
    const isDateStringFormat = Number.isNaN(Number(order.days[0]))
    const isSubOrder = !isGroupCard && order.groupId

    if (isSubOrder) {
      const date = new Date(order.startDate)
      const day = Days[date.getDay()]
      const month = Months[date.getMonth()]
      const formattedDate = `${date.getDate()} ${translate(
        `t.date-formats.short.${month}`
      )}`
      memo[recurring] = `${translate(
        `t.date-formats.short.${day}`
      )}, ${formattedDate}`

      return memo[recurring]
    }

    if (order.subscription) {
      const getDayInd = (str: string) =>
        isDateStringFormat ? new Date(str).getDay() : Number(str)

      formattedDays = order.days
        .map((day) => {
          const dayInd = getDayInd(day)
          memo[recurring] = translate(`t.date-formats.short.${Days[dayInd]}`)
          return memo[recurring]
        })
        .join(',')

      memo[recurring] = formattedDays
      return memo[recurring]
    }

    if (order.days.length === 1) {
      const date = new Date(order.days[0])
      const day = Days[date.getDay()]
      const month = Months[date.getMonth()]
      const formattedDate = `${date.getDate()} ${translate(
        `t.date-formats.short.${month}`
      )}`
      memo[recurring] = `${translate(
        `t.date-formats.short.${day}`
      )}, ${formattedDate}`
      return memo[recurring]
    }

    let daysSet: Set<number> = new Set<number>()
    order.days.forEach((dateString) => {
      const day = new Date(dateString).getDay()
      daysSet.add(day)
    })

    if (daysSet.size === 7) {
      memo[recurring] = translate('t.every-day')
      return memo[recurring]
    } else {
      formattedDays = Array.from(daysSet as any as ArrayLike<number>)
        .map((dayNum) => translate(`t.date-formats.short.${Days[dayNum]}`))
        .join(',')
    }

    memo[recurring] = formattedDays
    return memo[recurring]
  }
}
