import { modalReducer } from './../components/modal/modal.slice'
import { toastReducer } from './../components/toast/toast.slice'
import { staffReducer } from './../pages/staff/staff.slice'
import { workersReducer } from '../pages/orders/worker.slice'
import { ordersReducer } from '../pages/orders/orders.slice'
import { combineReducers } from 'redux'
import { authReducer } from './auth/authSlice'
import { clientsReducer } from '../pages/clients/clients.slice'
import { userReducer } from './user/user.slice'

import { ordersReducer as ordersReducerNew } from '../pages/orders-new-realization/orders.slice'
import { synchronizationReducer } from './synchronization/synchronization.slice'
import { countriesReducer } from './countries/countries.slice'
import { citiesReducer } from './cities/cities.slice'
import { statesReducer } from './states/states.slice'

const rootReducer = combineReducers({
  auth: authReducer,
  orders: ordersReducer,
  workers: workersReducer,
  toast: toastReducer,
  staff: staffReducer,
  clients: clientsReducer,
  modal: modalReducer,
  user: userReducer,
  // dashboardGeneral,
  ordersNew: ordersReducerNew,
  synchronization: synchronizationReducer,
  countries: countriesReducer,
  cities: citiesReducer,
  states: statesReducer,
})

export default rootReducer
