import { UnboxPromise } from './../../helpers/types'
import { AppDispatch } from './../store'
import { IUserProfileRes } from './../../services/requests/user/types'
import { getUserProfile } from './../../services/requests/user/user'
import {
  CheckCodeRes,
  SetPhoneReq,
  SetPhoneRes,
} from './../../services/requests/auth/types'
import { AuthState } from './authSlice'
import { createThunkResolver } from './../../helpers/index'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import {
  setPhone,
  checkCode,
  logOut,
} from './../../services/requests/auth/auth'
import { IResponseError } from '../../app-types/response-types/response-error'
import { clearUserData } from '../user/user.actions'

export const setPhoneThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof setPhone>>,
  Omit<SetPhoneReq, 'message'>,
  { dispatch: AppDispatch }
>('auth/setPhone', setPhone)
const setPhoneThunkResolver = createThunkResolver<AuthState, SetPhoneRes>(
  setPhoneThunk
)

export const resolveSetPhoneThunk = (
  builder: ActionReducerMapBuilder<AuthState>
) =>
  setPhoneThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.code = action.payload.code
    })
    .onRejected()

export const checkCodeThunk = createAsyncThunk('auth/checkCode', checkCode)
const checkCodeThunkResolver = createThunkResolver<AuthState, CheckCodeRes>(
  checkCodeThunk
)

export const resolveCheckCodeThunk = (
  builder: ActionReducerMapBuilder<AuthState>
) =>
  checkCodeThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.auth = action.payload.auth
    })
    .onRejected()

export const logOutThunk = createAsyncThunk<
  any,
  any,
  { rejectValue: IResponseError }
>('auth/logOut', async (arg, { dispatch, rejectWithValue }) => {
  try {
    const { data } = await logOut()
    dispatch(clearUserData())
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})
