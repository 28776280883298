import { t } from 'i18next'
import { FC, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { Switch, TextInput } from '../../..'
import { Button } from '../../../buttons/button'
import Select, { ISelectOption } from '../../../inputs/select/select'

import { modalActions } from '../../modal.actions'

import s from './styles.module.scss'

type ActionType = 'decline' | 'cancel' | 'delete' | 'reset' | 'accept'
interface IProps {
  data: {
    text: string
    handler: (arg?: any) => void
    type?: ActionType
  }
}

export const ConfirmationModal: FC<IProps> = ({
  data: { text, handler, type = 'delete' },
}) => {
  const dispatch = useDispatch()
  const { control, watch, resetField } = useForm({
    defaultValues: {
      cancelReason: undefined,
      isCustomReason: false,
    },
  })

  const reasons = useMemo(
    () => [
      t('modals.confirmation-modal.reason.dw-sick'),
      t('modals.confirmation-modal.reason.dw-vacation'),
      t('modals.confirmation-modal.reason.dw-not-found'),
      t('modals.confirmation-modal.reason.technical-issue'),
      t('modals.confirmation-modal.reason.client-request'),
    ],
    []
  )

  const [reason, isCustomReason] = watch(['cancelReason', 'isCustomReason'])

  const renderChild = (type: ActionType) => {
    switch (type) {
      case 'cancel':
        return t('modals.confirmation-modal.actions.cancel', { text })
      case 'decline':
        return t('modals.confirmation-modal.actions.decline', { text })
      case 'reset':
        return t('modals.confirmation-modal.actions.reset', { text })
      case 'delete':
        return t('modals.confirmation-modal.actions.delete', { text })
      case 'accept':
        return t('modals.confirmation-modal.actions.accept', { text })
      default:
        return null
    }
  }

  const onSubmit = () => {
    if (type === 'decline' || type === 'cancel') {
      handler(reason)
      dispatch(modalActions.closeModal())
    } else {
      handler()
      dispatch(modalActions.closeModal())
    }
  }

  return (
    <div className={s['modal']}>
      <p className={s['title']}>{t('modals.confirmation-modal.header')}</p>
      <p className={s['text']}>{renderChild(type)}</p>
      {(type === 'decline' || type === 'cancel') && (
        <div>
          {isCustomReason ? (
            <Controller
              name="cancelReason"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t('modals.confirmation-modal.forms.reason-label')}
                  placeholder={t(
                    'modals.confirmation-modal.forms.reason-input-placeholder'
                  )}
                  fieldProps={field}
                  controlId="cancelReason"
                />
              )}
            />
          ) : (
            <Controller
              name="cancelReason"
              control={control}
              render={({ field }) => (
                <Select
                  fieldProps={field}
                  label={t('modals.confirmation-modal.forms.reason-label')}
                  placeholder={t(
                    'modals.confirmation-modal.forms.reason-select-placeholder'
                  )}
                  value={undefined}
                  comment=""
                  onSelect={(option: ISelectOption) => {
                    field.onChange(option.value)
                  }}
                  options={reasons.map((reason) => ({
                    title: reason,
                    value: reason,
                  }))}
                />
              )}
            />
          )}

          <Controller
            name="isCustomReason"
            control={control}
            render={({ field }) => (
              <div className={s['other-reason']}>
                <Switch
                  value={field.value}
                  onChange={(value) => {
                    resetField('cancelReason')
                    field.onChange(value)
                  }}
                  defaultValue={field.value}
                />
                <span>
                  {t('modals.confirmation-modal.forms.reason-switch-label')}
                </span>
              </div>
            )}
          />
        </div>
      )}
      <div className={s['controls']}>
        <Button
          className={s['button']}
          onClick={() => dispatch(modalActions.closeModal())}
        >
          {t('modals.confirmation-modal.forms.close-btn-text')}
        </Button>
        <Button className={s['button']} onClick={onSubmit}>
          {t('modals.confirmation-modal.forms.confirm-btn-text')}
        </Button>
      </div>
    </div>
  )
}
