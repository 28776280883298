import { RootState } from './../../store/store'
import {
  orderGroupsAdapter,
  ordersAcceptedAdapter,
  ordersDismissedAdapter,
  ordersHistoryAdapter,
  ordersPendingAdapter,
  OrdersState,
  ordersTodayAdapter,
} from './orders.slice'

export const ordersSelectors = {
  groups: {
    ...orderGroupsAdapter.getSelectors<OrdersState>(
      //@ts-ignore
      (state) => state.orders.groups
    ),
    loading: (state: RootState) => state.orders.groups.loading,
  },
  orders: {
    pending: {
      ...ordersPendingAdapter.getSelectors<OrdersState>(
        //@ts-ignore
        (state) => state.orders.orders.pending
      ),
      totalCount: (state: RootState) => state.orders.orders.pending.totalCount,
      loading: (state: RootState) => state.orders.orders.pending.loading,
    },
    dismissed: {
      ...ordersDismissedAdapter.getSelectors<OrdersState>(
        //@ts-ignore
        (state) => state.orders.orders.dismissed
      ),
      totalCount: (state: RootState) =>
        state.orders.orders.dismissed.totalCount,
      loading: (state: RootState) => state.orders.orders.dismissed.loading,
    },
    today: {
      ...ordersTodayAdapter.getSelectors<OrdersState>(
        //@ts-ignore
        (state) => state.orders.orders.today
      ),
      totalCount: (state: RootState) => state.orders.orders.today.totalCount,
      loading: (state: RootState) => state.orders.orders.today.loading,
    },
    history: {
      ...ordersHistoryAdapter.getSelectors<OrdersState>(
        //@ts-ignore
        (state) => state.orders.orders.history
      ),
      totalCount: (state: RootState) => state.orders.orders.history.totalCount,
      loading: (state: RootState) => state.orders.orders.history.loading,
    },
    accepted: {
      ...ordersAcceptedAdapter.getSelectors<OrdersState>(
        //@ts-ignore
        (state) => state.orders.orders.accepted
      ),
      totalCount: (state: RootState) => state.orders.orders.accepted.totalCount,
      loading: (state: RootState) => state.orders.orders.accepted.loading,
    },
  },

  map: {
    inOrders: (state: RootState) => state.orders.map.inOrders,
    loading: (state: RootState) => state.orders.map.loading,
  },

  bulk: (state: RootState) => state.orders.bulk,
  loading: (state: RootState) => state.orders.loading,
  isBulk: (state: RootState) => state.orders.isBulk,
  allBulkTrigger: (state: RootState) => state.orders.allBulkTrigger,
  countsLoading: (state: RootState) => state.orders.countsLoading,
  selectedOrder: (state: RootState) => state.orders.selectedOrder,
  totalCounts: (state: RootState) => state.orders.totalCounts,
  totalCountsLoading: (state: RootState) => state.orders.totalCountsLoading,
  counts: (state: RootState) => state.orders.counts,
  filters: (state: RootState) => state.orders.filters,
}
