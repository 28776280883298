// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_backdrop__CbOjy {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  min-height: 100vh;\n  min-width: 100vw;\n  max-width: 100vw;\n  max-height: 100vh;\n  background: rgba(0, 0, 0, 0.4);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n}\n.styles_backdrop-hidden__mQy95 {\n  opacity: 0;\n  visibility: hidden;\n  transition: 0.55s opacity, 0.55s visibility;\n}\n.styles_backdrop-visible__-XDbs {\n  opacity: 1;\n  visibility: visible;\n  transition: 0.55s opacity, 0.55s visibility;\n}", "",{"version":3,"sources":["webpack://./src/components/modal/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,aAAA;EACA,iBAAA;EACA,gBAAA;EACA,gBAAA;EACA,iBAAA;EACA,8BAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,UAAA;EACA,kBAAA;EACA,2CAAA;AAEJ;AAAE;EACE,UAAA;EACA,mBAAA;EACA,2CAAA;AAEJ","sourcesContent":[".backdrop {\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100vw;\n  height: 100vh;\n  min-height: 100vh;\n  min-width: 100vw;\n  max-width: 100vw;\n  max-height: 100vh;\n  background: rgba(0, 0, 0, 0.4);\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  z-index: 999;\n  &-hidden {\n    opacity: 0;\n    visibility: hidden;\n    transition: 0.55s opacity, 0.55s visibility;\n  }\n  &-visible {\n    opacity: 1;\n    visibility: visible;\n    transition: 0.55s opacity, 0.55s visibility;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"backdrop": "styles_backdrop__CbOjy",
	"backdrop-hidden": "styles_backdrop-hidden__mQy95",
	"backdrop-visible": "styles_backdrop-visible__-XDbs"
};
export default ___CSS_LOADER_EXPORT___;
