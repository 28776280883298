import cn from 'classnames'
import { t } from 'i18next'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { TextInput } from '../../..'
import {
  CrossIcon,
  DogizCoinIcon,
  FailedIcon,
  SuccessIcon,
} from '../../../../assets/icons'
import { useAppDispatch } from '../../../../helpers'
import { clientsApi } from '../../../../pages/clients/clients.slice'
import { modalActions } from '../../modal.actions'

import s from './styles.module.scss'

interface IProps {
  clientId: string
  clientFullName: string
}

interface IFormValues {
  amount: number
}

export const AddCoinsModal: FC<IProps> = ({ clientId, clientFullName }) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({})

  const [isFetching, setIsFetching] = useState(false)
  const [isAddingSucces, setIsAddingSuccess] = useState<boolean | null>(null)

  const renderContent = () => {
    switch (isAddingSucces) {
      case true:
        return (
          <div className={s['result-container']}>
            <SuccessIcon />
            <p className={s['result-container__title']}>
              {t('clients.details.add-coins.success-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('clients.details.add-coins.success-text')}
            </p>
          </div>
        )
      case false:
        return (
          <div className={s['result-container']}>
            <FailedIcon />
            <p className={s['result-container__title']}>
              {t('clients.details.add-coins.failed-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('clients.details.add-coins.failed-text')}
            </p>
          </div>
        )

      default:
        return (
          <>
            <div>
              <p className={s['modal__title']}>
                {t('clients.details.add-coins.title')}
              </p>
              <p className={s['modal__subtitle']}>
                {t('clients.details.add-coins.subtitle-start')}
                <span className={s['modal__subtitle_name']}>
                  {clientFullName + "'s"}
                </span>
                {t('clients.details.add-coins.subtitle-end')}
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={s['input-container']}>
                <Controller
                  control={control}
                  name="amount"
                  rules={{
                    required: t('validation-errors.required').toString(),
                    validate: (value) =>
                      value < 1
                        ? t('validation-errors.min', { value: 1 }).toString()
                        : true,
                  }}
                  render={({ field }) => (
                    <TextInput
                      fieldProps={field}
                      controlId="coins"
                      type="number"
                      placeholder="0"
                      label={t('clients.details.add-coins.input-label')}
                      preLabel={<DogizCoinIcon />}
                      inputClassName={s['input']}
                      min={'1'}
                      error={errors.amount?.message}
                    />
                  )}
                />
              </div>
              <div className={s['btn-container']}>
                <button
                  className={s['confirm-btn']}
                  type="submit"
                  disabled={isFetching}
                >
                  {t('clients.details.add-coins.confirm-btn-text')}
                </button>
              </div>
            </form>
          </>
        )
    }
  }

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const body = {
      userId: clientId,
      amount: data.amount,
    }

    setIsFetching(true)
    const result = await dispatch(clientsApi.addCoinsByPartner(body))
    if (!result.payload?.status) {
      setIsFetching(false)
      setIsAddingSuccess(true)
      setTimeout(() => {
        dispatch(modalActions.closeModal())
      }, 2000)
    } else {
      setIsFetching(false)
      setIsAddingSuccess(false)
    }
  }

  return (
    <div
      className={cn([
        s['modal-container'],
        {
          [s['modal-container_small']]:
            isAddingSucces === true || isAddingSucces === false,
        },
      ])}
    >
      <button
        className={s['modal__close-btn']}
        onClick={() => dispatch(modalActions.closeModal())}
      >
        <CrossIcon width="10px" height="10px" />
      </button>
      {renderContent()}
    </div>
  )
}
