import { createAction } from '@reduxjs/toolkit'
import { IOrder } from '../../app-types/entities-types/order.types'
import { IOrderStatus } from '../../services/requests/orders/orders-new.types'
import { IOrderFilters } from './orders.slice'

export const toggleBulk = createAction<boolean>('orders/toggle-bulk')

export const selectOrder = createAction<{
  order: { id: IOrder['id']; oldStatus: IOrderStatus }
  pageNumber: number | string
}>('orders/select-order')

export const selectAllOrders = createAction<{
  orders: { id: IOrder['id']; oldStatus: IOrderStatus }[] | []
  pageNumber: number | string
}>('orders/select-all-order')

export const clearBulkState = createAction('orders/clear-bulk-state')

export const clearBulkOrders = createAction('orders/clear-bulk-orders')

export const setFilters = createAction<IOrderFilters>('orders/set-filters')

export const clearFilters = createAction('orders/clear-filters')
