import { createSlice } from '@reduxjs/toolkit'

import { IClientSubsription } from '../../app-types/entities-types/clients.types'
import { Order } from '../orders/types'
import { clearFilters, clearSubscriptions, setFilters } from './clients.actions'

import {
  addCoinsByPartnerThunk,
  cancelClientSubscriptionThunk,
  changeClientAddressThunk,
  getClientByIdThunk,
  getClientOrdersThunk,
  getClientSubscriptionsThunk,
  getPartnerClientsThunk,
  resolveClientById,
  resolveClientOrders,
  resolvePartnerClients,
} from './clients.thunks'
import { IClient } from './clients.types'

export interface IClientsFilters {
  clientId: string | undefined
  userName: string | undefined
  dogId: string | undefined
}

const initialState = {
  clients: {
    clients: null as any | Array<IClient>,
    total: 0 as number,
  },
  selectedClient: null as null | IClient,
  clientWalks: {
    walks: [] as Array<Order>,
    total: null as null | number,
  },
  clientSubscriptions: null as null | IClientSubsription[],
  loading: null as null | boolean,
  baseLoading: null as null | boolean,
  walksLoading: null as null | boolean,
  error: null as null | string,
  filters: {
    clientId: undefined,
    userName: undefined,
    dogId: undefined,
  } as IClientsFilters,
}

const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resolvePartnerClients(builder)
    resolveClientById(builder)
    resolveClientOrders(builder)
    builder.addCase(addCoinsByPartnerThunk.rejected, (state, { payload }) => {
      state.error = payload!.errorData.message
    })
    //------Filters----//
    builder.addCase(setFilters, (state, action) => {
      state.filters = action.payload
    })

    builder.addCase(clearFilters, (state) => {
      state.filters = {
        clientId: undefined,
        userName: undefined,
        dogId: undefined,
      }
    })
    //------Client subscriptions------//
    builder.addCase(getClientSubscriptionsThunk.pending, (state) => {
      state.walksLoading = true
    })
    builder.addCase(
      getClientSubscriptionsThunk.fulfilled,
      (state, { payload }) => {
        state.clientSubscriptions = payload
        state.walksLoading = false
      }
    )
    builder.addCase(
      getClientSubscriptionsThunk.rejected,
      (state, { payload }) => {
        state.error = payload!.errorData.message
        state.walksLoading = false
      }
    )
    builder.addCase(clearSubscriptions, (state) => {
      state.clientSubscriptions = null
    })
  },
})

export type clientsState = typeof initialState

export const clientsReducer = clientsSlice.reducer

export const clientsActions = {
  setFilters,
  clearFilters,
  clearSubscriptions,
}

export const clientsApi = {
  getPartnerClients: getPartnerClientsThunk,
  getClientById: getClientByIdThunk,
  getClientOrders: getClientOrdersThunk,
  addCoinsByPartner: addCoinsByPartnerThunk,
  getClientSubscriptions: getClientSubscriptionsThunk,
  cancelClientSubscription: cancelClientSubscriptionThunk,
  changeClientAddress: changeClientAddressThunk,
}
