import { OrderPageStatuses } from './../../pages/orders/constants'
import { Order } from './../../pages/orders/types'
import { createAction } from '@reduxjs/toolkit'
import { IModalTypes } from './modal.types'

export const modalActions = {
  openModal: createAction<{
    type: IModalTypes
    backdropColor: 'transparent' | 'dark'
    details?: {
      data?: any | Order
      eventData?: MouseEvent | React.MouseEvent
      pageStatus?: keyof typeof OrderPageStatuses
    }
  }>('modal/OPEN_MODAL'),
  closeModal: createAction('modal/CLOSE_MODAL'),
}
