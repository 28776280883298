import { createSlice } from '@reduxjs/toolkit'
import { toggleSynchronization } from './synchronization.actions'

interface ISynchronizationState {
  synchronization: boolean
}

const initialState: ISynchronizationState = {
  synchronization: false,
}

const synchronizationSlice = createSlice({
  name: 'synchronization',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(toggleSynchronization, (state, { payload }) => {
      state.synchronization = payload
    })
  },
})

export const synchronizationReducer = synchronizationSlice.reducer
export const synchronizationActions = {
  toggleSynchronization,
}
