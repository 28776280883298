import { OrderPageStatuses } from './../../pages/orders/constants'
import { Order } from './../../pages/orders/types'
import { createSlice } from '@reduxjs/toolkit'
import { IModalTypes } from './modal.types'

const initialState = {
  isOpen: false as boolean,
  type: null as null | IModalTypes,
  onClose: null as null | Function,
  backdropColor: 'transparent' as 'transparent' | 'dark',
  details: null as null | {
    data: any | Order
    eventData: MouseEvent | React.MouseEvent
    pageStatus?: keyof typeof OrderPageStatuses
  },
}

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    OPEN_MODAL(state, action) {
      state.type = action.payload.type
      state.backdropColor = action.payload.backdropColor
      state.details = action.payload.details
      state.isOpen = true
    },
    CLOSE_MODAL(state) {
      state.isOpen = false
      state.type = null
    },
  },
  extraReducers: (builder) => {},
})

export type modalState = typeof initialState

export const modalReducer = modalSlice.reducer
