import { initializeApp } from 'firebase/app'
import { getMessaging, getToken as getFBToken } from 'firebase/messaging'
import { Dispatch } from 'redux'
import { toastActions } from './components/toast/toast.slice'
import { setItem } from './helpers/local-storage'
import { registerDeviceForPushNotifications } from './services/requests/notifications/notifications'

const firebaseConfig = {
  apiKey: 'AIzaSyC3TmNPKRCFjx2eZ3fHjGY_XgZkmSzVoy8',
  authDomain: 'dogiz-dd964.firebaseapp.com',
  databaseURL: 'https://dogiz-dd964.firebaseio.com',
  projectId: 'dogiz-dd964',
  storageBucket: 'dogiz-dd964.appspot.com',
  messagingSenderId: '946200949509',
  appId: '1:946200949509:web:452757b29e29abbbf27d6c',
  measurementId: 'G-4TRGER74RT',
}

const firebase = initializeApp(firebaseConfig)

const { REACT_APP_VAPID_KEY } = process.env
const publicKey = REACT_APP_VAPID_KEY

export const messaging = getMessaging()

export const getToken = async () => {
  let currentToken = ''

  try {
    currentToken = await getFBToken(messaging, { vapidKey: publicKey })

    return currentToken
  } catch (error) {
    console.log('An error occurred while retrieving token. ', error)
    alert('Please, enable push notification for this page and reload page!')
  }

  return currentToken
}

export const initMessaging = async (fcm: any, dispatch: Dispatch) => {
  const fcmToken = await getToken()

  const isDeviceRegistered = fcmToken === fcm

  if (!isDeviceRegistered) {
    if (fcmToken) {
      registerDeviceForPushNotifications({
        nameDevice: 'web-platform',
        fcmToken,
      })
        .then(() => {
          setItem('fcm', fcmToken)
          dispatch(
            toastActions.showToast({
              key: 'push-notification-device-registered',
              type: 'success',
            })
          )
        })
        .catch(() => {
          dispatch(
            toastActions.showToast({
              key: 'push-notification-device-registered',
              type: 'failed',
            })
          )
        })
    } else {
      dispatch(
        toastActions.showToast({
          key: 'push-notification-received',
          type: 'failed',
        })
      )
    }
  }
}
