import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  countriesActions,
  countriesApi,
  countriesSelectors,
} from '../store/countries/countries.slice'

import { debounce } from '../helpers'

import { ICountry } from '../store/countries/countries.types'
import { ISuperSelectOption } from '../components/forms/super-select/utils'

const mapCountriesToSelectOptions = (
  countries: Array<ICountry> | null
): Array<ISuperSelectOption> => {
  return countries
    ? countries.map(({ _id, name }) => ({ label: name, value: _id }))
    : []
}

export const useCountryOptions = () => {
  const dispatch = useDispatch()
  const { data, isLoading, error } = useSelector(countriesSelectors.getAllState)

  const [searchedCountry, setSearchedCountry] = useState('')

  const onCountrySearch = useCallback(
    debounce((value: string) => {
      setSearchedCountry(value)
    }, 800),
    []
  )

  useEffect(() => {
    dispatch(
      countriesApi.getCountries({ params: { country: searchedCountry } })
    )

    return () => {
      dispatch(countriesActions.reset())
    }
  }, [searchedCountry, dispatch])

  return {
    searchedCountry,
    onCountrySearch,
    countries: mapCountriesToSelectOptions(data),
    originalState: data,
    isLoading,
    isError: error,
  }
}
