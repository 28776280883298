// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_image-zoomer__SRphz {\n  position: relative;\n  max-width: 80vw;\n  max-height: 80vh;\n  border-radius: 16px;\n  overflow: hidden;\n}\n\n.styles_image__eNzLV {\n  max-width: 90vw;\n  max-height: 80vh;\n  height: 100%;\n  object-fit: cover;\n}\n\n.styles_close-btn__dG84S {\n  padding: 6px;\n  border: none;\n  background: rgba(0, 0, 0, 0.05);\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  border-radius: 8px;\n  opacity: 0.5;\n  transition: opacity 0.15s linear;\n}\n.styles_close-btn__dG84S:hover {\n  opacity: 0.8;\n}\n.styles_close-btn__dG84S > svg {\n  fill: #fff;\n}", "",{"version":3,"sources":["webpack://./src/components/modal/components/image-zoomer/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,eAAA;EACA,gBAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AACA;EACE,eAAA;EACA,gBAAA;EACA,YAAA;EACA,iBAAA;AAEF;;AAAA;EACE,YAAA;EACA,YAAA;EACA,+BAAA;EACA,kBAAA;EACA,SAAA;EACA,WAAA;EACA,kBAAA;EACA,YAAA;EACA,gCAAA;AAGF;AAFE;EACE,YAAA;AAIJ;AAFE;EACE,UAAA;AAIJ","sourcesContent":[".image-zoomer {\n  position: relative;\n  max-width: 80vw;\n  max-height: 80vh;\n  border-radius: 16px;\n  overflow: hidden;\n}\n.image {\n  max-width: 90vw;\n  max-height: 80vh;\n  height: 100%;\n  object-fit: cover;\n}\n.close-btn {\n  padding: 6px;\n  border: none;\n  background: rgba($color: #000000, $alpha: 0.05);\n  position: absolute;\n  top: 16px;\n  right: 16px;\n  border-radius: 8px;\n  opacity: 0.5;\n  transition: opacity 0.15s linear;\n  &:hover {\n    opacity: 0.8;\n  }\n  & > svg {\n    fill: #fff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"image-zoomer": "styles_image-zoomer__SRphz",
	"image": "styles_image__eNzLV",
	"close-btn": "styles_close-btn__dG84S"
};
export default ___CSS_LOADER_EXPORT___;
