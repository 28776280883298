import cn from 'classnames'
import { FC, useRef, useState } from 'react'
import { ArrowRightIcon, SearchIcon } from '../../assets/icons'
import useOutsideClick from '../../hooks/use-outside-click'

import s from './styles.module.scss'

interface IDropDownOption {
  title: string
  value: string
}
interface IDropDownProps {
  options: IDropDownOption[]
  defaultOption: IDropDownOption
  onSelect: Function
  disabled?: boolean
}

const DropDown: FC<IDropDownProps> = ({
  options,
  onSelect,
  defaultOption,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<IDropDownOption>(defaultOption)
  const [results, setResults] = useState(options)

  const dropDownRef = useRef(null)

  useOutsideClick(dropDownRef, () => {
    setIsOpen(false)
  })

  const generateCN = (
    dep: boolean,
    className: string,
    modifier: string
  ): string => {
    if (dep === true) {
      return `${s[className]} ${s[className + '_' + modifier]}`
    } else {
      return `${s[className]}`
    }
  }

  const handleSearch = (e: any) => {
    const regexp = new RegExp(`${e.target.value.toLowerCase()}`)

    if (e.target.value === '') {
      setResults(options)
    } else {
      setResults(options.filter((i) => regexp.test(i.title.toLowerCase()) && i))
    }
  }

  const handleOnSelect = (value: typeof selected): void => {
    onSelect(value.value)
    setSelected(value)
    setIsOpen(false)
  }

  return (
    <div ref={dropDownRef} className={s['dropdown']}>
      <div className={s['dropdown-container']}>
        <div
          className={cn([
            s['dropdown-input'],
            { [s['opened']]: isOpen },
            { [s['disabled']]: disabled },
          ])}
          onClick={() => {
            if (disabled) {
              return
            } else {
              setResults(options)
              setIsOpen((p) => !p)
            }
          }}
        >
          <div className={s['dropdown-input__content']}>{selected.title}</div>
          <div className={generateCN(isOpen, 'dropdown-input__icon', 'opened')}>
            <ArrowRightIcon viewBox="0 0 6 9" />
          </div>
        </div>
        <div className={generateCN(isOpen, 'dropdown-body', 'opened')}>
          <div className={s['dropdown-body__search']}>
            <div className={s['dropdown-body__search-icon']}>
              <SearchIcon />
            </div>
            <input
              type="text"
              placeholder="Search..."
              onChange={handleSearch}
            />
          </div>
          <div className={s['dropdown-body__options']}>
            {results.length ? (
              results.map((i) => {
                return (
                  <div
                    key={i.value}
                    className={s['dropdown-body__item']}
                    onClick={() => {
                      handleOnSelect(i)
                    }}
                    data-value={i.value}
                  >
                    {i.title}
                  </div>
                )
              })
            ) : (
              <div
                className={
                  s['dropdown-body__item'] +
                  ' ' +
                  s['dropdown-body__item_empty']
                }
              >
                Nothing found...
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default DropDown
