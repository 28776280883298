import React, { FC } from 'react'
import { useDispatch } from 'react-redux'
import { CrossIcon } from '../../../../assets/icons'
import { modalActions } from '../../modal.actions'

import s from './styles.module.scss'

interface IProps {
  img: string
}

export const ImageZoomer: FC<IProps> = ({ img }) => {
  const dispatch = useDispatch()

  const onClose = () => {
    dispatch(modalActions.closeModal())
  }

  return (
    <div className={s['image-zoomer']}>
      <img src={img} alt="" className={s['image']} />
      <button className={s['close-btn']} onClick={onClose}>
        <CrossIcon />
      </button>
    </div>
  )
}
