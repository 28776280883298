import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastActions } from '../../components/toast/toast.slice'
import { getCities } from './cities.requests'

import { IResponseError } from '../../app-types/response-types/response-error'
import { IGetCitiesReq, IGetCitiesRes } from './cities.types'

export const getCitiesThunk = createAsyncThunk<
  IGetCitiesRes,
  IGetCitiesReq,
  { rejectValue: IResponseError }
>('cities', async (args, { dispatch, rejectWithValue }) => {
  try {
    const data = await getCities(args)

    return data
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'cities-status-get',
        type: 'failed',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})
