import cn from 'classnames'
import { FC, ReactNode } from 'react'
import { Color } from '../../../app-types/entities-types/color.types'

import './index.scss'

interface IProps {
  children: string | number | ReactNode
  type?: 'c2' | 'c3' | 'c3-up' | 'b2' | 'b2-slab' | 'b3'
  color?: Color
  className?: string
}
const Text: FC<IProps> = ({
  children,
  type = 'b2',
  color = 'neutral-black',
  className,
}) => {
  return (
    <span className={cn([className, 'Text', `Text_${type}`, `c-${color}`])}>
      {children}
    </span>
  )
}

export default Text
