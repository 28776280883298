import React, {
  ChangeEventHandler,
  MouseEventHandler,
  useEffect,
  useState,
} from 'react'
import s from './styles.module.scss'

type Props = {
  onChange: (isCheck: boolean) => void
  defaultValue?: boolean
  value?: boolean
}

export const Switch = ({ onChange, value, defaultValue }: Props) => {
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    if (defaultValue !== undefined && defaultValue !== value) {
      setChecked(defaultValue)
      onChange(defaultValue)
    } else if (value !== undefined) {
      setChecked(value)
    }
  }, [defaultValue])

  const handleClick: ChangeEventHandler<HTMLInputElement> = (e) => {
    setChecked(e.target.checked)
    onChange(e.target.checked)
  }

  return (
    <label role="button" className={s.wrapper}>
      <input
        className={s.check}
        onChange={handleClick}
        type="checkbox"
        checked={checked}
        hidden
      />
      <div className={s.switch}>
        <div className={s.circle}></div>
      </div>
    </label>
  )
}
