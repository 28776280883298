import { lazy } from 'react'
import { RouteObject } from 'react-router'
import { AuthLayout } from '../components/loyauts/auth/AuthLayout'
import { DashboardLayout } from '../components/loyauts/dashboard/DashboardLayout'
import { RouteBook } from '../components/menu/constants'

const ClientDetailsPage = lazy(
  () => import('../pages/client-details/client-details.page')
)
const SignIn = lazy(() => import('../pages/singIn/SignIn'))

const ClientsPage = lazy(() => import('../pages/clients/clients.page'))
const OrdersAcceptedPage = lazy(
  () => import('../pages/orders/orders-accepted.page')
)
const OrdersDismissedPage = lazy(
  () => import('../pages/orders/orders-dismissed/orders-dismissed.page')
)
const OrdersHistoryPage = lazy(
  () => import('../pages/orders/orders-history/orders-history.page')
)
const OrdersPendingPage = lazy(
  () => import('../pages/orders/orders-pending.page')
)
const OrdersTodayPage = lazy(() => import('../pages/orders/orders-today.page'))

const Staff = lazy(() => import('../pages/staff/staff'))

const StaffAddMember = lazy(
  () => import('../pages/staff/sub-pages/staff-add-member/staff-add-member')
)
const StaffEditMember = lazy(
  () => import('../pages/staff/sub-pages/staff-edit-member/staff-edit-member')
)
const StaffDetails = lazy(
  () => import('../pages/staff/sub-pages/staff-details/staff-details')
)
const WalkDetails = lazy(() => import('../pages/walk-details/walk-details'))
const OrdersPage = lazy(() => import('../pages/orders-new-realization/orders'))

export const routeConfig: RouteObject[] = [
  {
    path: '/',
    element: <DashboardLayout />,
    children: [
      // {
      // path: '/',
      // element: <OrdersPendingPage />,
      // },
      {
        path: '/',
        element: <OrdersPage />,
      },
      {
        path: 'orders',
        element: <OrdersPage />,
        children: [
          {
            path: 'pending',
            element: <OrdersPage />,
          },
          {
            path: 'accepted',
            element: <OrdersPage />,
          },
          {
            path: 'today',
            element: <OrdersPage />,
          },
          {
            path: 'dismissed',
            element: <OrdersPage />,
          },
          {
            path: 'history',
            element: <OrdersPage />,
          },
          {
            path: ':id',
            element: <WalkDetails />,
          },
        ],
      },
      // {
      // path: 'orders',
      // children: [
      // {
      // path: 'pending',
      // element: <OrdersPendingPage />,
      // },
      // {
      // path: 'dismissed',
      // element: <OrdersDismissedPage />,
      // },
      // {
      // path: 'today',
      // element: <OrdersTodayPage />,
      // },
      // {
      // path: 'accepted',
      // element: <OrdersAcceptedPage />,
      // },
      // {
      // path: 'history',
      // element: <OrdersHistoryPage />,
      // },
      // {
      // path: ':id',
      // element: <WalkDetails />,
      // },
      // ],
      // },
      {
        path: RouteBook.WalkDetails,
        element: <WalkDetails />,
      },
      {
        path: RouteBook.Staff,
        element: <Staff />,
      },
      {
        path: RouteBook.StaffUserDetails,
        element: <StaffDetails />,
      },
      {
        path: RouteBook.StaffAddMember,
        element: <StaffAddMember />,
      },
      {
        path: RouteBook.StaffEditMember,
        element: <StaffEditMember />,
      },
      {
        path: 'clients',
        children: [
          {
            path: '/clients',
            element: <ClientsPage />,
          },
          {
            path: ':id',
            element: <ClientDetailsPage />,
          },
        ],
      },
    ],
  },
  {
    path: '*',
    element: <AuthLayout />,
    children: [
      {
        path: '*',
        element: <SignIn />,
      },
    ],
  },
]
