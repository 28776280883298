import s from './styles.module.scss'

import { FC } from 'react'
import { Menu } from '../../menu/Menu'
import { Header } from './components/Header'
import { Outlet, useLocation, Navigate } from 'react-router'
import { Modal } from '../../modal/modal'
import { useSelector } from 'react-redux'
import { modalSelectors } from '../../modal/modal.selectors'

export const DashboardLayout: FC = ({ children }) => {
  const location = useLocation()
  const modalState = useSelector(modalSelectors.fullState)

  if (location.pathname === '/')
    return <Navigate to="/orders/pending" replace />

  return (
    <div className={s.wrapper}>
      <Menu />

      <div className={s['content-wrapper']}>
        <Header />
        <div className={s.main}>
          <Outlet />
          <div id="add_order_portal" className={s.add_order_portal} />
          <div id="order_filters_portal" className={s.add_order_portal} />
          <Modal {...modalState} />
        </div>
      </div>
    </div>
  )
}
