import { get } from '../../services/api'
import { WORKER_API } from '../../services/api/api.config'

import { IGetCitiesReq, IGetCitiesRes } from './cities.types'

export const getCities = async ({ params }: IGetCitiesReq) => {
  const url = '/worker/cities'

  try {
    const { data } = await get<IGetCitiesRes>(url, {
      baseURL: WORKER_API,
      params,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
