import { createAsyncThunk } from '@reduxjs/toolkit'

import { IResponseError } from '../../app-types/response-types/response-error'
import { modalActions } from '../../components/modal/modal.actions'
import { toastActions } from '../../components/toast/toast.slice'
import { getItemJSON } from '../../helpers/local-storage'
import { UnboxPromise } from '../../helpers/types'
import { getMapCoordsBySearch } from '../../services/requests/map/map'
import { GetMapCoordsBySearchReq } from '../../services/requests/map/types'
import {
  bulkResetOrders,
  bulkUpdateOrdersStatuses,
  bulkUpdateOrdersWorker,
  getOrdersList,
  getOrderTotalCounts,
  getTodayOrdersCounts,
  resetOrder,
  updateAdminNote,
  updateOrderDiscount,
  updateOrderInfo,
  updateOrderStatus,
  updateOrderWorker,
} from '../../services/requests/orders/orders-new-realization'
import {
  IBulkUpdateOrdersStatusesReq,
  IGetOrdersListReq,
  IGetOrderTotalCountsRes,
  IGetTodayOrdersCountsRes,
  IResetBulkOrderReq,
  IResetOrderReq,
  IUpdateAdminNoteReq,
  IUpdateOrderDiscount,
  IUpdateOrderInfoReq,
  IUpdateOrdersWorkerBulkReq,
  IUpdateOrderWorkerReq,
  IUpdateStatusReq,
} from '../../services/requests/orders/orders-new.types'
import { synchronizationActions } from '../../store/synchronization/synchronization.slice'
import { OrderPageStatuses } from '../orders/constants'
import { ordersActions } from './orders.slice'

interface IRejectedValue {
  rejectValue: IResponseError
}

export const getOrdersListThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof getOrdersList>>,
  IGetOrdersListReq,
  IRejectedValue
>('orders/GET_ORDERS_LIST', async (args, { rejectWithValue }) => {
  try {
    const data = await getOrdersList(args)
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const syncOrderListThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof getOrdersList>>,
  IGetOrdersListReq,
  IRejectedValue
>('orders/SYNC_ORDERS_LIST', async (args, { dispatch, rejectWithValue }) => {
  dispatch(synchronizationActions.toggleSynchronization(true))
  try {
    const data = await getOrdersList(args)
    dispatch(synchronizationActions.toggleSynchronization(false))
    return data
  } catch (error) {
    dispatch(synchronizationActions.toggleSynchronization(false))
    return rejectWithValue(error as IResponseError)
  }
})

export const updateOrderStatusThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof updateOrderStatus>>,
  IUpdateStatusReq,
  IRejectedValue
>('orders/UPDATE_ORDER_STATUS', async (args, { dispatch, rejectWithValue }) => {
  try {
    const status = await updateOrderStatus(args)
    dispatch(
      toastActions.showToast({
        key: 'order-status-update',
        type: 'success',
      })
    )
    // if status updating from modal window
    dispatch(modalActions.closeModal())

    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: args.pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )
    return status
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'order-status-update',
        type: 'failed',
      })
    )
    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: args.pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )
    return rejectWithValue(error as IResponseError)
  }
})

export const bulkUpdateOrdersStatusesThunk = createAsyncThunk<
  void,
  IBulkUpdateOrdersStatusesReq,
  IRejectedValue
>(
  'orders/BULK_UPDATE_ORDERS_STATUSES',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const pageStatus = await bulkUpdateOrdersStatuses(args)
      dispatch(
        toastActions.showToast({
          key: 'order-status-update',
          type: 'success',
        })
      )
      dispatch(
        syncOrderListThunk(
          getItemJSON('paramsForGettiingOrderList') || {
            status: pageStatus,
            pageNumber: 0,
            pageSize: 50,
          }
        )
      ).then(() => {
        dispatch(ordersActions.clearBulkOrders())
      })
    } catch (error) {
      dispatch(
        toastActions.showToast({
          key: 'order-status-update',
          type: 'failed',
        })
      )

      return rejectWithValue(error as IResponseError)
    }
  }
)

export const resetOrderThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof resetOrder>>,
  IResetOrderReq,
  IRejectedValue
>('orders/RESET_ORDER', async (args, { dispatch, rejectWithValue }) => {
  try {
    const { status, pageStatus } = await resetOrder(args)

    dispatch(
      toastActions.showToast({
        key: 'order-reseting',
        type: 'failed',
      })
    )

    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )

    return { status, pageStatus }
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'order-reseting',
        type: 'success',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})

export const bulkResetOrdersThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof bulkResetOrders>>,
  IResetBulkOrderReq,
  IRejectedValue
>('orders/BULK_RESET', async (args, { dispatch, rejectWithValue }) => {
  try {
    const { orders, pageStatus } = await bulkResetOrders(args)

    dispatch(
      toastActions.showToast({
        withVariable: {
          variable: 'count',
          variableValue: orders.length.toString(),
        },
        key: 'bulk-order-reseting',
        type: 'success',
      })
    )

    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    ).then(() => {
      dispatch(ordersActions.clearBulkOrders())
    })
    return { orders, pageStatus }
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'bulk-order-reseting',
        type: 'failed',
      })
    )
    return rejectWithValue(error as IResponseError)
  }
})

export const updateOrderWorkerThunk = createAsyncThunk<
  void,
  IUpdateOrderWorkerReq,
  IRejectedValue
>('orders/UPDATE_ORDER_WORKER', async (args, { dispatch, rejectWithValue }) => {
  try {
    const pageStatus = await updateOrderWorker(args)
    dispatch(
      toastActions.showToast({
        key: 'order-worker-update',
        type: 'success',
      })
    )
    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'order-worker-update',
        type: 'failed',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})

export const bulkUpdateOrdersWorkerThunk = createAsyncThunk<
  void,
  IUpdateOrdersWorkerBulkReq,
  IRejectedValue
>('orders/UPDATE_ORDER_WORKER', async (args, { dispatch, rejectWithValue }) => {
  try {
    const pageStatus = await bulkUpdateOrdersWorker(args)

    dispatch(
      toastActions.showToast({
        key: 'order-worker-update',
        type: 'success',
      })
    )

    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    ).then(() => {
      dispatch(ordersActions.clearBulkOrders())
    })
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'order-worker-update',
        type: 'failed',
      })
    )
    return rejectWithValue(error as IResponseError)
  }
})

export const syncOrderListAfterPushThunk = createAsyncThunk<
  void,
  keyof typeof OrderPageStatuses,
  IRejectedValue
>('orders/SYNC_ORDER_LIST_AFTER_PUSH', async (args, { dispatch }) => {
  dispatch(
    syncOrderListThunk(
      getItemJSON('paramsForGettiingOrderList') || {
        status: args,
        pageNumber: 0,
        pageSize: 50,
      }
    )
  )
})

export const updateOrderAdminNoteThunk = createAsyncThunk<
  void,
  IUpdateAdminNoteReq,
  IRejectedValue
>('orders/UPDATE_ADMIN_NOTE', async (args, { dispatch, rejectWithValue }) => {
  try {
    const { pageStatus } = await updateAdminNote(args)

    dispatch(
      toastActions.showToast({
        key: 'order-admin-note-update',
        type: 'success',
      })
    )
    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'order-admin-note-update',
        type: 'failed',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})

export const updateOrderDiscountThunk = createAsyncThunk<
  void,
  IUpdateOrderDiscount,
  IRejectedValue
>(
  'orders/UPDATE_ORDER_DISCOUNT',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const pageStatus = await updateOrderDiscount(args)
      dispatch(
        toastActions.showToast({
          key: 'order-discount-update',
          type: 'success',
        })
      )

      dispatch(
        syncOrderListThunk(
          getItemJSON('paramsForGettiingOrderList') || {
            status: pageStatus,
            pageNumber: 0,
            pageSize: 50,
          }
        )
      )
    } catch (error) {
      dispatch(
        toastActions.showToast({
          key: 'order-discount-update',
          type: 'failed',
        })
      )
      return rejectWithValue(error as IResponseError)
    }
  }
)

export const getTodayOrdersCountsThunk = createAsyncThunk<
  IGetTodayOrdersCountsRes,
  undefined,
  IRejectedValue
>('orders/counts', async (args, { rejectWithValue }) => {
  try {
    const data = await getTodayOrdersCounts()
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const getMapCoordsBySearchThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof getMapCoordsBySearch>>,
  GetMapCoordsBySearchReq,
  IRejectedValue
>('map/coords_by_search', async (args, { rejectWithValue }) => {
  try {
    const data = await getMapCoordsBySearch(args)
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const getOrdersTotalCountsThunk = createAsyncThunk<
  IGetOrderTotalCountsRes,
  undefined,
  IRejectedValue
>('order/GET_ORDERS_TOTAL_COUNTS', async (args, { rejectWithValue }) => {
  try {
    const data = await getOrderTotalCounts()
    return data
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const updateOrderInfoThunk = createAsyncThunk<
  void,
  IUpdateOrderInfoReq,
  IRejectedValue
>('orders/UPDATE_ORDER_INFO', async (args, { dispatch, rejectWithValue }) => {
  try {
    const pageStatus = await updateOrderInfo(args)
    dispatch(
      toastActions.showToast({
        key: 'update-order-info',
        type: 'success',
      })
    )

    dispatch(
      syncOrderListThunk(
        getItemJSON('paramsForGettiingOrderList') || {
          status: pageStatus,
          pageNumber: 0,
          pageSize: 50,
        }
      )
    )
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'update-order-info',
        type: 'failed',
      })
    )
    return rejectWithValue(error as IResponseError)
  }
})
