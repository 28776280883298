import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { debounce } from '../helpers'

import { ISuperSelectOption } from '../components/forms/super-select/utils'
import { ICity } from '../store/cities/cities.types'
import { citiesApi, citiesSelectors } from '../store/cities/cities.slice'

const mapCitiesToSelectOptions = (
  cities: Array<ICity> | null
): Array<ISuperSelectOption> => {
  return cities
    ? cities.map(({ _id, name }) => ({ label: name, value: _id }))
    : []
}

export const useCitiesOptions = (countryId: string) => {
  const dispatch = useDispatch()
  const { data, isLoading, error } = useSelector(citiesSelectors.getAllState)

  const [searchedCity, setSearchedCity] = useState('')

  const onCitySearch = useCallback(
    debounce((value: string) => {
      setSearchedCity(value)
    }, 800),
    []
  )

  useEffect(() => {
    if (!countryId || !searchedCity) return
    dispatch(
      citiesApi.getCities({
        params: { country: countryId, city: searchedCity },
      })
    )
  }, [searchedCity, countryId, dispatch])

  return {
    onCitySearch,
    cities: mapCitiesToSelectOptions(data),
    isLoading,
    isError: error,
  }
}
