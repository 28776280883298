import { RootState } from '../../store/store'

export const staffSelectors = {
  workers: (state: RootState) => state.staff.workers,
  selectedWorker: (state: RootState) => state.staff.selectedWorker,
  loading: (state: RootState) => state.staff.loading,
  walksLoading: (state: RootState) => state.staff.walksLoading,
  walks: (state: RootState) => state.staff.walks,
  filters: (state: RootState) => state.staff.filters,
}
