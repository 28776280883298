import s from '../styles.module.scss'
import { LocationIcon, SearchIcon } from '../../../../assets/icons'
import { Image } from 'react-bootstrap'
import { Text, TextInput } from '../../..'
import { useForm, Controller } from 'react-hook-form'
import { useSelector } from 'react-redux'
import defaultProfile from '../../../../assets/images/DefaultProfile.png'
import { userSelectors } from '../../../../store/user/user.selectors'

type Values = {
  search: string
}

export const Header = () => {
  const user = useSelector(userSelectors.getUser)
  const { handleSubmit, control } = useForm<Values>({})
  const onSubmit = ({ search }: Values) => {}

  return (
    <header className={s.header}>
      <div className={s.search}>
        <SearchIcon className={s.searchIcon} />
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="search"
            control={control}
            rules={{ required: true }}
            key="headerSearch"
            render={({ field }) => (
              <TextInput
                fieldProps={field}
                inputClassName={s.searchInput}
                placeholder="dashboard.search"
                controlId="headerSearch"
                type="text"
              />
            )}
          />
        </form>
        <div className={s.profile}>
          <div className={s.location}>
            <LocationIcon />
            <Text className="ml-3 mr-4">
              {user ? user?.address.country.name : 'no-city'}
            </Text>
            {/*<ArrowUpIcon className="flipX" />*/}
          </div>
          {/*
          <div className={s.bell} role="button">
            <BellIcon className={s.bellIcon} />
          </div>
          */}
          <div className="d-flex align-items-center" role="button">
            <Image
              className={s.avatar}
              src={user?.imageUrl || defaultProfile}
            />
            {/*  <ArrowUpIcon className="flipX ml-2" />   */}
          </div>
        </div>
      </div>
    </header>
  )
}
