import React, { MutableRefObject, useEffect } from 'react'

// interface IOutsideClick {
//   ref: React.RefObject<HTMLElement>
//   callback: Function
// }

const useOutsideClick = (
  ref: MutableRefObject<any>,
  callback: Function,
  isDisabled: boolean = false
) => {
  const handleClick = (e: any) => {
    if (ref?.current && !ref?.current.contains(e.target)) {
      callback()
    }
  }

  useEffect(() => {
    if (!isDisabled) {
      document.addEventListener('click', handleClick)
    }

    return () => {
      if (!isDisabled) document.removeEventListener('click', handleClick)
    }
  })
}

export default useOutsideClick
