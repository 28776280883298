import React, {
  useState,
  useRef,
  useEffect,
  Ref,
  useMemo,
  useCallback,
} from 'react'
import { useTranslation } from 'react-i18next'
import { forwardRef } from 'react'

import useOutsideClick from '../../../hooks/use-outside-click'

import { Chevron, SearchIcon } from '../../../assets/icons'
import { ControllerRenderProps } from 'react-hook-form'
// import SELECT_TYPES from './select.constants'

import './index.scss'
import { debounce } from '../../../helpers'

export interface ISelectOption {
  title: string
  value: string
}
interface ISelect {
  onSelect: Function
  label: string
  value: ISelectOption | undefined
  error?: string
  comment: string
  options: ISelectOption[] | []
  placeholder?: string
  type?: string
  withSearch?: boolean
  onSearch?: (value: string) => void
  fieldProps?: ControllerRenderProps<any, any>
  disabled?: boolean
  selectListClassName?: string
  selectButtonClassName?: string
  pageRef?: Ref<HTMLElement>
  withLazyLoading?: boolean
  lazyLoadingCallback?: (pageNumber: number) => Promise<any>
  pagesCount?: number | null
  pageSize?: number
}

const Select = forwardRef(
  ({
    onSelect,
    label,
    fieldProps,
    value,
    error,
    comment,
    options = [],
    placeholder = '',
    type = 'with-label',
    withSearch = false,
    onSearch,
    disabled,
    selectListClassName,
    selectButtonClassName,
    withLazyLoading,
    lazyLoadingCallback = () => Promise.resolve(),
    pagesCount,
    pageSize = 50,
  }: ISelect): any => {
    const { t } = useTranslation()
    const [isOpen, setOpen] = useState<React.SetStateAction<boolean>>(false)
    const [selected, setSelected] = useState<ISelectOption | undefined>(value)
    const selectRef = useRef<HTMLDivElement>(null)
    const positionRef = useRef<number | undefined>(0)
    const [isListUnderButton, setIsListUnderButton] = useState(true)
    const [isObserving, setIsObserving] = useState(false)
    const [isOptionsLoading, setIsOptionsLoading] = useState(false)
    const bodyRef = useRef(null)
    const lastOptionRef = useRef(null)

    useEffect(() => {
      if (
        value?.value !== selected?.value &&
        fieldProps?.value !== selected?.value
      ) {
        setSelected(value)
      }
    }, [fieldProps, value])

    const getLabel = () => {
      if (!selected)
        return (
          <span className="select__dropdown_value">
            {t(placeholder || label)}
          </span>
        )
      if (selected) return selected.title
    }

    const checkSelectedItem = (item: ISelectOption) => {
      if (type === 'without-label') {
        if (selected?.value === item?.value) return true
        else return false
      } else {
        if (selected?.value === item?.value) return true
        else return false
      }
    }

    const toggleDropdown = () => {
      positionRef.current = selectRef.current?.offsetTop
      if (
        (selectRef.current &&
          selectRef.current?.offsetParent &&
          // @ts-ignore
          selectRef.current?.offsetParent?.offsetTop + 280 >
            window.innerHeight) ||
        (selectRef.current &&
          selectRef.current?.offsetTop + 280 > window.innerHeight)
      ) {
        setIsListUnderButton(false)
      }

      options.length && !disabled && setOpen(!isOpen)
    }

    useOutsideClick(selectRef, () => setOpen(false), disabled)

    const setDropdownModifiers = () => {
      if (isOpen) {
        return '-open'
      } else if ((!options.length && error) || (error && disabled)) {
        return '-error select__dropdown-disabled'
      } else if (error) {
        return '-error'
      } else if (!options.length) {
        return '-disabled'
      } else if (disabled) {
        return ' select__dropdown-disabled'
      }

      return ''
    }

    const debouncedSearch = debounce(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onSearch) onSearch(e.target.value || 'a')
      },
      1000
    )

    const observerOptions: IntersectionObserverInit = useMemo(
      () => ({
        root: bodyRef.current,
        rootMargin: '0px',
        threshold: 0,
      }),
      [bodyRef]
    )

    const observerCallback: IntersectionObserverCallback = useCallback(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          const pageNumber = options ? Math.ceil(options?.length / pageSize) : 0
          if (pagesCount !== pageNumber) {
            observer.disconnect()
            setIsOptionsLoading(true)
            lazyLoadingCallback(pageNumber).then(() => {
              setIsObserving(false)
              setIsOptionsLoading(false)
            })
          } else {
            observer.disconnect()
          }
        }
      },
      [lazyLoadingCallback, options, pagesCount, pageSize]
    )

    const observer = useMemo(() => {
      if (withLazyLoading) {
        return new IntersectionObserver(observerCallback, observerOptions)
      } else {
        return null
      }
    }, [withLazyLoading, observerCallback, observerOptions])

    useEffect(() => {
      observer?.disconnect()
      setIsObserving(false)
    }, [options])

    useEffect(() => {
      if (!isObserving && observer && lastOptionRef.current && bodyRef) {
        observer.observe(lastOptionRef.current)
        setIsObserving(true)
      }
    }, [lastOptionRef, observer, bodyRef, isObserving])

    return (
      <div ref={selectRef} className="select">
        {label || error || comment ? (
          <div className="select__header">
            <div className="select__header_left-side">
              <label className="select__label">{t(label)}</label>
              <p className="select__comment">{error ? '' : t(comment)}</p>
            </div>
            <p className="select__error">{error && t(error)}</p>
          </div>
        ) : null}
        <div
          className={`select__dropdown${setDropdownModifiers()} ${selectButtonClassName}`}
        >
          <div
            className="select__dropdown_header"
            onClick={() => {
              toggleDropdown()
            }}
          >
            <p className="select__dropdown_title">{getLabel()}</p>
            <div
              className={`select__dropdown_icon${isOpen ? '-reverted' : ''}`}
            >
              <Chevron />
            </div>
          </div>
          <div
            ref={bodyRef}
            className={`select__dropdown_body ${
              isOpen ? 'open' : ''
            } ${selectListClassName} ${isListUnderButton ? '' : 'open-top'}`}
          >
            {!withSearch || !onSearch ? (
              <div className="select__dropdown_list">
                {options.map((item, idx) => (
                  <div
                    className="select__dropdown_inner"
                    key={item.value}
                    ref={
                      idx === options.length - 1 && withLazyLoading
                        ? lastOptionRef
                        : null
                    }
                    onClick={(e) => {
                      e.stopPropagation()
                      setSelected(item)
                      onSelect(item)
                      toggleDropdown()
                    }}
                  >
                    <div className="select__dropdown_item" id={item.value}>
                      <div
                        className={`select__dropdown_circle ${
                          checkSelectedItem(item)
                            ? 'select__dropdown_circle-selected'
                            : ''
                        }`}
                      >
                        <div className="select__dropdown_circle-border"></div>
                      </div>
                      <p className="select__dropdown_item__label">
                        {item?.title || null}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div>
                <div className="select__dropdown_search">
                  <span className="select__dropdown_search-icon">
                    <SearchIcon />
                  </span>
                  <input
                    type="text"
                    placeholder="Search..."
                    disabled={isOptionsLoading}
                    onChange={(e) => debouncedSearch(e)}
                    onClick={(e) => e.stopPropagation()}
                  />
                </div>
                <div className="select__dropdown_list">
                  {options.length > 0 ? (
                    options.map((item, idx) => (
                      <div
                        className="select__dropdown_inner"
                        key={item.value}
                        ref={
                          idx === options.length - 1 && withLazyLoading
                            ? lastOptionRef
                            : null
                        }
                        onClick={(e) => {
                          e.stopPropagation()
                          setSelected(item)
                          onSelect(item)
                          toggleDropdown()
                        }}
                      >
                        <div className="select__dropdown_item" id={item.value}>
                          <div
                            className={`select__dropdown_circle ${
                              checkSelectedItem(item)
                                ? 'select__dropdown_circle-selected'
                                : ''
                            }`}
                          >
                            <div className="select__dropdown_circle-border"></div>
                          </div>
                          <p className="select__dropdown_item__label">
                            {item?.title || null}
                          </p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div
                      className="select__dropdown_item"
                      style={{ cursor: 'default' }}
                    >
                      Nothing found...
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div />
        </div>
      </div>
    )
  }
)
Select.displayName = 'Select'

export default Select
