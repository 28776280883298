import { AuthStep } from './types'
import {
  resolveSetPhoneThunk,
  setPhoneThunk,
  checkCodeThunk,
  resolveCheckCodeThunk,
  logOutThunk,
} from './thunks'
import { createSlice, createAction } from '@reduxjs/toolkit'
import { removeItem } from '../../helpers/local-storage'

const initialState = {
  loading: false,
  code: null as string | null,
  step: 'set-phone' as AuthStep,
  auth: {
    accessToken: null as string | null,
    refreshToken: null as string | null,
    expiresIn: null as number | null,
  },
}

export type AuthState = typeof initialState

const setStep = createAction(
  'auth/setStep',
  ({ step, code }: { step: AuthStep; code: string | null }) => ({
    payload: {
      step,
      code,
    },
  })
)

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setStep, (state, action) => {
      state.code = action.payload.code
      state.step = action.payload.step
    })
    builder.addCase(logOutThunk.fulfilled, (state, action) => {
      localStorage.clear()
      window.location.replace('/login')
      return initialState
    })
    builder.addCase(logOutThunk.rejected, (state, action) => {
      localStorage.clear()
      window.location.replace('/login')
      return initialState
    })
    resolveSetPhoneThunk(builder), resolveCheckCodeThunk(builder)
  },
})

export const authReducer = authSlice.reducer

export const authActions = {
  setStep,
}

export const authApi = {
  setPhone: setPhoneThunk,
  checkCode: checkCodeThunk,
  logOut: logOutThunk,
}
