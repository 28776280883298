import cn from 'classnames'
import { t } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

import { TextInput } from '../../..'
import { IOrder } from '../../../../app-types/entities-types/order.types'
import { CrossIcon, FailedIcon, SuccessIcon } from '../../../../assets/icons'
import { useAppDispatch } from '../../../../helpers'
import { ordersApi } from '../../../../pages/orders-new-realization/orders.slice'
import { getDiscountReasons } from '../../../../services/requests/orders/orders-new-realization'
import { IPageStatus } from '../../../../services/requests/orders/orders-new.types'
import { updateDiscountReq } from '../../../../services/requests/orders/types'
import Radio from '../../../inputs/radio/radio'
import { modalActions } from '../../modal.actions'

import s from './styles.module.scss'

interface IProps {
  order: IOrder
  currency: string
  pageStatus: IPageStatus
}

interface IFormValues {
  discount: number
  reasonId: string
}

export const AddDiscountModal: FC<IProps> = ({
  order,
  currency,
  pageStatus,
}) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    defaultValues: { discount: order.price?.discount?.admin || 0 },
  })

  const [discountReasons, setDiscountReasons] = useState<
    { id: string; name: string }[]
  >([])
  const [isFetching, setIsFetching] = useState(false)
  const [isAddingSucces, setIsAddingSuccess] = useState<boolean | null>(null)

  useEffect(() => {
    getDiscountReasons().then((res) => {
      setDiscountReasons(res.result)
    })
  }, [])

  const renderContent = () => {
    switch (isAddingSucces) {
      case true:
        return (
          <div className={s['result-container']}>
            <SuccessIcon />
            <p className={s['result-container__title']}>
              {t('clients.details.add-coins.success-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('clients.details.add-coins.success-text')}
            </p>
          </div>
        )
      case false:
        return (
          <div className={s['result-container']}>
            <FailedIcon />
            <p className={s['result-container__title']}>
              {t('clients.details.add-coins.failed-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('clients.details.add-coins.failed-text')}
            </p>
          </div>
        )

      default:
        return (
          <>
            <div>
              <p className={s['modal__title']}>Add discount to the order</p>
              <p className={s['modal__subtitle']}>
                You’re adding discount to the
                <span className={s['modal__subtitle_name']}>
                  {' ' + order._id + ' '}
                </span>
                order
              </p>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className={s['input-container']}>
                <Controller
                  name="discount"
                  control={control}
                  rules={{
                    required: true,
                    validate: (value) =>
                      value >= 0 ||
                      (t('validation-errors.positive-number') as string),
                  }}
                  key="order-discount"
                  render={({ field }) => (
                    <TextInput
                      fieldProps={field}
                      controlId="discount"
                      type="number"
                      label="orders.fields.discount"
                      error={errors.discount?.message}
                      preLabel={currency}
                      placeholder="0.00"
                    />
                  )}
                />
                <div className={s['current-price']}>
                  <p className={s['current-price__text']}>
                    Current order price is{' '}
                    <span className={s['current-price__text_bold']}>
                      {currency}
                      {order.price.priceTotal}
                    </span>
                  </p>
                </div>
              </div>
              <div className={s['reasons']}>
                <p className={s['reasons-title']}>Reason:</p>
                <div className={s['reasons-container']}>
                  {discountReasons.map(({ id, name }) => (
                    <Controller
                      key={id}
                      control={control}
                      name="reasonId"
                      render={({ field }) => (
                        <Radio field={field} value={id} label={name} />
                      )}
                    />
                  ))}
                </div>
              </div>
              <div className={s['btn-container']}>
                <button
                  className={s['confirm-btn']}
                  type="submit"
                  disabled={isFetching}
                >
                  {t('clients.details.add-coins.confirm-btn-text')}
                </button>
              </div>
            </form>
          </>
        )
    }
  }

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const body: updateDiscountReq = {
      orderId: order._id,
      discount: data.discount,
      reasonId: data.reasonId,
      pageStatus,
    }
    setIsFetching(true)

    dispatch(ordersApi.updateOrderDiscount(body))
      .then(() => setIsAddingSuccess(true))
      .catch(() => setIsAddingSuccess(false))
  }

  return (
    <div
      className={cn([
        s['modal-container'],
        {
          [s['modal-container_small']]:
            isAddingSucces === true || isAddingSucces === false,
        },
      ])}
    >
      <button
        className={s['modal__close-btn']}
        onClick={() => dispatch(modalActions.closeModal())}
      >
        <CrossIcon width="10px" height="10px" />
      </button>
      {renderContent()}
    </div>
  )
}
