import React, { useEffect, useState } from 'react'
import { createNoSubstitutionTemplateLiteral } from 'typescript'
import { Text } from '../../../../../../components'

type Props = {
  time: number
}

export const Timer = ({ time }: Props) => {
  const [timer, setTimer] = useState(() => {
    const [mins, secs] = new Date(new Date().getTime() - time || 0)
      .toISOString()
      .substr(14, 5)
      .split(':')
    return Number(mins) * 60 + Number(secs)
  })

  //   const walkTime =
  //     new Date(order.liveDate).getTime() - new Date(order.startDate).getTime()

  //   const walkTimeParsed =
  //     order.status === OrderStatuses.LIVE
  //       ? new Date(walkTime || 0).toISOString().substr(14, 5)
  //       : '0:00'

  useEffect(() => {
    const timeout = setInterval(() => {
      setTimer((time) => time + 1)
    }, 1000)
    return () => {
      clearInterval(timeout)
    }
  }, [])

  const parseTime = () => {
    const mins = (timer / 60) | 0
    const secs = timer % 60
    return `${mins}:${secs >= 10 ? secs : '0' + secs}`
  }

  return (
    <Text isNoTranslate size="14">
      {parseTime()}
    </Text>
  )
}
