import { IStaffFilters, staffState } from './staff.slice'
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit'

export const setFilters = createAction<IStaffFilters>('staff/set-filters')

export const resolveSetFilters = (
  builder: ActionReducerMapBuilder<staffState>
) => {
  builder.addCase(setFilters, (state, action) => {
    state.filters = action.payload
  })
}

export const clearFilters = createAction('staff/clear-filters')

export const resolveClearFilters = (
  builder: ActionReducerMapBuilder<staffState>
) => {
  builder.addCase(clearFilters, (state) => {
    state.filters = {
      workerId: undefined,
      workerName: undefined,
    }
  })
}

export const clearSelectedWorker = createAction('staff/clear-selected-worker')
