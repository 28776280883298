import { FC, useMemo } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import ReactSelect, { InputActionMeta, StylesConfig } from 'react-select'
import cn from 'classnames'

import Text from '../../typography/text/Text'
import { ISuperSelectOption, selectStyles } from './utils'

import { ThemeColors } from '../../../app-types/_enums/colors'

import './index.scss'

interface IProps {
  options: Array<ISuperSelectOption>
  field: ControllerRenderProps<any, any>
  onChange?: (value: ISuperSelectOption) => void
  isMulti?: boolean
  isLoading?: boolean
  isDisabled?: boolean
  isClearable?: boolean
  placeholder?: string
  onInputChange?: (newValue: string, meta: InputActionMeta) => void
  onMenuScrollToBottom?: (event: WheelEvent | TouchEvent) => void
  closeMenuOnSelect?: boolean
  label?: string
  labelClassName?: string
  error?: string
  errorClassName?: string
  defaultValue?: ISuperSelectOption | Array<ISuperSelectOption>
  required?: boolean
}

export const SuperSelect: FC<IProps> = ({
  options,
  field,
  onChange,
  isMulti = false,
  isLoading = false,
  isDisabled = false,
  isClearable = false,
  closeMenuOnSelect = false,
  placeholder = '',
  onInputChange,
  onMenuScrollToBottom,
  label,
  labelClassName = '',
  error,
  errorClassName = '',
  defaultValue,
  required,
}) => {
  const styles: StylesConfig = useMemo(
    () => ({
      ...selectStyles,
      control: (base, state) => ({
        ...base,
        border: (() => {
          if (error) {
            return `1px solid ${ThemeColors['secondary-red-100']}`
          }
          if (state.isFocused) {
            return `1px solid ${ThemeColors['primary-green-70']}`
          }
          return `1px solid ${ThemeColors['neutral-gray-30']}`
        })(),
        backgroundColor: state.isDisabled
          ? ThemeColors['neutral-light-blue']
          : ThemeColors['neutral-white'],
        cursor: state.isDisabled ? 'not-allowed' : 'pointer',
        padding: '2px 0',
        minHeight: '42px',
        display: 'flex',
        alignItems: 'center',
        transition: 'all 0.15s linear',
        boxShadow: 'none',
        '&:hover': {
          border: `1px solid ${ThemeColors['primary-green-70']}`,
        },
      }),
    }),
    [error]
  )

  const onSelect = (value: ISuperSelectOption) => {
    field.onChange(value)
    if (onChange) onChange(value)
  }

  return (
    <div className="Select">
      {/* Error */}
      {label && (
        <div className="Select__label_container">
          <label className={cn([labelClassName, 'Select__label_label'])}>
            {label}{' '}
            {required && <span className="Select__label_asteriks">*</span>}
          </label>
        </div>
      )}

      {/* Select */}
      <ReactSelect
        options={options}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isClearable={isClearable}
        onChange={onSelect}
        onBlur={field.onBlur}
        value={field.value}
        name={field.name}
        ref={field.ref}
        filterOption={undefined}
        onInputChange={onInputChange}
        styles={styles}
        closeMenuOnSelect={closeMenuOnSelect}
        hideSelectedOptions={false}
        placeholder={placeholder}
        onMenuScrollToBottom={onMenuScrollToBottom}
        defaultValue={defaultValue}
        noOptionsMessage={({ inputValue }) => {
          if (!inputValue) {
            return 'Type something to start search...'
          }

          return 'Nothing found...'
        }}
      />
      {/* Error */}
      {error && (
        <div className="Select__error_container">
          <Text
            type="b3"
            color="secondary-red-100"
            className={cn([errorClassName, 'Select__error_text'])}
          >
            {error}
          </Text>
        </div>
      )}
    </div>
  )
}
