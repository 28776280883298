import { ClientIcon, OrdersIcon, StaffIcon } from '../../assets/icons'

export type MenuData = {
  icon: JSX.Element
  title: string
  link?: string
  tabs?: {
    title: string
    count?: string
    link: string
  }[]
}[]

export const RouteBook = {
  Login: '/login',
  Dashboard: '/',
  Walks: '/walks',
  WalkDetails: '/walks/:id',
  OrdersPending: '/orders/pending',
  OrdersDismissed: '/orders/dismissed',
  OrdersToday: '/orders/today',
  OrdersAccepted: '/orders/accepted',
  OrdersHistory: '/orders/history',
  Clients: '/clients',
  ClientDetails: '/clients/:id',
  Staff: '/staff',
  StaffUserDetails: '/staff/:id',
  StaffAddMember: '/staff/add-member',
  StaffEditMember: '/staff/edit-member/:id',
}

type Counts = {
  pending: number
  accepted: number
  today: number
}

export const createMenu = ({ accepted, pending, today }: Counts): MenuData => [
  {
    icon: <OrdersIcon />,
    title: 'menu.orders',
    tabs: [
      {
        title: 'menu.orderTabs.pending',
        count: pending.toString(),
        link: `${RouteBook.OrdersPending}?pageNumber=1`,
      },
      {
        title: 'menu.orderTabs.accepted',
        count: accepted.toString(),
        link: `${RouteBook.OrdersAccepted}?pageNumber=1`,
      },
      {
        title: 'menu.orderTabs.today',
        count: today.toString(),
        link: `${RouteBook.OrdersToday}?pageNumber=1`,
      },
      {
        title: 'menu.orderTabs.dismissed',
        link: `${RouteBook.OrdersDismissed}?pageNumber=1`,
      },
      {
        title: 'menu.orderTabs.history',
        link: `${RouteBook.OrdersHistory}?pageNumber=1`,
      },
    ],
  },
  {
    icon: <ClientIcon />,
    title: 'menu.clients',
    link: `${RouteBook.Clients}?pageNumber=1`,
  },
  {
    icon: <StaffIcon />,
    title: 'menu.staff',
    link: `${RouteBook.Staff}?pageNumber=1&view=list`,
  },
]
