import { RootState } from './../../store/store'

export const clientsSelector = {
  partnerClients: (state: RootState) => state.clients.clients.clients,
  total: (state: RootState) => state.clients.clients.total,
  loading: (state: RootState) => state.clients.loading,
  selectedClient: (state: RootState) => state.clients.selectedClient,
  walksLoading: (state: RootState) => state.clients.walksLoading,
  clientWalks: (state: RootState) => state.clients.clientWalks,
  filters: (state: RootState) => state.clients.filters,
  clientSubscriptions: (state: RootState) => state.clients.clientSubscriptions,
}
