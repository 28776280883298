export enum OrderStatuses {
  PENDING = 'PENDING',
  ACCEPTED = 'ACCEPTED',
  LIVE = 'LIVE',
  DECLINE_BY_SERVICE = 'DECLINE_BY_SERVICE',
  DECLINE_BY_USER = 'DECLINE_BY_USER',
  REASSIGNED_TO_WORKER = 'REASSIGNED_TO_WORKER',
  REMOVED_FROM_WORKER = 'REMOVED_FROM_WORKER',
  CANCEL_BY_SERVICE = 'CANCEL_BY_SERVICE',
  CANCEL_BY_USER = 'CANCEL_BY_USER',
  CANCEL_BY_USER_AFTER_8 = 'CANCEL_BY_USER_AFTER_8',
  FINISHED = 'FINISHED',
}
