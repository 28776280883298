import { async } from '@firebase/util'
import { AxiosResponse } from 'axios'
import { IOrder } from '../../../app-types/entities-types/order.types'
import { get, post, put } from '../../api'
import { BASE_URL, WORKER_API } from '../../api/api.config'
import { OrderStatuses } from './constants'
import { generateParamsForGetOrdersList } from './orders-new.helpers'
import {
  IBulkUpdateOrdersStatusesReq,
  ICreateNewOrderReq,
  IGetDiscountReasonsRes,
  IGetOrderPriceCounterReq,
  IGetOrderPriceCounterRes,
  IGetOrdersListReq,
  IGetOrdersListRes,
  IGetOrderTotalCountsRes,
  IGetTodayOrdersCountsRes,
  IOrderStatus,
  IResetBulkOrderReq,
  IResetOrderReq,
  IUpdateAdminNoteReq,
  IUpdateOrderDiscount,
  IUpdateOrderInfoReq,
  IUpdateOrdersWorkerBulkReq,
  IUpdateOrderWorkerReq,
  IUpdateStatusReq,
  IUpdateStatusRes,
} from './orders-new.types'

export const getOrdersList = async (reqParams: IGetOrdersListReq) => {
  const url = '/order/get-list-in-dashboard'
  const params = generateParamsForGetOrdersList(reqParams)

  try {
    const { data } = await get<IGetOrdersListRes>(url, {
      baseURL: WORKER_API,
      params,
    })
    const { result: orders, totalCount } = data
    return { orders, totalCount }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
export const getTodayOrdersCounts = async () => {
  const url = `/worker/partner-order-count`
  try {
    const { data } = await get<IGetTodayOrdersCountsRes>(url, {
      baseURL: WORKER_API,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getOrderTotalCounts = async () => {
  const url = `/order/order-counts`
  try {
    const { data } = await get<IGetOrderTotalCountsRes>(url, {
      baseURL: WORKER_API,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getOrderByID = async (orderID: string | undefined) => {
  const url = `/order/${orderID}`
  try {
    const { data } = await get<IOrder>(url, { baseURL: WORKER_API })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateOrderStatus = async ({
  orderId,
  updatedStatus,
  oldStatus,
  groupId = null,
  isGroupCard,
  cancelReason,
}: IUpdateStatusReq) => {
  const url =
    groupId && isGroupCard
      ? `/order/status/group/${groupId}`
      : `/order/status/${orderId}`

  let reqData: any = {}
  if (updatedStatus === OrderStatuses.LIVE)
    reqData = { liveDate: new Date().toISOString() }
  if (updatedStatus === OrderStatuses.FINISHED)
    reqData = { endDate: new Date().toISOString() }
  if (groupId && oldStatus) reqData.statusToUpdate = oldStatus

  reqData = { ...reqData, status: updatedStatus, cancelReason }
  try {
    const { status } = await put<IUpdateStatusRes>(url, reqData, {
      baseURL: WORKER_API,
    })

    return status
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const bulkUpdateOrdersStatuses = async ({
  orders,
  pageStatus,
  groups,
  action,
  cancelReason,
}: IBulkUpdateOrdersStatusesReq) => {
  try {
    const url = '/order/status/'

    const requests = [] as Promise<AxiosResponse<IUpdateStatusRes, any>>[]

    orders.forEach(({ id, oldStatus }) => {
      let reqData: any = {}
      let status: IOrderStatus | undefined = undefined

      if (oldStatus === OrderStatuses.ACCEPTED && action === 'ACCEPT')
        reqData = { liveDate: new Date().toISOString() }
      if (oldStatus === OrderStatuses.LIVE && action === 'DECLINE')
        reqData = { endDate: new Date().toISOString() }

      if (pageStatus === 'PENDING' && action === 'ACCEPT') status = 'ACCEPTED'

      if (pageStatus === 'PENDING' && action === 'DECLINE')
        status = 'DECLINE_BY_SERVICE'

      if (
        pageStatus === 'ACCEPTED' &&
        action === 'ACCEPT' &&
        oldStatus === 'LIVE'
      )
        return

      if (
        pageStatus === 'ACCEPTED' &&
        action === 'ACCEPT' &&
        oldStatus === 'ACCEPTED'
      )
        status = 'LIVE'

      if (
        pageStatus === 'ACCEPTED' &&
        action === 'DECLINE' &&
        oldStatus === 'LIVE'
      )
        status = 'FINISHED'

      if (
        pageStatus === 'ACCEPTED' &&
        action === 'DECLINE' &&
        oldStatus === 'ACCEPTED'
      )
        status = 'CANCEL_BY_SERVICE'

      if (
        pageStatus === 'TODAY' &&
        action === 'ACCEPT' &&
        oldStatus === 'PENDING'
      )
        status = 'ACCEPTED'

      if (
        pageStatus === 'TODAY' &&
        action === 'ACCEPT' &&
        oldStatus === 'ACCEPTED'
      )
        status = 'LIVE'

      if (pageStatus === 'TODAY' && action === 'ACCEPT' && oldStatus === 'LIVE')
        return

      if (
        pageStatus === 'TODAY' &&
        action === 'DECLINE' &&
        oldStatus === 'PENDING'
      )
        status = 'DECLINE_BY_SERVICE'

      if (
        pageStatus === 'TODAY' &&
        action === 'DECLINE' &&
        oldStatus === 'ACCEPTED'
      )
        status = 'CANCEL_BY_SERVICE'

      if (
        pageStatus === 'TODAY' &&
        action === 'DECLINE' &&
        oldStatus === 'LIVE'
      )
        status = 'FINISHED'

      reqData = { ...reqData, status, cancelReason }
      requests.push(
        put<IUpdateStatusRes>(url + id, reqData, {
          baseURL: WORKER_API,
        })
      )
    })

    groups &&
      groups.forEach(({ groupId, updatedStatus }) => {
        const groupUrl = `/order/status/group/${groupId}`

        let reqData: any = {}
        if (updatedStatus === OrderStatuses.LIVE)
          reqData = { liveDate: new Date().toISOString() }
        if (updatedStatus === OrderStatuses.FINISHED)
          reqData = { endDate: new Date().toISOString() }
        reqData = { ...reqData, status: updatedStatus, cancelReason }

        requests.push(
          put<IUpdateStatusRes>(groupUrl, reqData, {
            baseURL: WORKER_API,
          })
        )
      })

    await Promise.all(requests)

    return pageStatus
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateOrderWorker = async ({
  orderId,
  workerId,
  groupId,
  pageStatus,
}: IUpdateOrderWorkerReq) => {
  const url = groupId
    ? `/order/worker/group/${groupId}`
    : `/super-admin/order/worker/${orderId}`
  try {
    await put<null>(
      url,
      { worker: workerId },
      { baseURL: groupId ? WORKER_API : BASE_URL }
    )

    return pageStatus
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const bulkUpdateOrdersWorker = async ({
  orders,
  pageStatus,
  workerId,
}: IUpdateOrdersWorkerBulkReq) => {
  const url = '/super-admin/order/worker/'

  const requests = [] as Promise<AxiosResponse<null, any>>[]

  orders.forEach((order) => {
    if (order.oldStatus !== 'PENDING' && order.oldStatus !== 'ACCEPTED') return
    else {
      requests.push(
        put<null>(url + order.id, { worker: workerId }, { baseURL: BASE_URL })
      )
    }
  })

  try {
    await Promise.all(requests)

    return pageStatus
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateAdminNote = async ({
  orderId,
  pageStatus,
  adminNote,
}: IUpdateAdminNoteReq) => {
  const url = 'super-admin/order/admin-note/' + orderId
  try {
    const { status } = await put(url, { adminNote })

    return { status, pageStatus }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateOrderDiscount = async ({
  orderId,
  discount,
  reasonId,
  pageStatus,
}: IUpdateOrderDiscount) => {
  const url = `/order/discount/${orderId}`
  try {
    await put<null>(url, { coins: discount, reasonId }, { baseURL: WORKER_API })

    return pageStatus
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getOrderPriceCounter = async (body: IGetOrderPriceCounterReq) => {
  const url = '/order/counter-price'

  try {
    const { data } = await post<IGetOrderPriceCounterRes>(url, body, {
      baseURL: WORKER_API,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const createNewOrder = async (body: ICreateNewOrderReq) => {
  const url = '/order'
  try {
    await post(url, body, { baseURL: WORKER_API })
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const resetOrder = async ({ id, pageStatus }: IResetOrderReq) => {
  const url = '/order/reset/' + id
  try {
    const { status } = await put(url, undefined, { baseURL: WORKER_API })

    return { status, pageStatus }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const bulkResetOrders = async ({
  orders,
  pageStatus,
}: IResetBulkOrderReq) => {
  const url = '/order/reset/'

  try {
    const requests = [] as Promise<AxiosResponse<any, any>>[]

    orders.forEach((order) => {
      requests.push(
        put<any>(url + order.id, undefined, { baseURL: WORKER_API })
      )
    })

    await Promise.all(requests)

    return { orders, pageStatus }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getDiscountReasons = async () => {
  const url = '/order-discount-reason'

  try {
    const { data } = await get<IGetDiscountReasonsRes>(url, {
      baseURL: WORKER_API,
    })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateOrderInfo = async ({
  id,
  body,
  pageStatus,
}: IUpdateOrderInfoReq) => {
  const url = '/order/update-from-dashboard/' + id

  try {
    await put(url, body, { baseURL: WORKER_API })
    return pageStatus
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
