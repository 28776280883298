export enum EColors {
  'neutral-black' = 'neutral-black',
  'neutral-white' = 'neutral-white',
  'neutral-light-blue' = 'neutral-light-blue',

  'neutral-light-gray' = 'neutral-light-gray',
  'dashboard-neo-gray-100' = 'dashboard-neo-gray-100',
  'dashboard-neo-gray-200' = 'dashboard-neo-gray-200',
  'dashboard-neo-gray-900' = 'dashboard-neo-gray-900',
  'neutral-gray-30' = 'neutral-gray-30',
  'neutral-gray-50' = 'neutral-gray-50',
  'neutral-gray-80' = 'neutral-gray-80',
  'neutral-gray-90' = 'neutral-gray-90',

  'secondary-mint-mint-80' = 'secondary-mint-mint-80',
  'primary-green-10' = 'primary-green-10',
  'primary-green-20' = 'primary-green-20',
  'primary-green-70' = 'primary-green-70',
  'primary-green-90' = 'primary-green-90',
  'primary-green-100' = 'primary-green-100',

  'primary-purple-10' = 'primary-purple-10',
  'primary-purple-20' = 'primary-purple-20',
  'primary-purple-100' = 'primary-purple-100',

  'secondary-red-100' = 'secondary-red-100',
}
export enum ThemeColors {
  'neutral-black' = '#232121',
  'neutral-white' = '#ffffff',
  'neutral-light-blue' = '#f8fafd',

  'neutral-light-gray' = '#efeff2',
  'dashboard-neo-gray-100' = '#f1f3f3',
  'dashboard-neo-gray-200' = '#e1e6e5',
  'dashboard-neo-gray-900' = '#5e7373',
  'neutral-gray-30' = '#d9d9de',
  'neutral-gray-50' = '#b3b3bc',
  'neutral-gray-80' = '#666678',
  'neutral-gray-90' = '#49495f',

  'secondary-mint-mint-80' = '#56cbb9',
  'primary-green-10' = '#edfbf8',
  'primary-green-20' = '#dbf7f1',
  'primary-green-70' = '#4ad9b8',
  'primary-green-90' = '#01c297',
  'primary-green-100' = '#00a47f',

  'primary-purple-10' = '#f1ecfd',
  'primary-purple-20' = '#e3d9fc',
  'primary-purple-100' = '#7540ee',

  'secondary-red-100' = '#ff2f54',
}
