import { UnboxPromise } from './../../helpers/types'
import {
  changeWorkerActive,
  createWorker,
  getAllPartnerWorkers,
  getWorkerByID,
  getWorkerOrders,
  updateWorker,
} from './../../services/requests/staff/staff'
import { staffState } from './staff.slice'
import { createThunkResolver } from '../../helpers/index'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { IResponseError } from '../../app-types/response-types/response-error'
import { toastActions } from '../../components/toast/toast.slice'
export const getWorkerByIdThunk = createAsyncThunk(
  'staff/GET_WORKER_BY_ID',
  getWorkerByID
)
const getWorkerByIdThunkResolver = createThunkResolver<
  staffState,
  UnboxPromise<ReturnType<typeof getWorkerByID>>
>(getWorkerByIdThunk)

export const resolveWorkerByIdThunk = (
  builder: ActionReducerMapBuilder<staffState>
) =>
  getWorkerByIdThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.selectedWorker = action.payload
    })
    .onRejected()

export const getAllPartnerWorkersThunk = createAsyncThunk(
  'staff/GET_ALL_PARTNER_WORKERS',
  getAllPartnerWorkers
)
const getAllPartnerWorkersThunkResolver = createThunkResolver<
  staffState,
  UnboxPromise<ReturnType<typeof getAllPartnerWorkers>>
>(getAllPartnerWorkersThunk)

export const resolveAllPartnerWorkersThunk = (
  builder: ActionReducerMapBuilder<staffState>
) =>
  getAllPartnerWorkersThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.workers.workers = [...action.payload.result]
      state.workers.total = action.payload.totalCount
    })
    .onRejected()

//worker creating

export const createWorkerThunk = createAsyncThunk(
  'staff/CREATE_WORKER',
  createWorker
)
const createWorkerThunkResolver = createThunkResolver<
  staffState,
  UnboxPromise<ReturnType<typeof createWorker>>
>(createWorkerThunk)

export const resolveCrateWorkerThunk = (
  builder: ActionReducerMapBuilder<staffState>
) => createWorkerThunkResolver(builder).onPending().onFulfilled().onRejected()

//worker creating

export const updateWorkerThunk = createAsyncThunk(
  'staff/UPDATE_WORKER',
  updateWorker
)
const updateWorkerThunkResolver = createThunkResolver<
  staffState,
  UnboxPromise<ReturnType<typeof updateWorker>>
>(updateWorkerThunk)

export const resolveUpdateWorkerThunk = (
  builder: ActionReducerMapBuilder<staffState>
) => updateWorkerThunkResolver(builder).onPending().onFulfilled().onRejected()

// get worker orders

export const getWorkerOrdersThunk = createAsyncThunk(
  'staff/GET_WORKER_ORDERS',
  getWorkerOrders
)
const getWorkerOrdersThunkResolver = createThunkResolver<
  staffState,
  UnboxPromise<ReturnType<typeof getWorkerOrders>>
>(getWorkerOrdersThunk, 'walksLoading')

export const resolveWorkerOrdersThunk = (
  builder: ActionReducerMapBuilder<staffState>
) =>
  getWorkerOrdersThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.walks.walks = action.payload.result
      state.walks.total = action.payload.totalCount
    })
    .onRejected()

export const changeWorkerActiveThunk = createAsyncThunk<
  void,
  { workerId: string; inactive: boolean },
  { rejectValue: IResponseError }
>('staff/CHANGE_WORKER_ACTIVE', async (body, { dispatch, rejectWithValue }) => {
  try {
    await changeWorkerActive(body)

    dispatch(getWorkerByIdThunk(body.workerId))
    dispatch(
      toastActions.showToast({
        type: 'success',
        key: 'change-inactive',
      })
    )
  } catch (error) {
    dispatch(
      toastActions.showToast({
        type: 'failed',
        key: 'change-inactive',
      })
    )
    setTimeout(() => {
      window.location.reload()
    }, 1000)
    return rejectWithValue(error as IResponseError)
  }
})
