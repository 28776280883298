import { Suspense, useEffect } from 'react'
import { Col } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import './assets/styles/main.scss'
import ToastMessage from './components/toast/toast'

import { getItemJSON } from './helpers/local-storage'
import { ordersSelectors } from './pages/orders/orders.selectors'
import { ordersApi } from './pages/orders/orders.slice'
import { ordersSelectors as ordersFromListSelectors } from './pages/orders-new-realization/orders.selectors'
import { ordersApi as newOrdersApi } from './pages/orders-new-realization/orders.slice'

import { Router } from './router/router'
import { AuthType } from './store/auth/types'
import { RootState } from './store/store'
import { userSelectors } from './store/user/user.selectors'
import { userApi } from './store/user/user.slice'
import Notifications from './components/notifications'
import { toastActions } from './components/toast/toast.slice'

import { onMessage } from 'firebase/messaging'
import { messaging } from './firebase-init'
import { SyncIndicator } from './components/sync-indicator/sync-indicator'
import { generateStatusesForPage } from './pages/orders-new-realization/orders.helpers'

const App = () => {
  const dispatch = useDispatch()
  const user = useSelector(userSelectors.getUser)
  const token =
    useSelector((state: RootState) => state.auth.auth.accessToken) ||
    getItemJSON<AuthType>('auth')?.accessToken ||
    ''
  const ordersLoading = useSelector(ordersSelectors.loading)
  const ordersFromList = useSelector(ordersFromListSelectors.orders)

  useEffect(() => {
    if (!user || !token) return
    else if (ordersLoading || ordersFromList) {
      dispatch(ordersApi.getTotalCounts())
    }
  }, [ordersLoading, ordersFromList])

  useEffect(() => {
    if (token && !user) dispatch(userApi.getUserProfile(null))
  }, [user, token, dispatch])

  onMessage(messaging, (payload: any) => {
    const { data } = payload

    dispatch(
      toastActions.showToast({
        customToast: {
          title: data.title,
          content: data.message,
        },
        key: '',
        type: 'success',
      })
    )
    const url = window.location.pathname

    if (url.includes('o')) {
      if (!data.subType) return

      if (
        url.includes('pending') &&
        generateStatusesForPage('PENDING').includes(data.subType)
      ) {
        dispatch(newOrdersApi.syncOrderListAfterPush('PENDING'))
      }

      if (
        url.includes('accepted') &&
        generateStatusesForPage('ACCEPTED').includes(data.subType)
      ) {
        dispatch(newOrdersApi.syncOrderListAfterPush('ACCEPTED'))
      }

      if (
        url.includes('today') &&
        generateStatusesForPage('TODAY').includes(data.subType)
      ) {
        dispatch(newOrdersApi.syncOrderListAfterPush('TODAY'))
      }

      if (
        url.includes('dismissed') &&
        generateStatusesForPage('DISMISSED').includes(data.subType)
      ) {
        dispatch(newOrdersApi.syncOrderListAfterPush('DISMISSED'))
      }

      if (
        url.includes('history') &&
        generateStatusesForPage('HISTORY').includes(data.subType)
      ) {
        dispatch(newOrdersApi.syncOrderListAfterPush('HISTORY'))
      }
    }
  })

  return (
    <Col className="vw-100 vh-100">
      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
          <Router />
        </Suspense>
      </BrowserRouter>
      <ToastMessage />
      <SyncIndicator />
      {!window.location.pathname.includes('login') && <Notifications />}
    </Col>
  )
}

export default App
