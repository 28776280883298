import ReactDOM from 'react-dom'
import React, {
  FC,
  MouseEvent,
  MutableRefObject,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react'
import { Text } from '../..'
import { useOutsideClick } from '../../../helpers/hooks'
import s from './styles.module.scss'
import cn from 'classnames'
import { ArrowUpIcon } from '../../../assets/icons'
import { SelectItem } from './types'
import { CustomListType } from '../../../pages/orders/components/search-dropdown/custom-list/custom-list'

export type DropdownProps = {
  zIdex?: number
  defaultOption?: SelectItem
  placeholder?: string
  listContainerNode?: HTMLElement | null
  customLabel?: JSX.Element
  customList?: FC<CustomListType>
  scrollContainerNode?: MutableRefObject<HTMLDivElement | null>
  onSelect: (item: SelectItem) => void
  options: {
    label: string
    value: string
  }[]
  className?: string
  labelClassName?: string
  isPlaceholder?: boolean
  listClassName?: string
  customListWidth?: number
  dropClassName?: string
}

const appRoot = document.getElementById('root') as HTMLElement

export const Dropdown = ({
  options,
  defaultOption,
  listContainerNode,
  className,
  listClassName,
  isPlaceholder,
  scrollContainerNode,
  customLabel,
  labelClassName,
  dropClassName,
  onSelect,
  customList: CustomList,
  customListWidth,
  zIdex = 0,
  placeholder = 'no-option',
}: DropdownProps) => {
  const [option, setOption] = useState(defaultOption)
  const ref = useRef(null) as any as MutableRefObject<HTMLDivElement>
  const dropRef = useRef(null) as any as MutableRefObject<HTMLDivElement>
  const listRef = useRef(null) as any as MutableRefObject<HTMLDivElement>
  const dropWrapperRef = useRef(null) as any as MutableRefObject<HTMLDivElement>
  const [isOpen, setIsOpen] = useState(false)

  const listWidth = customListWidth || dropRef?.current?.offsetWidth

  const [position, setPosition] = useState({
    left:
      dropRef?.current?.offsetLeft + dropRef?.current?.offsetWidth - listWidth,
    top: dropRef?.current?.offsetTop,
  })
  const onClose = (e: MouseEvent) => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  useLayoutEffect(() => {
    updateScrollPosition()
  }, [])

  useEffect(() => setOption(defaultOption), [defaultOption])

  const updateScrollPosition = useCallback(() => {
    setPosition({
      left:
        dropRef?.current?.offsetLeft +
        dropRef?.current?.offsetWidth -
        listWidth -
        (scrollContainerNode?.current?.scrollLeft || 0),
      top:
        dropRef?.current?.offsetTop -
        (scrollContainerNode?.current?.scrollTop || 0),
    })
  }, [listWidth, scrollContainerNode])

  useLayoutEffect(() => {
    let currentScrollNode: HTMLDivElement | null = null
    if (scrollContainerNode?.current && isOpen) {
      scrollContainerNode.current.addEventListener(
        'scroll',
        updateScrollPosition
      )
      currentScrollNode = scrollContainerNode.current
    }
    return () => {
      if (currentScrollNode) {
        currentScrollNode.removeEventListener('scroll', updateScrollPosition)
      }
    }
  }, [isOpen, scrollContainerNode, updateScrollPosition])

  useOutsideClick([listRef, dropRef], onClose)

  return (
    <div
      ref={dropWrapperRef}
      className={cn([s['wrapper'], className, { [s.active]: isOpen }])}
    >
      <div
        style={{ zIndex: isOpen ? zIdex + 4 : zIdex + 2 }}
        className={cn([dropClassName, s['drop-button']])}
        role="button"
        ref={dropRef}
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen((flag) => !flag)
          if (!isOpen) {
            setPosition({
              left:
                dropRef?.current?.offsetLeft +
                dropRef?.current?.offsetWidth -
                listWidth -
                (scrollContainerNode?.current?.scrollLeft || 0),
              top:
                dropRef?.current?.offsetTop -
                (scrollContainerNode?.current?.scrollTop || 0),
            })
          }
        }}
      >
        {customLabel ? (
          customLabel
        ) : (
          <Text
            className={cn([labelClassName, s.label])}
            size="14"
            color="point"
          >
            {isPlaceholder ? placeholder : option?.label || 'no-option'}
          </Text>
        )}

        <ArrowUpIcon className={cn([s.arrow, { [s['arrow-flip']]: isOpen }])} />
      </div>
      {ReactDOM.createPortal(
        <div
          style={
            position && {
              zIndex: zIdex + 3,
              position: 'absolute',
              top: position.top,
              left: position.left,
              width: listWidth + 'px',
            }
          }
          ref={listRef}
          className={cn([listClassName, s.option_list, { [s.open]: isOpen }])}
        >
          {CustomList ? (
            <CustomList
              options={options}
              onClick={(e, label, value) => {
                e.stopPropagation()
                onSelect({ label, value })
                setOption({ label, value })
                setIsOpen(false)
              }}
            />
          ) : (
            options.map(({ label, value }, ind) => (
              <div
                ref={ref}
                role="button"
                key={value}
                data-value={value}
                onClick={(e) => {
                  e.stopPropagation()
                  onSelect({ label, value })
                  setOption({ label, value })
                  setIsOpen(false)
                }}
                className={s['option-wrapper']}
              >
                <Text size="14" color="inactive-major">
                  {label}
                </Text>
              </div>
            ))
          )}
        </div>,
        listContainerNode || dropWrapperRef.current || appRoot
      )}
    </div>
  )
}
