import { staffSelectors } from './../pages/staff/staff.selectors'
import { IStaffFilters, staffActions } from './../pages/staff/staff.slice'
import { ordersActions, IOrderFilters } from './../pages/orders/orders.slice'
import { useSelector, useDispatch } from 'react-redux'
import { RouteBook } from './../components/menu/constants'
import {
  NavigateOptions,
  useLocation,
  Location,
  useNavigate,
} from 'react-router'
import React, {
  MouseEvent,
  MutableRefObject,
  Ref,
  useEffect,
  useState,
} from 'react'
import { OrderPageStatuses } from '../pages/orders/constants'
import { ordersSelectors } from '../pages/orders/orders.selectors'
import { clientsSelector } from '../pages/clients/clients.selectors'
import { clientsActions, IClientsFilters } from '../pages/clients/clients.slice'

export const useRouteNavigate = () => {
  const navigate = useNavigate()
  return (to: keyof typeof RouteBook, options?: NavigateOptions) =>
    navigate(RouteBook[to], options)
}

export const useOutsideClick = (
  refs: MutableRefObject<HTMLDivElement>[],
  onClick: (e: MouseEvent) => void
) => {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!refs.some((ref) => ref.current.contains(event.target as Element))) {
        onClick(event)
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside as any)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside as any)
    }
  }, [refs])
}

type LocationWithState<T> = Location & {
  state: T | null
}
export const useLocationWithState = <T>() => {
  const location = useLocation() as any as LocationWithState<T>
  return location
}

type TabProps = {
  defaultTab: string
}

const useTabs = ({ defaultTab }: TabProps) => {
  const [active, setTab] = useState(defaultTab)
  return { active, setTab }
}

export const useQuery = () => {
  const { search } = useLocation()

  return React.useMemo(() => new URLSearchParams(search), [search])
}

export const useOrderPage = (): keyof typeof OrderPageStatuses => {
  const pathname = useLocation().pathname
  if (pathname.includes('pending')) return OrderPageStatuses.PENDING
  if (pathname.includes('accepted')) return OrderPageStatuses.ACCEPTED
  if (pathname.includes('today')) return OrderPageStatuses.TODAY
  if (pathname.includes('dismissed')) return OrderPageStatuses.DISMISSED
  else return OrderPageStatuses.HISTORY
}
export const useOrdersFilters = () => {
  const dispatch = useDispatch()
  const filters = useSelector(ordersSelectors.filters)
  const setFilters = (filters: IOrderFilters) =>
    dispatch(ordersActions.setFilters(filters))
  const clearFilters = () => dispatch(ordersActions.clearFilters())
  return { filters, setFilters, clearFilters }
}
export const useStaffFilters = () => {
  const dispatch = useDispatch()
  const filters = useSelector(staffSelectors.filters)
  const setFilters = (filters: IStaffFilters) =>
    dispatch(staffActions.setFilters(filters))
  const clearFilters = () => dispatch(staffActions.clearFilters())
  return { filters, setFilters, clearFilters }
}
export const useClientsFilters = () => {
  const dispatch = useDispatch()
  const filters = useSelector(clientsSelector.filters)
  const setFilters = (filters: IClientsFilters) =>
    dispatch(clientsActions.setFilters(filters))
  const clearFilters = () => dispatch(clientsActions.clearFilters())
  return { filters, setFilters, clearFilters }
}
