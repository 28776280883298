import moment from 'moment'
import { FC, MouseEventHandler, ReactNode, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ECurrencies } from '../../../../app-types/_enums/currencies'
import { CheckIcon, CrossIcon, PlayIcon } from '../../../../assets/icons'
import { Timer } from '../../../../pages/orders/components/order-card/components/timer/timer'
import { OrderPageStatuses } from '../../../../pages/orders/constants'
import { ordersApi } from '../../../../pages/orders/orders.slice'
import { Order } from '../../../../pages/orders/types'
import { staffSelectors } from '../../../../pages/staff/staff.selectors'
import { OrderStatuses } from '../../../../services/requests/orders/constants'
import { userSelectors } from '../../../../store/user/user.selectors'
import DropDown from '../../../dropdown/dropdown'
import Map from '../../../map'
import { modalActions } from '../../modal.actions'

import '../popups.scss'

interface Props {
  data: Order
  pageStatus?: keyof typeof OrderPageStatuses
}
export const OrderFullscreenModal: FC<Props> = ({
  data,
  pageStatus = 'PENDING',
}) => {
  const {
    id,
    createDate,
    updateDate,
    dogs,
    user,
    time,
    startDate,
    service,
    price,
    groupId,
    typeService,
    someone_at_home,
    clientNote,
    address,
  } = data
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const { workers } = useSelector(staffSelectors.workers)
  const currency = useSelector(userSelectors.getUserCurrency)

  const handleWalkerSelect = (optionId: string) => {
    dispatch(ordersApi.updateWorker({ orderId: data._id, workerId: optionId }))
  }

  const onAccept: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.ACCEPTED,
        pageStatus: pageStatus,
        orderId: data._id,
      })
    )
  }

  const onDecline = (reason?: string) => {
    dispatch(
      ordersApi.updateStatus({
        updatedStatus:
          data.status === OrderStatuses.PENDING
            ? OrderStatuses.DECLINE_BY_SERVICE
            : OrderStatuses.CANCEL_BY_SERVICE,
        pageStatus: pageStatus,
        orderId: data._id,
        cancelReason: reason,
      })
    )
  }

  const onStart: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.LIVE,
        pageStatus,
        orderId: data._id,
      })
    )
  }

  const onFinish: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.FINISHED,
        pageStatus,
        orderId: data._id,
      })
    )
  }

  const renderControls = (): ReactNode => {
    switch (data.status) {
      case 'PENDING':
        return (
          <>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_accept"
              onClick={onAccept}
            >
              <CheckIcon height="12px" />
            </button>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_decline"
              onClick={() => {
                dispatch(
                  modalActions.openModal({
                    backdropColor: 'dark',
                    type: 'confirmation-modal',
                    details: {
                      data: {
                        text: data?.dogs[0]?.name + '/' + data.user.fulName,
                        handler: (reason?: string) => onDecline(reason),
                        type:
                          data.status === OrderStatuses.PENDING
                            ? 'decline'
                            : 'cancel',
                      },
                    },
                  })
                )
              }}
            >
              <CrossIcon width="12px" height="12px" fill="#fe4444" />
            </button>
          </>
        )
      case 'ACCEPTED':
        return (
          <>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_accept"
              onClick={onStart}
            >
              <PlayIcon height="12px" />
            </button>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_decline"
              onClick={() => {
                dispatch(
                  modalActions.openModal({
                    backdropColor: 'dark',
                    type: 'confirmation-modal',
                    details: {
                      data: {
                        text: data?.dogs[0]?.name + '/' + data.user.fulName,
                        handler: (reason?: string) => onDecline(reason),
                        type:
                          data.status === OrderStatuses.PENDING
                            ? 'decline'
                            : 'cancel',
                      },
                    },
                  })
                )
              }}
            >
              <CrossIcon width="12px" height="12px" fill="#fe4444" />
            </button>
          </>
        )
      case 'LIVE':
        return (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'auto auto auto',
              gap: '4px',
              alignItems: 'center',
              padding: '0 0 0 10px',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                marginRight: '4px',
              }}
            >
              <Timer time={new Date(data.liveDate).getTime()} />
            </div>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_stop"
              onClick={onFinish}
            >
              <div></div>
            </button>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <div ref={ref} className={'order-popup-big'}>
      <div className="order-popup-big-container">
        <div className="order-popup-big__header">
          <div className="order-popup-big-header__user-info">
            <div className="order-popup-big-header__avatar">
              {dogs && dogs.length >= 1 && dogs[0].url ? (
                <img src={dogs[0].url} width="36px" height="36px" />
              ) : (
                <span>{dogs[0].name.substring(1, 0).toUpperCase()}</span>
              )}
            </div>
            <div className="order-popup-big-header__dogs-and-owner">
              <p className="order-popup-big-header__text text-14-400">
                {dogs && dogs.length >= 1
                  ? dogs.map((i: any) => i.name).join(', ')
                  : '-'}
              </p>
              <p className="order-popup-big-header__text text-12-500">
                {user?.fulName || '-'}
              </p>
            </div>
          </div>
          <div className="order-popup-big-header__time">
            <p className="order-popup-body-header__text text-14-400">
              {time
                .split('-')
                .map((i: string) => i + ':00')
                .join(' - ') || '-'}
            </p>
          </div>
          <div className="order-popup-big-header__date">
            <p className="order-popup-body-header__text text-12-500">
              {startDate ? moment(startDate).format('D MMMM, ddd') : '-'}
            </p>
          </div>
          <div className="order-popup-big-header__duration">
            <p className="order-popup-big-header__text text-14-400">
              {service?.service?.title || '-'}
            </p>
          </div>
          <div className="order-popup-big-header__select">
            <DropDown
              options={
                workers
                  ? workers.map((i) => ({
                      value: i._id,
                      title: `${i.firstName} ${i.lastName}`,
                    }))
                  : []
              }
              defaultOption={{
                title: data.worker?.fulName || '-',
                value: data.worker?._id || '',
              }}
              onSelect={handleWalkerSelect}
              disabled={
                data.status !== 'PENDING' && data.status !== 'ACCEPTED'
                  ? true
                  : false
              }
            />
          </div>
          <div className="order-popup-big-header__price">
            <p className="order-popup-big-header__text text-14-600">
              {price?.priceTotal
                ? (currency ? ECurrencies[currency] : ECurrencies.ILS) +
                  price.priceTotal
                : '-'}
            </p>
          </div>
          <div className="order-popup-big-header__buttons">
            {renderControls()}
          </div>
          <div>
            <button
              className="order-popup-big-header__button order-popup-big-header__button_close"
              onClick={() => {
                dispatch(modalActions.closeModal())
              }}
            >
              <CrossIcon width="12px" height="12px" />
            </button>
          </div>
        </div>
        <hr />
        <div className="order-popup-big-body">
          <div className="order-popup-big-body__column">
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">ID:</p>
              <p className="order-popup-big-body__cell text-12-500">
                {id || '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                Creation Date:
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {createDate
                  ? moment(createDate).format('MM.DD.YYYY, HH:mm')
                  : '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                Last Update:
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {updateDate
                  ? moment(updateDate).format('MM.DD.YYYY, HH:mm')
                  : '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">Dogs:</p>
              <p className="order-popup-big-body__cell text-12-500">
                {dogs && dogs.length >= 1
                  ? dogs.map((i: any) => i.name).join(', ')
                  : '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                Discount:
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {price?.discount?.coins?.money || '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                Recuring ID:
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {groupId || '-'}
              </p>
            </div>
          </div>
          <div className="order-popup-big-body__column">
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">Key:</p>
              <p className="order-popup-big-body__cell text-12-500">
                {someone_at_home?.person
                  ? 'Someone will be home'
                  : someone_at_home.note}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                Additional Service:
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {price?.additionService?.length >= 1
                  ? price.additionService.map((i) => i.title).join(', ')
                  : '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">Note:</p>
              <p className="order-popup-big-body__cell text-12-500">
                {clientNote?.note || '-'}
              </p>
            </div>
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">Credit:</p>
              <p className="order-popup-big-body__cell text-12-500">
                {price?.discount?.coins?.value || '-'}
              </p>
            </div>
          </div>
          <div className="order-popup-big-body__column">
            <div className="order-popup-big-body__row">
              <p className="order-popup-big-body__cell text-14-600">
                {'Location: '}{' '}
              </p>
              <p className="order-popup-big-body__cell text-12-500">
                {address?.address + ', apt ' + address?.apartment}
              </p>
              {/* <button
                className="order-popup-big-header__button order-popup-big-header__button_edit"
                onClick={(e) => {}}
              >
                <EditIcon />
              </button> */}
            </div>
            <div className="map-wrapper">
              {address?.lat && address?.long ? (
                <Map
                  mapID={id}
                  height={175}
                  coords={[[address.long, address.lat]]}
                  markers={[
                    {
                      coord: [address.long, address.lat],
                      dogId: dogs[0]._id,
                      typeActivity: typeService,
                    },
                  ]}
                />
              ) : (
                <p className="map-wrapper__no-info text-12-500">
                  {'No information about the coordinates of the order :('}
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
