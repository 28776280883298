import { t } from 'i18next'
import s from './styles.module.scss'
import cn from 'classnames'
import { ButtonHTMLAttributes } from 'react'

type Props = {
  children: string
  type?: 'button' | 'submit' | 'reset'
  form?: string
  className?: string
  onClick?: () => void
  variant?: 'alt'
}

export const Button = ({
  children,
  className,
  onClick,
  form,
  type = 'button',
  variant = 'alt',
}: Props) => {
  return (
    <button
      form={form}
      onClick={onClick}
      type={type}
      className={cn([
        s.btn,
        { [className!]: className, [s.alt]: variant == 'alt' },
      ])}
    >
      {t(children)}
    </button>
  )
}
