import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastActions } from '../../components/toast/toast.slice'
import { getCountries } from './countries.requests'

import { IResponseError } from '../../app-types/response-types/response-error'
import { IGetCountriesReq, IGetCountriesRes } from './countries.types'

export const getCountriesThunk = createAsyncThunk<
  IGetCountriesRes,
  IGetCountriesReq,
  { rejectValue: IResponseError }
>('countries', async (args, { dispatch, rejectWithValue }) => {
  try {
    const data = await getCountries(args)
    return data
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'countries-status-get',
        type: 'failed',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})
