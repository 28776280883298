import { Order } from '../../../pages/orders/types'
import { get, put } from '../../api'
import { WORKER_API } from '../../api/api.config'
import { GetWorkersReq, WorkersRes } from './types'

export const getWorkers = async ({ partnerId }: GetWorkersReq) => {
  const url = `/worker/${partnerId}/partner-workers`

  const { data } = await get<WorkersRes>(url, {
    baseURL: WORKER_API,
  })

  return data
}
