import { get } from '../../services/api'
import { WORKER_API } from '../../services/api/api.config'
import { IGetStatesReq, IGetStatesRes } from './states.types'

export const getStates = async ({ params }: IGetStatesReq) => {
  const url = '/worker/states'

  try {
    const { data } = await get<IGetStatesRes>(url, {
      baseURL: WORKER_API,
      params,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
