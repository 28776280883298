import moment from 'moment'
import { generateStatusesForPage } from '../../../pages/orders-new-realization/orders.helpers'
import { OrderPageStatuses } from '../../../pages/orders/constants'
import { IGetOrdersListReq } from './orders-new.types'

export const generateParamsForGetOrdersList = (
  reqParams: IGetOrdersListReq
) => {
  const {
    status,
    pageNumber,
    pageSize,
    dogId,
    endDate,
    startDate,
    orderId,
    clientId,
    workerId,
    serviceTypeId,
    sort = 'date',
    forSort = 1,
    isOnlyLive,
  } = reqParams

  let params = {}
  if (status === OrderPageStatuses.PENDING) {
    params = {
      status: generateStatusesForPage(status),
    }
  }
  if (status === OrderPageStatuses.ACCEPTED) {
    params = {
      status: isOnlyLive ? ['LIVE'] : generateStatusesForPage(status),
    }
  }
  if (status === OrderPageStatuses.DISMISSED) {
    params = {
      status: generateStatusesForPage(status),
    }
  }
  if (status === OrderPageStatuses.HISTORY) {
    params = {
      status: generateStatusesForPage(status),
    }
  }
  if (status === OrderPageStatuses.TODAY) {
    const today = moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')
    const tomorrow = moment()
      .startOf('day')
      .add(1, 'day')
      .format('yyyy-MM-DDTHH:mm:ss')

    params = {
      startDate: today,
      endDate: tomorrow,
      status: isOnlyLive ? ['LIVE'] : generateStatusesForPage(status),
    }
  }

  if ((startDate || endDate) && status !== 'TODAY') {
    params = {
      ...params,
      pageSize,
      pageNumber,
      sort,
      forSort,
      startDate: moment(startDate).format('yyyy-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).add(1, 'day').format('yyyy-MM-DDTHH:mm:ss'),
    }
  }

  params = {
    ...params,
    pageSize,
    pageNumber,
    sort,
    forSort,
    clientId,
    dogId,
    workerId,
    orderId,
    serviceTypeId,
  }

  return params
}
