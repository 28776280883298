export const dateToFormat = (date: Date, format: string) => {
  let formattedString = format
  if (format.includes('dd')) {
    let day = date.getDay().toString()
    day = day.length === 1 ? `0${day}` : day
    formattedString = formattedString.replace('dd', day)
  }
  if (format.includes('mm')) {
    let month = date.getDay().toString()
    month = month.length === 1 ? `0${month}` : month
    formattedString = formattedString.replace('mm', month)
  }
  if (format.includes('yyyy')) {
    formattedString = formattedString.replace(
      'yyyy',
      date.getFullYear().toString()
    )
  }
  if (format.includes('hh')) {
    let hours = date.getHours().toString()
    hours = hours.length === 1 ? `0${hours}` : hours
    formattedString = formattedString.replace('hh', hours)
  }
  if (format.includes('mins')) {
    let mins = date.getMinutes().toString()
    mins = mins.length === 1 ? `0${mins}` : mins
    formattedString = formattedString.replace('mins', mins)
  }
  if (format.includes('ss')) {
    let secs = date.getSeconds().toString()
    secs = secs.length === 1 ? `0${secs}` : secs
    formattedString = formattedString.replace('ss', secs)
  }
  return formattedString
}
