import cn from 'classnames'
import { t } from 'i18next'
import { FC, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'

// COmponents
import { CrossIcon, FailedIcon, SuccessIcon } from '../../../../assets/icons'
import { AddressInput } from '../../../inputs/address-input/address-input'
import { SuperSelect } from '../../../forms/super-select/SuperSelect'
import Input from '../../../forms/input/Input'

// Utils
import { useAppDispatch } from '../../../../helpers'
import { isFieldError } from '../../../../helpers/isFieldError'
import { useCitiesOptions } from '../../../../hooks/useCitiesOptions'
import { useCountryOptions } from '../../../../hooks/useCountryOptions'
import { useStatesOptions } from '../../../../hooks/useStatesOptions'

// State management
import { clientsApi } from '../../../../pages/clients/clients.slice'
import { modalActions } from '../../modal.actions'

// Types
import { IClient } from '../../../../pages/clients/clients.types'
import { IUpdateClientAddressReq } from '../../../../services/requests/clients/types'
import { ISuperSelectOption } from '../../../forms/super-select/utils'

// Styles
import s from './styles.module.scss'

interface IProps {
  client: IClient
}

type IUpdateClientAddressReqBody = IUpdateClientAddressReq['body']['address']

interface IFormValues
  extends Omit<IUpdateClientAddressReqBody, 'country' | 'city' | 'state'> {
  country: ISuperSelectOption | null
  city: ISuperSelectOption | null
  state: ISuperSelectOption | null
}

export const ChangeClientAddressModal: FC<IProps> = ({ client }) => {
  const dispatch = useAppDispatch()

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    getValues,
    formState: { errors, isDirty },
  } = useForm<IFormValues>({
    defaultValues: {
      country: client.address?.country
        ? {
            label: client.address.country.name_localizations?.en,
            value: client.address.country._id,
          }
        : null,
      city: client.address?.city
        ? {
            label: client.address.city.name_localizations?.en,
            value: client.address.city._id,
          }
        : null,
      state: client.address?.state
        ? {
            label: client.address.state.name_localizations?.en,
            value: client.address.state._id,
          }
        : null,
      apartment: client.address?.apartment || '',
      floor: client.address?.floor || '',
      building_code: client.address?.building_code || '',
      entrance: client.address?.entrance || '',
      zip: client.address?.zip || '',
      zip_code: client.address?.zip_code || '',
      address: client.address?.address || '',
      lat: client.address?.lat || 0,
      long: client.address?.long || 0,
    },
  })

  const [selectedCountry] = watch(['country'])

  const [isFetching, setIsFetching] = useState(false)
  const [isAddingSucces, setIsChangingSuccess] = useState<boolean | null>(null)

  const {
    onCountrySearch,
    countries,
    isLoading: isCountriesLoading,
    isError: isCountriesRequestError,
  } = useCountryOptions()

  const {
    onStateSearch,
    states,
    isLoading: isStatesLoading,
    isError: isStatesRequestError,
  } = useStatesOptions(selectedCountry?.value as string)

  const {
    onCitySearch,
    cities,
    isLoading: isCitiesLoading,
    isError: isCitiesRequestError,
  } = useCitiesOptions(selectedCountry?.value as string)

  const renderContent = () => {
    switch (isAddingSucces) {
      case true:
        return (
          <div className={s['result-container']}>
            <SuccessIcon />
            <p className={s['result-container__title']}>
              {t('modals.client-address.form.success-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('modals.client-address.form.success-text')}
            </p>
          </div>
        )
      case false:
        return (
          <div className={s['result-container']}>
            <FailedIcon />
            <p className={s['result-container__title']}>
              {t('modals.client-address.form.failed-title')}
            </p>
            <p className={s['result-container__text']}>
              {t('modals.client-address.form.failed-text')}
            </p>
          </div>
        )

      default:
        return (
          <>
            <div>
              <p className={s['modal__title']}>
                {t('modals.client-address.header.title')}
              </p>
              <p className={s['modal__subtitle']}>
                {t('modals.client-address.header.info-start')}
                <span className={s['modal__subtitle_name']}>
                  {client?.fulName + "'s"}
                </span>
                {t('modals.client-address.header.info-end')}
              </p>
            </div>
            <form className={s['form']} onSubmit={handleSubmit(onSubmit)}>
              <div className={s['body-container']}>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="country"
                    rules={{
                      required: t('validation-errors.required').toString(),
                    }}
                    render={({ field }) => (
                      <SuperSelect
                        required
                        closeMenuOnSelect
                        options={countries}
                        label={t('modals.client-address.form.country-label')}
                        placeholder={t(
                          'modals.client-address.form.country-placeholder'
                        )}
                        isLoading={isCountriesLoading}
                        onInputChange={(value) => onCountrySearch(value)}
                        field={field}
                        isDisabled={Boolean(isCountriesRequestError)}
                        error={
                          isFieldError(errors.country)
                            ? errors.country?.message
                            : undefined
                        }
                        onChange={() => {
                          setValue('city', null)
                          setValue('state', null)
                          setValue('address', '')
                          setValue('lat', 0)
                          setValue('long', 0)
                        }}
                      />
                    )}
                  />
                </div>

                {selectedCountry?.value === '60f6bdd0cb99b4395cc43e9a' && (
                  <div className={s['input-container']}>
                    <Controller
                      control={control}
                      name="state"
                      rules={{
                        required:
                          selectedCountry.value === '60f6bdd0cb99b4395cc43e9a'
                            ? t('validation-errors.required').toString()
                            : false,
                      }}
                      render={({ field }) => (
                        <SuperSelect
                          required
                          options={states}
                          label={t('modals.client-address.form.state-label')}
                          placeholder={t(
                            'modals.client-address.form.state-placeholder'
                          )}
                          isLoading={isStatesLoading}
                          closeMenuOnSelect
                          onInputChange={onStateSearch}
                          field={field}
                          error={
                            isFieldError(errors.state)
                              ? errors.state?.message
                              : undefined
                          }
                          isDisabled={
                            Boolean(isStatesRequestError) ||
                            !Boolean(selectedCountry)
                          }
                        />
                      )}
                    />
                  </div>
                )}

                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="city"
                    rules={{
                      required: t('validation-errors.required').toString(),
                    }}
                    render={({ field }) => (
                      <SuperSelect
                        required
                        options={cities}
                        label={t('modals.client-address.form.city-label')}
                        placeholder={t(
                          'modals.client-address.form.city-placeholder'
                        )}
                        isLoading={isCitiesLoading}
                        closeMenuOnSelect
                        onInputChange={onCitySearch}
                        field={field}
                        error={
                          isFieldError(errors.city)
                            ? errors.city?.message
                            : undefined
                        }
                        isDisabled={
                          Boolean(isCitiesRequestError) ||
                          !Boolean(selectedCountry)
                        }
                      />
                    )}
                  />
                </div>

                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="address"
                    rules={{
                      required: t('validation-errors.required').toString(),
                      validate: () => {
                        if (!getValues('lat')) {
                          return t('validation-errors.no-location').toString()
                        }

                        return true
                      },
                    }}
                    render={({ field }) => (
                      <AddressInput
                        required
                        label={t('modals.client-address.form.address-label')}
                        placeholder={t(
                          'modals.client-address.form.address-placeholder'
                        )}
                        field={field}
                        error={errors.address?.message}
                        onChange={(res) => {
                          if (setValue) {
                            setValue('lat', res.lat)
                            setValue('long', res.long)
                          }
                        }}
                      />
                    )}
                  />
                </div>

                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="apartment"
                    rules={{
                      required: t('validation-errors.required').toString(),
                    }}
                    render={({ field }) => (
                      <Input
                        required
                        label={t('modals.client-address.form.apartment-label')}
                        placeholder={t(
                          'modals.client-address.form.apartment-placeholder'
                        )}
                        field={field}
                        error={errors.apartment?.message}
                      />
                    )}
                  />
                </div>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="floor"
                    rules={{
                      required: t('validation-errors.required').toString(),
                    }}
                    render={({ field }) => (
                      <Input
                        required
                        label={t('modals.client-address.form.floor-label')}
                        placeholder={t(
                          'modals.client-address.form.floor-placeholder'
                        )}
                        field={field}
                        error={errors.floor?.message}
                      />
                    )}
                  />
                </div>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="building_code"
                    rules={{
                      required: t('validation-errors.required').toString(),
                    }}
                    render={({ field }) => (
                      <Input
                        required
                        label={t(
                          'modals.client-address.form.building-code-label'
                        )}
                        placeholder={t(
                          'modals.client-address.form.building-code-placeholder'
                        )}
                        field={field}
                        error={errors.building_code?.message}
                      />
                    )}
                  />
                </div>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="entrance"
                    render={({ field }) => (
                      <Input
                        label={t('modals.client-address.form.entrance-label')}
                        placeholder={t(
                          'modals.client-address.form.entrance-placeholder'
                        )}
                        field={field}
                      />
                    )}
                  />
                </div>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="zip"
                    render={({ field }) => (
                      <Input
                        label={t('modals.client-address.form.zip-label')}
                        placeholder={t(
                          'modals.client-address.form.zip-placeholder'
                        )}
                        field={field}
                      />
                    )}
                  />
                </div>
                <div className={s['input-container']}>
                  <Controller
                    control={control}
                    name="zip_code"
                    render={({ field }) => (
                      <Input
                        label={t('modals.client-address.form.zip-code-label')}
                        placeholder={t(
                          'modals.client-address.form.zip-code-placeholder'
                        )}
                        field={field}
                      />
                    )}
                  />
                </div>
              </div>
              <div className={s['btn-container']}>
                <button
                  className={s['confirm-btn']}
                  type="submit"
                  disabled={isFetching || !isDirty}
                >
                  {t('modals.client-address.form.confirm')}
                </button>
              </div>
            </form>
          </>
        )
    }
  }

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const address: IUpdateClientAddressReqBody = {
      ...data,
      city: data.city?.value as string,
      country: data.country?.value as string,
      state: data.state?.value as string,
    }

    if (!data.state) {
      delete address.state
    }

    if (!data.zip) {
      delete address.zip
    }

    if (!data.zip_code) {
      delete address.zip_code
    }

    if (!data.entrance) {
      delete address.entrance
    }

    setIsFetching(true)

    const result = await dispatch(
      clientsApi.changeClientAddress({ id: client._id, body: { address } })
    )
    if (!result.payload?.status) {
      setIsFetching(false)
      setIsChangingSuccess(true)
      setTimeout(() => {
        dispatch(modalActions.closeModal())
      }, 2000)
    } else {
      setIsFetching(false)
      setIsChangingSuccess(false)
    }
  }

  return (
    <div
      className={cn([
        s['modal-container'],
        {
          [s['modal-container_small']]:
            isAddingSucces === true || isAddingSucces === false,
        },
      ])}
    >
      <button
        className={s['modal__close-btn']}
        onClick={() => dispatch(modalActions.closeModal())}
      >
        <CrossIcon width="10px" height="10px" />
      </button>
      {renderContent()}
    </div>
  )
}
