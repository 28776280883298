import React, {
  FC,
  MouseEventHandler,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  CheckIcon,
  CrossIcon,
  EyeIcon,
  PlayIcon,
} from '../../../../assets/icons'
import { ordersApi } from '../../../../pages/orders/orders.slice'
import { OrderStatuses } from '../../../../services/requests/orders/constants'
import { PositionType } from './order-popup.types'
import { modalActions } from '../../modal.actions'
import { calculatePopupPosition } from './order-popup.helpers'

import '../popups.scss'
import { Order } from '../../../../pages/orders/types'
import moment from 'moment'
import { OrderPageStatuses } from '../../../../pages/orders/constants'
import { Timer } from '../../../../pages/orders/components/order-card/components/timer/timer'
import { ECurrencies } from '../../../../app-types/_enums/currencies'
import { userSelectors } from '../../../../store/user/user.selectors'

interface Props {
  data: Order
  position: any
  pageStatus?: keyof typeof OrderPageStatuses
}

export const OrderPopup: FC<Props> = ({
  data,
  position,
  pageStatus = 'PENDING',
}) => {
  const {
    id,
    user: { fulName },
    dogs,
    time,
    startDate,
    worker,
    price: { priceTotal },
    service: {
      service: { title: serviceTitle },
    },
  } = data
  const workerFulName = (worker && worker.fulName) || '-'
  const ref = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const [popUpPosition, setPopUpPisition] = useState<PositionType>({
    x: 0,
    y: 0,
  })

  const currency = useSelector(userSelectors.getUserCurrency)

  useEffect(() => {
    const popUpWigth = ref.current?.offsetWidth
    const popUpHeight = ref.current?.offsetHeight
    setPopUpPisition(
      calculatePopupPosition(
        {
          width: popUpWigth || 0,
          height: popUpHeight || 0,
        },
        position
      )
    )
  }, [position])

  const onAccept: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.ACCEPTED,
        pageStatus: pageStatus,
        orderId: data._id,
      })
    )
  }
  const onDecline = (reason?: string) => {
    dispatch(
      ordersApi.updateStatus({
        updatedStatus:
          data.status === OrderStatuses.PENDING
            ? OrderStatuses.DECLINE_BY_SERVICE
            : OrderStatuses.CANCEL_BY_SERVICE,
        pageStatus: pageStatus,
        orderId: data._id,
        cancelReason: reason,
      })
    )
  }

  const onStart: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.LIVE,
        pageStatus,
        orderId: data._id,
      })
    )
  }
  const onFinish: MouseEventHandler<HTMLButtonElement> = (e) => {
    e.stopPropagation()
    dispatch(
      ordersApi.updateStatus({
        updatedStatus: OrderStatuses.FINISHED,
        pageStatus,
        orderId: data._id,
      })
    )
  }

  const renderControls = (): ReactNode => {
    switch (data.status) {
      case 'PENDING':
        return (
          <>
            <button
              className="order-popup-footer__btn order-popup-footer__btn_acorder-popupt"
              onClick={onAccept}
            >
              <CheckIcon height="12px" />
              Accept
            </button>
            <button
              className="order-popup-footer__btn"
              onClick={() => {
                dispatch(
                  modalActions.openModal({
                    backdropColor: 'dark',
                    type: 'confirmation-modal',
                    details: {
                      data: {
                        text: data?.dogs[0]?.name + '/' + data.user.fulName,
                        handler: (reason?: string) => onDecline(reason),
                        type:
                          data.status === OrderStatuses.PENDING
                            ? 'decline'
                            : 'cancel',
                      },
                    },
                  })
                )
              }}
            >
              <CrossIcon width="12px" height="12px" />
              Decline
            </button>
          </>
        )
      case 'ACCEPTED':
        return (
          <>
            <button
              className="order-popup-footer__btn order-popup-footer__btn_acorder-popupt"
              onClick={onStart}
            >
              <PlayIcon height="12px" />
              Start
            </button>
            <button
              className="order-popup-footer__btn"
              onClick={() => {
                dispatch(
                  modalActions.openModal({
                    backdropColor: 'dark',
                    type: 'confirmation-modal',
                    details: {
                      data: {
                        text: data?.dogs[0]?.name + '/' + data.user.fulName,
                        handler: (reason?: string) => onDecline(reason),
                        type:
                          data.status === OrderStatuses.PENDING
                            ? 'decline'
                            : 'cancel',
                      },
                    },
                  })
                )
              }}
            >
              <CrossIcon width="12px" height="12px" />
              Decline
            </button>
          </>
        )
      case 'LIVE':
        return (
          <>
            <div className="order-popup-footer__timer">
              <Timer time={new Date(data.liveDate).getTime()} />
            </div>
            <button className="order-popup-footer__btn" onClick={onFinish}>
              <div className="order-popup-footer__stop"></div>
              Stop
            </button>
          </>
        )
      default:
        return <></>
    }
  }

  return (
    <div
      ref={ref}
      className="order-popup"
      style={{
        top: popUpPosition.y,
        left: popUpPosition.x,
      }}
    >
      <div className="order-popup__header">
        <p>{serviceTitle || '-'}</p>
        <button
          className="order-popup__close-btn"
          onClick={() => dispatch(modalActions.closeModal())}
        >
          <CrossIcon width="12px" height="12px" />
        </button>
      </div>
      <hr />
      <div className="order-popup__body">
        <div className="order-popup-body__cell">
          <p className="order-popup-body-cell__title">Client Name</p>
          <p className="order-popup-body-cell__main-text">
            {dogs ? dogs.map((i: any) => i.name).join(', ') : '-'}
          </p>
          <p className="order-popup-body-cell__other-text">{fulName || '-'}</p>
        </div>
        <div className="order-popup-body__cell">
          <p className="order-popup-body-cell__title">{'Time & Date'}</p>
          <p className="order-popup-body-cell__main-text">
            {time
              .split('-')
              .map((i: string) => i + ':00')
              .join('-') || '-'}
          </p>
          <p className="order-popup-body-cell__other-text">
            {startDate ? moment(startDate).format('DD MMM, YYYY') : '-'}
          </p>
        </div>
        <div className="order-popup-body__cell">
          <p className="order-popup-body-cell__title">Order Id</p>
          <p className="order-popup-body-cell__main-text">{id || '-'}</p>
        </div>
        <div className="order-popup-body__cell">
          <p className="order-popup-body-cell__title">Walker</p>
          <p className="order-popup-body-cell__main-text">
            {workerFulName || '-'}
          </p>
        </div>
        <div className="order-popup-body__cell">
          <p className="order-popup-body-cell__title">Price</p>
          <p className="order-popup-body-cell__main-text">
            {priceTotal
              ? (currency ? ECurrencies[currency] : ECurrencies.ILS) +
                priceTotal
              : '-'}
          </p>
        </div>
      </div>
      <hr />
      <div className="order-popup__footer">
        {renderControls()}
        <button
          className="order-popup-footer__btn"
          onClick={() => {
            dispatch(modalActions.closeModal())
            dispatch(
              modalActions.openModal({
                type: 'order-fullscreen-modal',
                backdropColor: 'dark',
                details: { data: data, pageStatus },
              })
            )
          }}
        >
          <EyeIcon height="12px" />
          See more
        </button>
      </div>
    </div>
  )
}
