import moment from 'moment'

import { IClient, IClientDog } from './../../../pages/clients/clients.types'
import { OrderStatuses } from './../orders/constants'
import { get, put } from '../../api'
import { WORKER_API } from '../../api/api.config'
import {
  ICancelClientSubscriptionReq,
  IAddCoinsByPartnerReq,
  IGetPartnerClientsReq,
  IUpdateClientAddressReq,
} from './types'
import { IClientSubsription } from '../../../app-types/entities-types/clients.types'

export const getPartnerClients = async (params: IGetPartnerClientsReq) => {
  const url = '/worker/partner-clients'
  try {
    const { data } = await get<{ result: IClient[]; totalCount: number }>(url, {
      baseURL: WORKER_API,
      params,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getClientById = async (id: string) => {
  const url = `/worker/client/${id}`
  try {
    const { data } = await get(url, { baseURL: WORKER_API })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getClientOrders = async ({
  id,
  params,
}: {
  id: string
  params?: {
    status: 'ACCEPTED' | 'TODAY' | 'PENDING' | 'HISTORY'
    pageNumber: string | number
    pageSize: string | number
    sort?: 'name' | 'time' | 'service' | 'date' | 'walker' | 'price'
    forSort?: 1 | -1
  }
}) => {
  const url = `/worker/client/${id}/orders`
  let queryParams: any = {
    status: null,
  }

  const today = moment().startOf('day').format('YYYY-MM-DD')

  switch (params?.status) {
    case 'ACCEPTED':
      queryParams.status = [OrderStatuses.ACCEPTED, OrderStatuses.LIVE]
      break

    case 'PENDING':
      queryParams.status = [OrderStatuses.PENDING]
      break

    case 'TODAY':
      queryParams = {
        dateFrom: today,
        dateTo: today,
        status: [
          OrderStatuses.ACCEPTED,
          OrderStatuses.PENDING,
          OrderStatuses.LIVE,
        ],
      }
      break

    case 'HISTORY':
      queryParams.status = [
        OrderStatuses.CANCEL_BY_USER_AFTER_8,
        OrderStatuses.FINISHED,
      ]
      break

    default:
      break
  }

  queryParams = {
    ...params,
    ...queryParams,
  }

  try {
    const { data } = await get(url, {
      baseURL: WORKER_API,
      params: queryParams,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getPartnerClientDogs = async (params: {
  clientId?: string[]
  dogName?: string
  pageNumber?: number
  pageSize?: number
}) => {
  const url = '/worker/partner-dogs'

  try {
    const { data } = await get<{ dogs: IClientDog[]; totalCount: number }>(
      url,
      {
        baseURL: WORKER_API,
        params,
      }
    )

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const addCoinsByPartner = async (body: IAddCoinsByPartnerReq) => {
  const url = '/worker/add-coins-by-partner'
  try {
    const { status } = await put(url, body, { baseURL: WORKER_API })
    return status
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getClientSubscriptions = async (id: string) => {
  const url = '/worker/all-subscription/' + id

  try {
    const { data } = await get<IClientSubsription[]>(url, {
      baseURL: WORKER_API,
    })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const cancelClientSubscription = async (
  body: ICancelClientSubscriptionReq
) => {
  const url = '/worker/cancel-subscription-by-partner'
  try {
    const { data } = await put(url, body, { baseURL: WORKER_API })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const changeClientAddress = async ({
  body,
  id,
}: IUpdateClientAddressReq) => {
  const url = '/user/' + id

  try {
    const { data } = await put<void>(url, body, {
      baseURL: WORKER_API,
    })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
