import { Accordion, Image } from 'react-bootstrap'
import bigLogo from '../../assets/images/BigLogo.png'
import s from './styles.module.scss'

import { FC, useEffect, useState } from 'react'
import { MenuItem } from './components/MenuItem'

import TodaysOrder from '../todays-order'
import { useSelector } from 'react-redux'
import { ordersSelectors } from '../../pages/orders/orders.selectors'
import { useAppDispatch } from '../../helpers'
import { ordersApi } from '../../pages/orders/orders.slice'
import { Button } from '..'
import { authApi } from '../../store/auth/authSlice'
import { createMenu, MenuData } from './constants'

export const Menu: FC = () => {
  const [active, setActive] = useState('-1')
  const [collapsed, setCollapsed] = useState('-1')
  const d = useAppDispatch()
  const todayCounts = useSelector(ordersSelectors.counts)
  const loading = useSelector(ordersSelectors.countsLoading)
  const totalCounts = useSelector(ordersSelectors.totalCounts)
  const totalCountsLoading = useSelector(ordersSelectors.totalCountsLoading)
  const [menu, setMenu] = useState<null | MenuData>(null)

  useEffect(() => {
    if (todayCounts === null) {
      if (loading === null) d(ordersApi.getCounts())
    }
  }, [loading, d, todayCounts])

  useEffect(() => {
    if (totalCounts === null) {
      if (totalCountsLoading === null) d(ordersApi.getTotalCounts())
    } else {
      setMenu(
        createMenu({
          accepted: totalCounts.accepted_count,
          pending: totalCounts.pending_count,
          today: totalCounts.today_count,
        })
      )
    }
  }, [totalCountsLoading, d, totalCounts])

  const onLogout = () => {
    d(authApi.logOut(null))
  }

  return (
    <div className={s.wrapper}>
      <div className="d-flex">
        <Image className={`mx-auto ${s.logo}`} src={bigLogo} />
      </div>
      <Accordion defaultActiveKey={active}>
        {menu &&
          menu.map((item, ind) => (
            <MenuItem
              key={item.title}
              item={item}
              setCollapsed={setCollapsed}
              collapsed={collapsed}
              onClick={setActive}
              isActive={active === ind.toString()}
              eventKey={ind.toString()}
            />
          ))}
      </Accordion>
      <Button onClick={onLogout}>LogOut</Button>
      {todayCounts && (
        <TodaysOrder
          accepted={todayCounts.accepted}
          pending={todayCounts.pending}
          live={todayCounts.live}
          history={todayCounts.history}
          total={todayCounts.total}
        />
      )}
    </div>
  )
}
