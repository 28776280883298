import { createSlice } from '@reduxjs/toolkit'

import { IUserState } from '../../app-types/states-types/user-state.types'
import { clearUserData } from './user.actions'
import { getUserCurrencyThunk, getUserProfileThunk } from './user.thunks'

const initialState: IUserState = {
  loading: false,
  error: null,
  user: null,
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET USER DATA
    builder.addCase(getUserProfileThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUserProfileThunk.fulfilled, (state, { payload }) => {
      state.user = payload
      state.loading = false
    })
    builder.addCase(getUserProfileThunk.rejected, (state, { payload }) => {
      state.error = payload!.errorData.message
      state.loading = false
    })

    // CLEAR USER DATA
    builder.addCase(clearUserData, () => {
      return initialState
    })

    // GET USER CURRENCY
    builder.addCase(getUserCurrencyThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(getUserCurrencyThunk.fulfilled, (state, { payload }) => {
      state.user!.currency = payload.currency
      state.loading = false
    })
    builder.addCase(getUserCurrencyThunk.rejected, (state, { payload }) => {
      state.error = payload!.errorData.message
      state.loading = false
    })
  },
})

export const userReducer = userSlice.reducer

export const userApi = {
  getUserProfile: getUserProfileThunk,
  getUserCurrency: getUserCurrencyThunk,
}
