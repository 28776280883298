import { get } from '../../services/api'
import { WORKER_API } from '../../services/api/api.config'
import { IGetCountriesReq, IGetCountriesRes } from './countries.types'

export const getCountries = async ({ params }: IGetCountriesReq) => {
  const url = '/worker/countries'

  try {
    const { data } = await get<IGetCountriesRes>(url, {
      baseURL: WORKER_API,
      params,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
