import cn from 'classnames'
import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { Form } from 'react-bootstrap'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
  Suggestion,
} from 'use-places-autocomplete'
import useOutsideClick from '../../../hooks/use-outside-click'

import s from './styles.module.scss'
import { t } from 'i18next'
import Text from '../../typography/text/Text'

interface IProps {
  field: ControllerRenderProps<any, any>
  onChange?: (args: { address: string; lat: number; long: number }) => void
  placeholder?: string
  label?: string
  error?: string
  required?: boolean
}

export const AddressInput: FC<IProps> = ({
  label = '',
  error = '',
  placeholder = '',
  required = false,
  field,
  onChange,
}) => {
  const ref = useRef(null)
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(false)
  const [suggestions, setSuggestions] = useState<any>([])
  const [selected, setSelected] = useState('')

  const {
    ready,
    suggestions: { status, data, loading },
    setValue,
  } = usePlacesAutocomplete({
    requestOptions: {},
    debounce: 1200,
  })

  const handleSelect = useCallback(
    ({ description }: Suggestion) =>
      async () => {
        setValue(description, false)

        setIsSuggestionsVisible(false)

        const results = await getGeocode({ address: description })
        const { lat, lng: long } = getLatLng(results[0])
        const address = results[0].formatted_address

        setSelected(results[0].place_id)
        field.onChange(address)
        if (onChange) onChange({ address, lat, long })
      },
    [field, setValue]
  )
  const handleInput = (e: any) => {
    setValue(e.target.value)
    field.onChange(e.target.value)
    !isSuggestionsVisible && setIsSuggestionsVisible(true)
  }

  useEffect(() => {
    if (status === 'OK' && data) {
      setSuggestions(
        data.map((suggestion) => {
          const {
            place_id,
            structured_formatting: { main_text, secondary_text },
          } = suggestion

          return (
            <li
              key={place_id}
              className={cn([
                s['suggestion'],
                { [s['suggestion-selected']]: place_id === selected },
              ])}
              onClick={handleSelect(suggestion)}
            >
              <p className={s['suggestion-main-text']}>{main_text}</p>
              <p className={s['suggestion-secondary-text']}>{secondary_text}</p>
            </li>
          )
        })
      )
    }
  }, [status, data, handleSelect, selected])

  useOutsideClick(ref, () => setIsSuggestionsVisible(false))

  return (
    <Form.Group className={s['search']} ref={ref}>
      <Form.Label className={s['label']}>
        <Text className={s['label-text']} type="c2">
          {label}{' '}
          {required && <span className={s['label-text__asteriks']}>*</span>}
        </Text>
      </Form.Label>
      <div className={s['input-container']}>
        <Form.Control
          className={cn([s['search-input'], { [s['error']]: error }])}
          value={field.value}
          onChange={handleInput}
          disabled={!ready}
          placeholder={placeholder}
          ref={field.ref}
          name={field.name}
          onClick={() => {
            setIsSuggestionsVisible(true)
          }}
        />
        <div className={s['loader']} style={{ opacity: loading ? 1 : 0 }}>
          <div />
          <div />
          <div />
        </div>
      </div>
      {error && (
        <div className={s['error-container']}>
          <Text className={s['error-text']} type="b3" color="secondary-red-100">
            {error}
          </Text>
        </div>
      )}

      {isSuggestionsVisible && (
        <div className={s['search-body']}>
          {status === 'OK' && (
            <ul className={s['suggestions']}>{suggestions}</ul>
          )}
          {status === 'ZERO_RESULTS' && (
            <div className={s['nothing']}>
              <p>{t('staff.member-form.nothing')}</p>
            </div>
          )}

          {!suggestions.length && (
            <div className={s['hint']}>
              <Text type="c2" color="neutral-gray-50">
                Type to search...
              </Text>
            </div>
          )}
        </div>
      )}
    </Form.Group>
  )
}
