import { t } from 'i18next'
import moment from 'moment'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'

import { Switch, TextInput } from '../../..'
import Select, { ISelectOption } from '../../../inputs/select/select'
import { IOrder } from '../../../../app-types/entities-types/order.types'
import { CrossIcon } from '../../../../assets/icons'
import { ordersApi } from '../../../../pages/orders-new-realization/orders.slice'
import { getPartnerClientDogs } from '../../../../services/requests/clients/clients'
import { getOrderPriceCounter } from '../../../../services/requests/orders/orders'
import { getDiscountReasons } from '../../../../services/requests/orders/orders-new-realization'
import {
  IPageStatus,
  IUpdateOrderInfoReq,
} from '../../../../services/requests/orders/orders-new.types'
import {
  getAllPartnerWorkers,
  getWorkerServices,
} from '../../../../services/requests/staff/staff'
import { AddressInput } from '../../../inputs/address-input/address-input'
import { MultipleSelect } from '../../../inputs/multiple-select/muliple-select'
import { modalActions } from '../../modal.actions'

import s from './styles.module.scss'
import { SuperSelect } from '../../../forms/super-select/SuperSelect'
import { ISuperSelectOption } from '../../../forms/super-select/utils'
import { isFieldError } from '../../../../helpers/isFieldError'

interface IProps {
  order: IOrder
  currency: string
  pageStatus: IPageStatus
}

interface IFormValues {
  service: string
  dogs: Array<string>
  startDate: string
  time: string
  address: {
    address: string
    lat: number | null
    long: number | null
    entrance: string
    floor: string
    apartment: string
    building_code: string
  }
  adminNote: string
  someone_at_home: {
    person: boolean
    note: string
  }
  keyinfo: string
  worker: ISuperSelectOption<string, string> | null
  adminCoins: number
  discount_reason: string
}

const timeItems: ISelectOption[] = [
  { title: '00-02', value: '00-02' },
  { title: '02-04', value: '02-04' },
  { title: '04-06', value: '04-06' },
  { title: '06-08', value: '06-08' },
  { title: '08-10', value: '08-10' },
  { title: '10-12', value: '10-12' },
  { title: '12-15', value: '12-14' },
  { title: '14-16', value: '14-16' },
  { title: '16-18', value: '16-18' },
  { title: '18-20', value: '18-20' },
  { title: '20-22', value: '20-22' },
  { title: '22-00', value: '22-00' },
]
export const EditOrderModal: FC<IProps> = ({ order, currency, pageStatus }) => {
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors,isDirty },
  } = useForm<IFormValues>({ mode: 'onChange' })

  const [dogs, startDate, adminCoins, time, service, person] = watch([
    'dogs',
    'startDate',
    'adminCoins',
    'time',
    'service',
    'someone_at_home.person',
  ])

  const { user } = order

  const [basePrice, setBasePrice] = useState(order.price.basic.price)
  const [additionPrice, setAdditionPrice] = useState(
    order?.price?.additionService?.reduce((acc, curr) => {
      return acc + curr.count * curr.price
    }, 0)
  )
  const [totalPrice, setTotalPrice] = useState(order.price.priceTotal)

  const [clientDogsOptions, setClientDogsOptions] = useState<ISelectOption[]>(
    []
  )

  const [servicesOptions, setServicesOptions] = useState<ISelectOption[]>([])
  const [workersOptions, setWorkersOptions] = useState<
    Array<ISuperSelectOption<string, string>>
  >([])
  const [filteredWorkerOptions, setFilteredWorkersOptions] = useState<
    Array<ISuperSelectOption<string, string>>
  >([])
  const [discountReasonOptions, setDiscountReasonOptions] = useState<
    ISelectOption[]
  >([])

  useEffect(() => {
    getPartnerClientDogs({
      clientId: [order.user.id],
    }).then(({ dogs }) => {
      setClientDogsOptions(
        dogs.map((dog) => ({ title: dog.name, value: dog._id }))
      )
    })
  }, [order.user.id])

  useEffect(() => {
    getAllPartnerWorkers({ id: 'me' }).then(({ result: workers }) => {
      const options = workers.map((worker) => ({
        label: worker.fulName,
        value: worker.id,
      }))
      setWorkersOptions(options)
      setFilteredWorkersOptions(options)
    })
  }, [order.user.id])

  useEffect(() => {
    getWorkerServices().then((res) => {
      setServicesOptions(
        res.map((service) => ({
          title: service.service.title,
          value: service._id,
        }))
      )
    })
  }, [])

  useEffect(() => {
    getDiscountReasons().then((res) => {
      setDiscountReasonOptions(
        res.result.map((reason) => ({
          value: reason.id,
          title: reason.name,
        }))
      )
    })
  }, [])

  useEffect(() => {
    if (dogs && startDate && time && service) {
      if (!adminCoins || adminCoins === 0) {
        setValue('discount_reason', '')
      }
      getOrderPriceCounter({
        clientId: user.id,
        dogsCount: dogs.length,
        orderInfo: {
          service: service,
          time: time,
          repeat: false,
          subscription: false,
          startDate: [moment(startDate).format('YYYY-MM-DD')],
        },
        discountInfo: {
          adminCoins: adminCoins ? Number(adminCoins) : 0,
        },
      }).then((res) => {
        if (res.additionService.length > 0) {
          setAdditionPrice(
            res.additionService
              .map((i) => i.count * i.price)
              .reduce((acc, curr) => acc + curr, 0)
          )
        }
        setBasePrice(res.basic.price)
        setTotalPrice(res.priceTotal)
      })
    } else {
      setTimeout(() => {
        setBasePrice(order.price.basic.price)
        setAdditionPrice(
          order?.price?.additionService?.reduce(
            (acc, curr) => acc + curr.count * curr.price,
            0
          )
        )
        setTotalPrice(order.price.priceTotal)
      }, 1000)
    }
  }, [user, dogs, startDate, time, service, adminCoins])

  useEffect(() => {
    setValue('address.lat', order.address.lat)
    setValue('address.long', order.address.long)
  }, [order.address, setValue])

  const onWorkerSearch = (value: string) => {
    setFilteredWorkersOptions(
      workersOptions.filter((worker) =>
        worker.label.toLowerCase().trim().includes(value.trim().toLowerCase())
      )
    )
  }

  const onSubmit = useCallback(
    (data: IFormValues) => {
      const body: IUpdateOrderInfoReq['body'] = {
        service: data.service,
        dogs: data.dogs,
        startDate: data.startDate,
        time: data.time,
        address: {
          ...data.address,
          lat: data.address.lat || 0,
          long: data.address.long || 0,
          country: order.address.country._id,
          city: order.address.city._id,
          zip: order.address.zip,
          zip_code: order.address.zip_code,
          state: order.address.state?._id,
        },
        adminNote: data.adminNote,
        someone_at_home: {
          person: order.someone_at_home.person,
          note: data.keyinfo,
        },
        worker: data.worker?.value as string,
        price: {
          discount: {
            admin: Number(data.adminCoins),
            reasonId: Number(data.adminCoins) ? data.discount_reason : null,
          },
        },
      }

      if (!order.address.state) {
        delete body.address.state
      }

      if (typeof body.worker !== 'string') {
        delete body.worker
      }

      dispatch(ordersApi.updateOrderInfo({ id: order._id, body, pageStatus }))
    },
    [order, dispatch, pageStatus]
  )

  return (
    <form className={s['modal']} onSubmit={handleSubmit(onSubmit)}>
      <div className={s['modal__header']}>
        <div className={s['modal-header__row']}>
          <p className={s['title']}>{t('modals.order-edit.header.title')}</p>
          <div className={s['controls']}>
            <div
              className={s['close-btn']}
              onClick={() => dispatch(modalActions.closeModal())}
            >
              <CrossIcon />
            </div>
          </div>
        </div>
        <div className={s['modal-header__row']}>
          <p className={s['text']}>
            {t('modals.order-edit.header.info-start')}{' '}
            <span className={s['text_strong']}>{order?._id}</span>{' '}
            {t('modals.order-edit.header.info-end')}{' '}
            <span className={s['text_strong']}>
              {moment(order?.startDate).format('DD.MM.YYYY HH:mm')}
            </span>
          </p>
          <p className={s['text']}>
            {t('modals.order-edit.header.status')}{' '}
            <span className={s['text_strong']}>{order?.status}</span>
          </p>
        </div>
      </div>

      {/* Form */}
      <div className={s['modal__content']}>
        {/* Left side */}
        <div className={s['modal-content__left-side']}>
          {/* Service select*/}
          <div className={s['input']}>
            <Controller
              name="service"
              control={control}
              rules={{
                required: 'This field is required',
              }}
              defaultValue={order.service.id}
              render={({ field }) => (
                <Select
                  fieldProps={field}
                  options={servicesOptions}
                  label={t('modals.order-edit.form.service-label')}
                  onSelect={({ value }: ISelectOption) => field.onChange(value)}
                  comment=""
                  // @ts-ignore
                  error={errors?.service?.message}
                  value={{
                    title: order.service.service.title,
                    value: order.service.service.id,
                  }}
                />
              )}
            />
          </div>

          {/* Dogs select*/}
          <div className={s['input']}>
            <Controller
              name="dogs"
              control={control}
              rules={{
                required: 'This field is required',
                validate: (value) =>
                  value.length <= 0 ? 'This field is required' : true,
              }}
              render={({ field }) => (
                <MultipleSelect
                  fieldProps={field}
                  options={clientDogsOptions}
                  label={t('modals.order-edit.form.dogs-label')}
                  // @ts-ignore
                  error={errors?.dogs?.message}
                  defaultValue={order.dogs.map((dog) => ({
                    title: dog.name,
                    value: dog.id,
                  }))}
                />
              )}
            />
          </div>

          {/* Date and time */}
          <div className={s['flex']}>
            {/* Start date input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="startDate"
                rules={{
                  required: 'This field is required',
                }}
                defaultValue={moment(order.startDate).format('YYYY-MM-DD')}
                render={({ field }) => (
                  <TextInput
                    type="date"
                    fieldProps={field}
                    controlId="startDate"
                    label={t('modals.order-edit.form.date-label')}
                    error={errors.startDate?.message}
                  />
                )}
              />
            </div>

            {/* Time slot select */}
            <div className={s['input']}>
              <Controller
                name="time"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                defaultValue={order.time}
                render={({ field }) => (
                  <Select
                    fieldProps={field}
                    options={timeItems}
                    label={t('modals.order-edit.form.time-slot-label')}
                    onSelect={({ value }: ISelectOption) =>
                      field.onChange(value)
                    }
                    comment=""
                    value={{ title: order.time, value: order.time }}
                    // @ts-ignore
                    error={errors.time?.message}
                  />
                )}
              />
            </div>
          </div>

          {/* Full address input */}
          <div className={s['input']}>
            <Controller
              control={control}
              name="address.address"
              rules={{
                required: 'This field is required',
              }}
              defaultValue={order.address.address || ''}
              render={({ field }) => (
                <AddressInput
                  field={field}
                  label={t('modals.order-edit.form.full-address-label')}
                  error={errors?.address?.address?.message}
                  onChange={({ address, lat, long }) => {
                    field.onChange(address)
                    setValue('address.lat', lat)
                    setValue('address.long', long)
                  }}
                />
              )}
            />
          </div>

          {/* Additional address inputs */}
          <div className={s['address-grid']}>
            {/* Entrance input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="address.entrance"
                defaultValue={order.address.entrance || ''}
                render={({ field }) => (
                  <TextInput
                    fieldProps={field}
                    label={t('modals.order-edit.form.entrance-label')}
                    controlId="address.entrance"
                  />
                )}
              />
            </div>

            {/* Floor input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="address.floor"
                defaultValue={order.address.floor || ''}
                render={({ field }) => (
                  <TextInput
                    fieldProps={field}
                    label={t('modals.order-edit.form.floor-label')}
                    controlId="address.floor"
                  />
                )}
              />
            </div>

            {/* Apartment input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="address.apartment"
                defaultValue={order.address.apartment || ''}
                render={({ field }) => (
                  <TextInput
                    fieldProps={field}
                    label={t('modals.order-edit.form.apartment-label')}
                    controlId="address.apartment"
                  />
                )}
              />
            </div>

            {/* Code input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="address.building_code"
                defaultValue={order.address.building_code || ''}
                render={({ field }) => (
                  <TextInput
                    fieldProps={field}
                    label={t('modals.order-edit.form.code-label')}
                    controlId="address.building_code"
                  />
                )}
              />
            </div>
          </div>

          {/* Admin note input */}
          <div className={s['input']}>
            <Controller
              control={control}
              name="adminNote"
              defaultValue={order.adminNote || ''}
              render={({ field }) => (
                <TextInput
                  fieldProps={field}
                  label={t('modals.order-edit.form.admin-note-label')}
                  placeholder={t(
                    'modals.order-edit.form.admin-note-placeholder'
                  )}
                  controlId="adminNote"
                  isTextArea
                  inputClassName={s['admin-note-input']}
                />
              )}
            />
          </div>

          {/* Someone at home input */}
          <div className={s['input']}>
            <Controller
              control={control}
              name="someone_at_home.person"
              defaultValue={order.someone_at_home?.person}
              render={({ field }) => (
                <div className={s['switch']}>
                  <p className={s['switch__label']}>
                    {t('modals.order-edit.form.someone-at-home-label')}
                  </p>
                  <Switch
                    onChange={(value) => field.onChange(value)}
                    value={field.value}
                  />
                </div>
              )}
            />
          </div>

          {/* Key info input */}
          <div className={s['input']}>
            <Controller
              control={control}
              name="someone_at_home.note"
              defaultValue={person ? '' : order?.someone_at_home?.note}
              render={({ field }) => (
                <TextInput
                  fieldProps={field}
                  label={t('modals.order-edit.form.key-info-label')}
                  controlId="someone_at_home.note"
                  placeholder={
                    person
                      ? 'modals.order-edit.form.key-info-placeholder1'
                      : 'modals.order-edit.form.key-info-placeholder2'
                  }
                  isDisabled={person}
                />
              )}
            />
          </div>
        </div>

        {/* Right side */}
        <div className={s['modal-content__right-side']}>
          {/* Inputs */}
          <div className={s['right-side__inputs']}>
            {/* Worker select */}
            <div className={s['input']}>
              <Controller
                name="worker"
                control={control}
                rules={{
                  required: 'This field is required',
                }}
                defaultValue={
                  order.worker
                    ? { label: order.worker.fulName, value: order.worker._id }
                    : null
                }
                render={({ field }) => (
                  <SuperSelect
                    closeMenuOnSelect
                    field={field}
                    options={filteredWorkerOptions}
                    label={t('modals.order-edit.form.worker-label')}
                    placeholder={t('modals.order-edit.form.worker-label')}
                    error={
                      isFieldError(errors.worker)
                        ? errors.worker?.message
                        : undefined
                    }
                    isClearable
                    onInputChange={onWorkerSearch}
                    isDisabled={!filteredWorkerOptions.length}
                  />
                )}
              />
            </div>

            {/* Discount input */}
            <div className={s['input']}>
              <Controller
                control={control}
                name="adminCoins"
                defaultValue={order.price?.discount?.admin || 0}
                rules={{
                  validate: (value) => (value >= 0 ? true : 'Minimal value: 0'),
                }}
                render={({ field }) => (
                  <TextInput
                    fieldProps={field}
                    label={t('modals.order-edit.form.discount-label')}
                    error={errors.adminCoins?.message}
                    controlId="adminCoins"
                    preLabel={currency}
                    placeholder={'0.00'}
                    type="number"
                  />
                )}
              />
            </div>

            {/* Discount reason select */}
            <div className={s['input']}>
              <Controller
                name="discount_reason"
                rules={{
                  validate: (value) =>
                    adminCoins && !value ? 'This field is required' : true,
                }}
                control={control}
                defaultValue={order.price?.discount?.reasonId?._id}
                render={({ field }) => (
                  <Select
                    fieldProps={field}
                    options={discountReasonOptions}
                    disabled={!adminCoins || Number(adminCoins) <= 0}
                    label={t('modals.order-edit.form.discount-reason-label')}
                    placeholder={
                      'modals.order-edit.form.discount-reason-placeholder'
                    }
                    // @ts-ignore
                    error={errors.discount_reason?.message}
                    onSelect={({ value }: ISelectOption) =>
                      field.onChange(value)
                    }
                    comment=""
                    value={
                      adminCoins &&
                      order?.price?.discount?.reasonId?._id &&
                      Number(adminCoins) > 0
                        ? {
                            value: order?.price?.discount?.reasonId?._id,
                            title:
                              order?.price?.discount?.reasonId
                                ?.name_localization?.en ||
                              order?.price?.discount?.reasonId?.name,
                          }
                        : undefined
                    }
                  />
                )}
              />
            </div>
          </div>

          {/* Price information */}
          <div className={s['right-side__info']}>
            <div className={s['info-row']}>
              <p className={s['info-row__text']}>
                {t('modals.order-edit.form.service-price')}
              </p>
              <p className={s['info-row__text_strong']}>{`${currency} ${
                basePrice || 0
              }`}</p>
            </div>
            <div className={s['info-row']}>
              <p className={s['info-row__text']}>
                {t('modals.order-edit.form.additional-fees')}
              </p>
              <p className={s['info-row__text_strong']}>{`${currency} ${
                additionPrice || 0
              }`}</p>
            </div>
            <div className={s['info-row']}>
              <p className={s['info-row__text']}>
                {t('modals.order-edit.form.total-price')}
              </p>
              <p className={s['info-row__text_strong']}>{`${currency} ${
                totalPrice || 0
              }`}</p>
            </div>
          </div>
        </div>
      </div>

      <div className={s['modal__footer']}>
        <p className={s['text']}>
          {t('modals.order-edit.footer.last-update')}{' '}
          <span className={s['text_strong']}>
            {moment(order.updateDate).format('DD.MM.YYYY HH:mm')}
          </span>
        </p>

        <button className={s['save-btn']} type="submit" disabled={!isDirty}>
          {t('modals.order-edit.footer.save')}
        </button>
      </div>
    </form>
  )
}
