import { createSlice } from '@reduxjs/toolkit'
import { resetStates } from './states.actions'
import { getStatesThunk } from './states.thunks'
import { statesSelectors as selectors } from './states.selectors'

import { IState } from './states.types'

const initialState = {
  isLoading: false,
  error: '',
  data: null as null | Array<IState>,
  total: 0,
}

export const statesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStatesThunk.pending, (state) => {
      state.error = ''
      state.total = 0
      state.isLoading = true
    })

    builder.addCase(getStatesThunk.fulfilled, (state, { payload }) => {
      state.error = ''
      state.data = payload.states
      state.total = payload.states.length
      state.isLoading = false
    })

    builder.addCase(getStatesThunk.rejected, (state, { payload }) => {
      state.error = payload?.errorData.message || 'failed'
      state.data = null
      state.total = 0
      state.isLoading = false
    })

    builder.addCase(resetStates, () => {
      return initialState
    })
  },
})

export const statesReducer = statesSlice.reducer

export const statesSelectors = selectors

export const statesActions = {
  reset: resetStates,
}

export const statesApi = {
  getStates: getStatesThunk,
}
