import { post } from '../../api'
import { WORKER_API } from '../../api/api.config'

export const registerDeviceForPushNotifications = async (params: {
  nameDevice?: string | number
  fcmToken?: string | number
}) => {
  const url = '/auth/registration-device'
  try {
    const { data } = await post(url, params, { baseURL: WORKER_API })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
