import moment from 'moment'
import {
  ActionReducerMapBuilder,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
} from '@reduxjs/toolkit'

import { OrderPageStatuses } from './constants'
import {
  IResetBulkOrderReq,
  GetOrdersReq,
  IResetOrderReq,
  OrderReqStatus,
  OrdersPaginationRes,
  UpdatedBulkOrderStatusReq,
  UpdatedStatusReq,
  UpdatedWorkerBulkReq,
  UpdatedWorkerReq,
} from './../../services/requests/orders/types'
import { UnboxPromise } from './../../helpers/types'
import { GroupOrders, Order } from './types'
import {
  bulkResetOrders,
  getOrderAfterPushReceived,
  getOrderByID,
  getOrderCounts,
  getOrders,
  getOrdersByGroup,
  getOrdersByGroupIds,
  getOrderTotalCounts,
  resetOrder,
  updateAdminNote,
  updateBulkOrderStatus,
  updateBulkOrderWorker,
  updateDiscount,
  updateOrderStatus,
  updateOrderWorker,
} from './../../services/requests/orders/orders'
import {
  orderGroupsAdapter,
  ordersAcceptedAdapter,
  ordersHistoryAdapter,
  ordersPendingAdapter,
  OrdersState,
  ordersThunkConfig,
  ordersTodayAdapter,
} from './orders.slice'
import { createThunkResolver } from '../../helpers/index'
import { AppDispatch } from '../../store/store'
import { OrderStatuses } from '../../services/requests/orders/constants'
import { getMapCoordsBySearch } from '../../services/requests/map/map'
import { IResponseError } from '../../app-types/response-types/response-error'
import { toastActions } from '../../components/toast/toast.slice'

export const getOrdersThunk = createAsyncThunk<
  { data: OrdersPaginationRes; status: OrderReqStatus },
  GetOrdersReq,
  any
>('orders/selected', async (args, { dispatch, rejectWithValue }) => {
  try {
    const { data, status } = await getOrders(args)
    return { data, status }
  } catch (error: any) {
    dispatch(
      toastActions.showToast({
        key: 'orders-getting',
        type: 'failed',
      })
    )
    return rejectWithValue(error)
  }
})
const getOrdersThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrders>>
>(getOrdersThunk)

export const resolveOrdersThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getOrdersThunkResolver(builder)
    .onPending((state, action) => {
      const { prop } = ordersThunkConfig[action.meta.arg.status]
      state.orders[prop].loading = true
    })
    .onFulfilled((state, action) => {
      const { data } = action.payload
      const { adapter, prop } = ordersThunkConfig[action.meta.arg.status]
      adapter.setAll(state.orders[prop], data.order)
      state.orders[prop].loading = false
      state.orders[prop].totalCount = data.totalCount
    })
    .onRejected((state, action) => {
      const { prop } = ordersThunkConfig[action.meta.arg.status]
      state.orders[prop].loading = false
    })

export const getOrderByIDThunk = createAsyncThunk('orders/by-id', getOrderByID)
const getOrderByIDThunkResolver = createThunkResolver<OrdersState, Order>(
  getOrderByIDThunk
)
export const resolveOrderByIDThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getOrderByIDThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.selectedOrder = action.payload
    })
    .onRejected()

export const updateOrderStatusThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof updateOrderStatus>>,
  UpdatedStatusReq,
  { dispatch: AppDispatch }
>('orders/update-status', updateOrderStatus)
const updateOrderStatusThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof updateOrderStatus>>
>(updateOrderStatusThunk)
export const resolveUpdateOrderStatusThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateOrderStatusThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const {
        orderId,
        status,
        groupId,
        isGroupCard,
        updatedOrder,
        pageStatus,
      } = action.payload

      if (pageStatus === OrderPageStatuses.ACCEPTED) {
        if (status === OrderStatuses.LIVE) {
          ordersAcceptedAdapter.updateOne(state.orders.accepted, {
            id: orderId,
            changes: updatedOrder,
          })
        }
        if (
          status === OrderStatuses.FINISHED ||
          status === OrderStatuses.CANCEL_BY_SERVICE
        ) {
          ordersAcceptedAdapter.removeOne(state.orders.accepted, orderId)
        }
      }

      if (pageStatus === OrderPageStatuses.TODAY) {
        if (
          status === OrderStatuses.LIVE ||
          status === OrderStatuses.ACCEPTED
        ) {
          ordersTodayAdapter.updateOne(state.orders.today, {
            id: orderId,
            changes: updatedOrder,
          })
        }
        if (
          status === OrderStatuses.FINISHED ||
          status === OrderStatuses.CANCEL_BY_SERVICE ||
          status === OrderStatuses.DECLINE_BY_SERVICE
        ) {
          ordersTodayAdapter.removeOne(state.orders.today, orderId)
        }
      }

      if (pageStatus === OrderPageStatuses.PENDING) {
        if (groupId) {
          if (isGroupCard) {
            const ids = state.groups.entities[groupId]?.orders.map((i) => i._id)
            // @ts-ignore
            ordersPendingAdapter.removeMany(state.orders.pending, ids)

            orderGroupsAdapter.removeOne(state.groups, groupId)
          } else {
            ordersPendingAdapter.removeOne(state.orders.pending, orderId)

            const groupLength = state.groups.entities[groupId]?.orders?.length

            if (groupId && groupLength !== undefined && groupLength <= 1) {
              orderGroupsAdapter.removeOne(state.groups, groupId)
            } else {
              // @ts-ignore
              state.groups.entities[groupId].orders =
                // @ts-ignore
                state.groups.entities[groupId].orders.filter(
                  (i: Order) => i._id !== orderId
                )
            }
          }
        } else {
          ordersPendingAdapter.removeOne(state.orders.pending, orderId)
        }
      }
    })
    .onRejected((state) => (state.orders.pending.loading = false))

export const updateOrderWorkerThunk = createAsyncThunk(
  'orders/update-worker',
  updateOrderWorker
)
const updateOrderWorkerThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof updateOrderWorker>>
>(updateOrderWorkerThunk)
export const resolveUpdateOrderWorkerThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateOrderWorkerThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const { groupId } = action.meta.arg as UpdatedWorkerReq
      const { updatedOrderGroup } = action.payload
      if (groupId) {
        const group = state.groups.entities[groupId]
        if (group && updatedOrderGroup) {
          orderGroupsAdapter.updateOne(state.groups, {
            id: groupId,
            changes: updatedOrderGroup,
          })
        }
      }
    })
    .onRejected()

export const getOrdersByGroupsThunk = createAsyncThunk(
  'orders/groups',
  getOrdersByGroupIds
)
const getGroupsThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrdersByGroupIds>>
>(getOrdersByGroupsThunk)

export const resolveGroupsThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getGroupsThunkResolver(builder)
    .onPending((state) => {
      state.groups.loading = true
    })
    .onFulfilled((state, action) => {
      const groupsById = action.payload
      orderGroupsAdapter.setAll(state.groups, groupsById as GroupOrders[])
      state.groups.loading = false
    })
    .onRejected()

export const updateBulkOrderStatusThunk = createAsyncThunk(
  'orders/bulk',
  updateBulkOrderStatus as AsyncThunkPayloadCreator<
    unknown,
    UpdatedBulkOrderStatusReq,
    {}
  >
)
const updateBulkOrderStatusThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrdersByGroup>>
>(updateBulkOrderStatusThunk)

export const resolveUpdateBulkOrderStatusThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateBulkOrderStatusThunkResolver(builder)
    .onPending((state, action) => {
      const { pageStatus } = action.meta.arg as UpdatedBulkOrderStatusReq
      const { prop } = ordersThunkConfig[pageStatus]
      state.orders[prop].loading = true
      state.bulk = []
    })
    .onFulfilled()
    .onRejected()

export const updateDiscountThunk = createAsyncThunk(
  'orders/discount',
  updateDiscount
)
const updateDiscountThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof updateDiscount>>
>(updateDiscountThunk)

export const resolveUpdateDiscountThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateDiscountThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const { orderId, pageStatus, updatedOrder } = action.payload
      const { adapter, prop } = ordersThunkConfig[pageStatus]
      const order = state.orders[prop].entities[orderId]
      if (order) {
        adapter.updateOne(state.orders[prop], {
          id: orderId,
          changes: updatedOrder,
        })
      }
    })
    .onRejected()

export const getCountsThunk = createAsyncThunk('orders/counts', getOrderCounts)
const getCountsThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrderCounts>>
>(getCountsThunk, 'countsLoading')

export const resolveGetCountsThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getCountsThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const counts = action.payload
      state.counts = counts
    })
    .onRejected()

export const getMapCoordsBySearchThunk = createAsyncThunk(
  'map/coords_by_search',
  getMapCoordsBySearch
)
const getMapCoordsBySearchThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getMapCoordsBySearch>>
>(getMapCoordsBySearchThunk)

export const resolveGetMapCoordsBySearchThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getMapCoordsBySearchThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const { coords, orderId } = action.payload
      state.map.loading = false
      if (coords) state.map.inOrders[orderId] = coords
    })
    .onRejected((state) => {
      state.map.loading = false
    })

export const updateBulkWorkerThunk = createAsyncThunk(
  'order/worker-update-bulk',
  updateBulkOrderWorker as AsyncThunkPayloadCreator<
    unknown,
    UpdatedWorkerBulkReq,
    {}
  >
)

const updateBulkWorkerThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof updateBulkOrderWorker>>
>(updateBulkWorkerThunk)

export const resolveUpdateBulkWorkerThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateBulkWorkerThunkResolver(builder)
    .onPending((state, action) => {
      const { pageStatus } = action.meta.arg as UpdatedBulkOrderStatusReq
      const { prop } = ordersThunkConfig[pageStatus]
      state.orders[prop].loading = true
    })
    .onFulfilled((state, action) => {
      const { pageStatus } = action.payload
      const { prop } = ordersThunkConfig[pageStatus]
      state.orders[prop].loading = false
    })
    .onRejected((state, action) => {
      const { pageStatus } = action.meta.arg as UpdatedBulkOrderStatusReq
      const { prop } = ordersThunkConfig[pageStatus]
      state.orders[prop].loading = false
    })

export const getTotalCountsThunk = createAsyncThunk(
  'order/total-counts',
  getOrderTotalCounts
)

const getTotalCountsThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrderTotalCounts>>
>(getTotalCountsThunk, 'totalCountsLoading')

export const resolveGetTotalCountsThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getTotalCountsThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.totalCounts = action.payload
    })
    .onRejected()

export const updateAdminNoteThunk = createAsyncThunk(
  'orders/admin-note',
  updateAdminNote
)
const updateAdminNoteThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof updateAdminNote>>
>(updateAdminNoteThunk)

export const resolveUpdateAdminNoteThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  updateAdminNoteThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const { orderId, pageStatus, updatedOrder } = action.payload
      const { adapter, prop } = ordersThunkConfig[pageStatus]
      const order = state.orders[prop].entities[orderId]
      if (order) {
        adapter.updateOne(state.orders[prop], {
          id: orderId,
          changes: updatedOrder,
        })
      }
    })
    .onRejected()

export const resetOrderThunk = createAsyncThunk<
  UnboxPromise<ReturnType<typeof resetOrder>>,
  IResetOrderReq,
  { dispatch: AppDispatch }
>('orders/reset-order', resetOrder)
const resetOrderThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof resetOrder>>
>(resetOrderThunk)
export const resolveResetOrderThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  resetOrderThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      const { id, pageStatus } = action.payload

      if (pageStatus === OrderPageStatuses.ACCEPTED) {
        ordersAcceptedAdapter.removeOne(state.orders.accepted, id)
      }

      if (pageStatus === OrderPageStatuses.TODAY) {
        ordersTodayAdapter.removeOne(state.orders.today, id)
      }

      if (pageStatus === OrderPageStatuses.HISTORY) {
        ordersHistoryAdapter.removeOne(state.orders.history, id)
      }

      if (pageStatus === OrderPageStatuses.DISMISSED) {
        ordersHistoryAdapter.removeOne(state.orders.dismissed, id)
      }
    })
    .onRejected((state) => (state.loading = false))

export const getOrderAfterPushReceivedThunk = createAsyncThunk(
  'orders/order-after-push-received',
  getOrderAfterPushReceived
)
const getOrderAfterPushReceivedThunkResolver = createThunkResolver<
  OrdersState,
  UnboxPromise<ReturnType<typeof getOrderAfterPushReceived>>
>(getOrderAfterPushReceivedThunk)
export const resolveGetOrderAfterPushReceivedThunk = (
  builder: ActionReducerMapBuilder<OrdersState>
) =>
  getOrderAfterPushReceivedThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      if (action.payload) {
        const { data: order, pageStatus } = action.payload

        type OrdersStateType = keyof typeof state.orders

        const stateStatus = ((): OrdersStateType => {
          switch (pageStatus) {
            case 'ACCEPTED':
              return 'accepted'
            case 'DISMISSED':
              return 'dismissed'
            case 'PENDING':
              return 'pending'
            case 'TODAY':
              return 'today'
            case 'HISTORY':
              return 'history'
          }
        })()

        const isOldOrder = state.orders[stateStatus].ids.includes(order.id)

        if (pageStatus === 'PENDING') {
          if (isOldOrder) {
            if (order.status === OrderStatuses.PENDING) {
              ordersPendingAdapter.updateOne(state.orders.pending, {
                id: order._id,
                changes: order,
              })
            } else {
              ordersPendingAdapter.removeOne(state.orders.pending, order._id)
            }
          } else {
            if (order.status === OrderStatuses.PENDING) {
              ordersPendingAdapter.addOne(state.orders.pending, order)
            }
          }
        }

        if (pageStatus === 'ACCEPTED') {
          if (isOldOrder) {
            if (
              order.status === OrderStatuses.CANCEL_BY_USER ||
              order.status === OrderStatuses.CANCEL_BY_USER_AFTER_8 ||
              order.status === OrderStatuses.CANCEL_BY_SERVICE ||
              order.status === OrderStatuses.DECLINE_BY_SERVICE ||
              order.status === OrderStatuses.DECLINE_BY_USER ||
              order.status === OrderStatuses.FINISHED
            ) {
              ordersAcceptedAdapter.removeOne(state.orders.accepted, order._id)
            } else if (order.status === OrderStatuses.LIVE) {
              ordersAcceptedAdapter.updateOne(state.orders.accepted, {
                id: order._id,
                changes: order,
              })
            }
          } else {
            if (
              order.status === OrderStatuses.ACCEPTED ||
              order.status === OrderStatuses.LIVE
            ) {
              ordersAcceptedAdapter.addOne(state.orders.accepted, order)
            }
          }
        }
        // add startDate logic
        if (pageStatus === 'TODAY') {
          if (isOldOrder) {
            if (
              order.status !== OrderStatuses.PENDING &&
              order.status !== OrderStatuses.ACCEPTED &&
              order.status !== OrderStatuses.LIVE
            ) {
              ordersAcceptedAdapter.removeOne(state.orders.today, order._id)
            } else if (
              order.status === OrderStatuses.LIVE ||
              order.status === OrderStatuses.ACCEPTED ||
              order.status === OrderStatuses.PENDING
            ) {
              ordersAcceptedAdapter.updateOne(state.orders.today, {
                id: order._id,
                changes: order,
              })
            }
          } else {
            if (
              (order.status === OrderStatuses.ACCEPTED ||
                order.status === OrderStatuses.LIVE ||
                order.status === OrderStatuses.PENDING) &&
              order.startDate.split('T')[0] === moment().format('YYYY-MM-DD')
            ) {
              ordersAcceptedAdapter.addOne(state.orders.today, order)
            }
          }
        }

        if (pageStatus === 'HISTORY') {
          if (isOldOrder) {
            if (order.status === OrderStatuses.PENDING)
              ordersHistoryAdapter.removeOne(state.orders.history, order._id)
          } else {
            if (
              order.status === OrderStatuses.CANCEL_BY_USER_AFTER_8 ||
              order.status === OrderStatuses.FINISHED
            )
              ordersHistoryAdapter.addOne(state.orders.history, order)
          }
        }

        if (pageStatus === 'DISMISSED') {
          if (isOldOrder) {
            if (order.status === OrderStatuses.PENDING)
              ordersHistoryAdapter.removeOne(state.orders.dismissed, order._id)
          } else {
            if (
              order.status === OrderStatuses.CANCEL_BY_SERVICE ||
              order.status === OrderStatuses.CANCEL_BY_USER ||
              order.status === OrderStatuses.DECLINE_BY_SERVICE ||
              order.status === OrderStatuses.DECLINE_BY_USER
            )
              ordersHistoryAdapter.addOne(state.orders.dismissed, order)
          }
        }
      }
    })
    .onRejected()

export const bulkResetOrdersThunk = createAsyncThunk<
  IResetBulkOrderReq,
  IResetBulkOrderReq,
  { rejectValue: IResponseError }
>(
  'orders/bulk-reset',
  async ({ ids, pageStatus }, { dispatch, rejectWithValue }) => {
    try {
      const data = await bulkResetOrders({ ids, pageStatus })

      dispatch(
        toastActions.showToast({
          withVariable: {
            variable: 'count',
            variableValue: ids.length.toString(),
          },
          key: 'bulk-order-reseting',
          type: 'success',
        })
      )

      return data
    } catch (error) {
      dispatch(
        toastActions.showToast({
          key: 'bulk-order-reseting',
          type: 'failed',
        })
      )
      return rejectWithValue(error as IResponseError)
    }
  }
)
