import { RouteBook } from './../components/menu/constants'
import { ActionFulfilled,  } from './types'
import { AppDispatch, RootState } from './../store/store'
import { useDispatch, TypedUseSelectorHook, useSelector } from 'react-redux'
import {
  ActionReducerMapBuilder,
  AsyncThunk,
  PayloadAction,
  Draft,
} from '@reduxjs/toolkit'
import { t } from 'i18next'

import { OrderPageStatuses } from '../pages/orders/constants'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const createThunkResolver =
  <TLoading, TThunkRes>(
    thunk: AsyncThunk<any, any, any>,
    loadingName: string = 'loading'
  ) =>
  (builder: ActionReducerMapBuilder<TLoading>) =>
    resolveThunk<TLoading, TThunkRes>(builder, thunk, loadingName)

export const resolveThunk = <TInitialState, TThunkRes>(
  builder: ActionReducerMapBuilder<TInitialState>,
  thunk: AsyncThunk<TThunkRes, any, any>,
  loadingName: string
) => {
  return {
    onPending(
      cb?: (
        state: Draft<TInitialState>,
        action: PayloadAction<any, any, any>
      ) => void
    ) {
      builder.addCase(thunk.pending, (state: any, action) => {
        state[loadingName] = true
        state.error = null
        cb?.(state, action)
      })
      return this
    },
    onFulfilled(
      cb?: (
        state: Draft<TInitialState>,
        action: ActionFulfilled<typeof thunk>
      ) => void
    ) {
      builder.addCase(
        thunk.fulfilled,
        (state: any, action: ActionFulfilled<typeof thunk>) => {
          state[loadingName] = false
          state.error = null
          cb?.(
            state as Draft<TInitialState>,
            action as ActionFulfilled<typeof thunk>
          )
        }
      )
      return this
    },
    onRejected(cb?: (state: Draft<TInitialState>, action: any) => void) {
      builder.addCase(thunk.rejected, (state: any, action) => {
        state[loadingName] = false
        // state.error = action.error
        state.error = null
        cb?.(state, action)
      })
      return this
    },
  }
}

export const dateParser = (date: Date, separator: string) => {
  const day = new Date(date).getDate()
  const month = new Date(date).getMonth()
  const year = new Date(date).getFullYear()

  const parsedDay = day.toString().length == 1 ? '0' + day : day
  const parsedMonth = t(`helpers.months.short.${month}`)
  return `${parsedDay} ${parsedMonth}${separator}${year}`
}

export function debounce(func: any, timeout = 300) {
  let timer: any
  return function (this: unknown, ...args: any) {
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(this, args)
    }, timeout)
  }
}

export const getOrderPageStatus = (path: string): OrderPageStatuses => {
  let pageStatus: OrderPageStatuses = OrderPageStatuses.PENDING
  if (path.includes(RouteBook.OrdersAccepted))
    pageStatus = OrderPageStatuses.ACCEPTED
  if (path.includes(RouteBook.OrdersDismissed))
    pageStatus = OrderPageStatuses.DISMISSED
  if (path.includes(RouteBook.OrdersHistory))
    pageStatus = OrderPageStatuses.HISTORY
  if (path.includes(RouteBook.OrdersToday)) pageStatus = OrderPageStatuses.TODAY
  return pageStatus
}

export * from './date'
