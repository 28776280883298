import React, {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { ControllerRenderProps, FieldValues } from 'react-hook-form'
import cn from 'classnames'

import useOutsideClick from '../../../hooks/use-outside-click'
import { ArrowUpIcon, CrossIcon, SearchIcon } from '../../../assets/icons'
import { debounce } from '../../../helpers'

import s from './styles.module.scss'

interface ISelectOption {
  title: string
  value: string
}

interface IProps {
  fieldProps: ControllerRenderProps<any, any>
  label?: string
  options: ISelectOption[] | []
  disabled?: boolean
  withSearch?: boolean
  onSearch?: (value: string) => void
  placeholder?: string
  error?: string
  defaultValue?: ISelectOption[]
  withLazyLoading?: boolean
  lazyLoadingCallback?: (pageNumber: number) => Promise<any>
  pagesCount?: number | null
  pageSize?: number
  onSelect?: (selected: ISelectOption[] | undefined) => void
}

export const MultipleSelect = forwardRef(
  (
    {
      fieldProps: { value, onChange, name },
      label,
      options,
      disabled = false,
      placeholder,
      withSearch = false,
      onSearch,
      error,
      defaultValue,
      withLazyLoading = false,
      lazyLoadingCallback = () => Promise.resolve(),
      pagesCount,
      pageSize = 50,
      onSelect,
    }: IProps,
    ref
  ) => {
    const selectRef = useRef<HTMLDivElement>(null)
    const bodyRef = useRef<HTMLDivElement>(null)
    let lastOptionRef = useRef<HTMLDivElement>(null)

    const [open, setOpen] = useState(false)
    const [selected, setSelected] = useState<ISelectOption[] | undefined>()
    const [isObserving, setIsObserving] = useState(false)
    const [isOptionsLoading, setIsOptionsLoading] = useState(false)

    useEffect(() => {
      if (!value && !defaultValue) setSelected(value)
    }, [value])

    useEffect(() => {
      if (defaultValue) setSelected(defaultValue)
    }, [])

    useEffect(() => {
      if (selected?.length === 0) {
        onChange(undefined)
        onSelect && onSelect(undefined)
      } else {
        onChange(selected?.map((i) => i.value))
        onSelect && onSelect(selected)
      }
    }, [selected])

    useOutsideClick(selectRef, () => setOpen(false))

    const toggleOpen = () => !disabled && setOpen((p) => !p)

    const debouncedSearch = debounce(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onSearch) onSearch(e.target.value)
      },
      1000
    )

    const observerOptions: IntersectionObserverInit = useMemo(
      () => ({
        root: bodyRef.current,
        rootMargin: '0px',
        threshold: 0,
      }),
      [bodyRef]
    )

    const observerCallback: IntersectionObserverCallback = useCallback(
      (entries, observer) => {
        if (entries[0].isIntersecting) {
          const pageNumber = options ? Math.ceil(options?.length / pageSize) : 0
          if (pagesCount !== pageNumber) {
            observer.disconnect()
            setIsOptionsLoading(true)
            lazyLoadingCallback(pageNumber).then(() => {
              setIsObserving(false)
              setIsOptionsLoading(false)
            })
          } else {
            observer.disconnect()
          }
        }
      },
      [lazyLoadingCallback, options, pagesCount, pageSize]
    )

    const observer = useMemo(() => {
      if (withLazyLoading) {
        return new IntersectionObserver(observerCallback, observerOptions)
      } else {
        return null
      }
    }, [withLazyLoading, observerCallback, observerOptions])

    useEffect(() => {
      observer?.disconnect()
      setIsObserving(false)
    }, [options])

    useEffect(() => {
      if (!isObserving && observer && lastOptionRef.current && bodyRef) {
        observer.observe(lastOptionRef.current)
        setIsObserving(true)
      }
    }, [lastOptionRef, observer, bodyRef, isObserving])

    return (
      <div ref={selectRef} className={s['select']} id={name}>
        {label && (
          <label className={s['select-label']}>
            <p className={s['select-label-text']}>{label}</p>
            <p className={s['select-label-error']}>{error}</p>
          </label>
        )}
        <div className={s['select-container']}>
          <div
            role="button"
            className={cn([
              s['select-input'],
              { [s['select-input-closed']]: !open },
              { [s['disabled']]: disabled },
              { [s['error']]: error },
            ])}
            onClick={toggleOpen}
          >
            <div className={s['select-input-placeholder']}>
              <p
                className={cn({
                  [s['placeholder-text']]: !selected || selected.length < 1,
                })}
              >
                {!selected || selected.length < 1
                  ? placeholder
                  : selected.map((i) => i.title).join(', ')}
              </p>
            </div>
            <ArrowUpIcon />
          </div>
          <div
            className={cn([
              s['select-dropdown-body'],
              { [s['closed']]: !open },
            ])}
          >
            <>
              {withSearch && (
                <div className={s['search']}>
                  <span className={s['search-icon']}>
                    <SearchIcon />
                  </span>
                  <input
                    type="text"
                    placeholder="Search..."
                    disabled={isOptionsLoading}
                    onChange={(e) => {
                      debouncedSearch(e)
                    }}
                  />
                </div>
              )}
              {selected && selected.length > 0 && (
                <div className={s['selected-list-container']}>
                  <div className={s['selected-list']}>
                    {selected.map((item) => {
                      return (
                        <div
                          key={item.value}
                          className={s['selected-list-item']}
                        >
                          <p className={s['selected-list-item-title']}>
                            {item.title}
                          </p>
                          <div
                            role="button"
                            onClick={(e) => {
                              e.stopPropagation()
                              setSelected((p) =>
                                p
                                  ? p.filter(
                                    ({ value }) => value !== item.value
                                  )
                                  : p
                              )
                            }}
                            className={s['selected-list-item-button']}
                          >
                            <CrossIcon />
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )}
              <div className={s['options-body']} ref={bodyRef}>
                {options.length > 0 ? (
                  options.map((option, idx) => (
                    <div
                      ref={idx === options.length - 1 ? lastOptionRef : null}
                      role="button"
                      key={option.value}
                      onClick={() => {
                        setSelected((p) =>
                          p
                            ? p.some(({ value }) => value === option.value)
                              ? p.filter(({ value }) => value !== option.value)
                              : p.concat(option)
                            : [option]
                        )
                      }}
                      className={s['option']}
                    >
                      <div
                        className={cn([
                          s['circle'],
                          {
                            [s['circle-selected']]: selected?.some(
                              ({ value }) => value === option.value
                            ),
                          },
                        ])}
                      ></div>
                      <p className={s['option-text']}>{option.title}</p>
                    </div>
                  ))
                ) : (
                  <p className={s['no-options']}>Nothing found...</p>
                )}
              </div>
            </>
          </div>
        </div>
      </div>
    )
  }
)

MultipleSelect.displayName = 'MultipleSelect'
