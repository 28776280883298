import { useRef, useEffect } from 'react'
import { FC } from 'react'
//@ts-ignore
import mapboxgl from '!mapbox-gl'
import { Map as MapBoxMap } from 'mapbox-gl'
import cn from 'classnames'

mapboxgl.accessToken =
  'pk.eyJ1Ijoic2VyaGlpaHViY2hha2V2eWNoIiwiYSI6ImNreDRnZDZsdzF2MmEyeG9ibXRqbjIwdTAifQ.S_9EstXll20lsnTw1OYqGw'
export type IMapMarkers = {
  coord: [number, number]
  dogId: string
  typeActivity: string
}

interface IMap {
  coords: Array<[number, number]>
  markers: IMapMarkers[]
  height?: number
  className?: string
  mapID: string
}

export const Map: FC<IMap> = ({
  coords,
  markers,
  height,
  className,
  mapID,
}) => {
  const mapContainer = useRef<HTMLDivElement>(null)
  const map = useRef<MapBoxMap>(null)

  const createPopup = (text: string) => {
    const popup = new mapboxgl.Popup({ offset: 25 }).setText(
      `Here was ${text} event :)`
    )

    return popup
  }

  useEffect(() => {
    if (map.current) return
    //@ts-ignore
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: coords[0],
      zoom: 12,
    })
  }, [map, coords])

  useEffect(() => {
    if (!map.current) return

    if (map.current.getSource(mapID)) return

    map.current.on('load', () => {
      map.current &&
        map.current.addSource(mapID, {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: coords,
            },
          },
        })

      map.current &&
        map.current.addLayer({
          id: mapID,
          type: 'line',
          source: mapID,
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#985ab5',
            'line-width': 8,
          },
        })

      if (markers.length) {
        markers.forEach((item) =>
          new mapboxgl.Marker()
            .setLngLat(item.coord)
            .setPopup(createPopup(item.typeActivity))
            .addTo(map.current)
        )
      }

      map.current &&
        map.current.on('remove', () => {
          map.current && map.current.removeSource(mapID)
          //@ts-ignore
          map.current = null
        })
    })
  }, [coords, mapID, markers])

  return (
    <div style={{ width: '100%' }}>
      <div
        ref={mapContainer}
        className={cn(['map-container', className])}
        style={{ height: height || 400 }}
      />
    </div>
  )
}
