import { ReactComponent as ArrowUpIcon } from './arrowUpIcon.svg'
import { ReactComponent as LogoBigIcon } from './logoBigIcon.svg'
import { ReactComponent as OrdersIcon } from './ordersIcon.svg'
import { ReactComponent as SearchIcon } from './searchIcon.svg'
import { ReactComponent as LocationIcon } from './locationIcon.svg'
import { ReactComponent as BellIcon } from './bellIcon.svg'
import { ReactComponent as MapIcon } from './map-icon.svg'
import { ReactComponent as BurgerIcon } from './list.svg'
import { ReactComponent as CalendarIcon } from './calendar.svg'
import { ReactComponent as FiltersIcon } from './filters.svg'
import { ReactComponent as SortIcon } from './sort-icon.svg'
import { ReactComponent as ChunkIcon } from './chunk-icon.svg'
import { ReactComponent as CheckIcon } from './check-icon.svg'
import { ReactComponent as CrossIcon } from './cross-icon.svg'
import { ReactComponent as PhoneIcon } from './phone-icon.svg'
import { ReactComponent as ReturnIcon } from './location-icon.svg'
import { ReactComponent as MessagesIcon } from './messages-icon.svg'
import { ReactComponent as ArrowIcon } from './arrow.svg'
import { ReactComponent as GiftIcon } from './gift.svg'
import { ReactComponent as FoodIcon } from './food.svg'
import { ReactComponent as DogizCoinIcon } from './dogiz-coin.svg'
import { ReactComponent as PoopIcon } from './poop.svg'
import { ReactComponent as TimerIcon } from './timer.svg'
import { ReactComponent as ToiletIcon } from './toilet.svg'
import { ReactComponent as WaterIcon } from './water.svg'
import { ReactComponent as ArrowRightIcon } from './arrow-right.svg'
import { ReactComponent as ClientIcon } from './client-icon.svg'
import { ReactComponent as DetailsButtonIcon } from './detailsButtonIcon.svg'
import { ReactComponent as BackButtonIcon } from './back-btn-icon.svg'
import { ReactComponent as DeleteIcon } from './delete-icon.svg'
import { ReactComponent as EditIcon } from './edit.svg'
import { ReactComponent as DocumentIcon } from './document-icon.svg'
import { ReactComponent as DownloadIcon } from './download-icon.svg'
import { ReactComponent as EyeIcon } from './eye-icon.svg'
import { ReactComponent as KeyIcon } from './key-icon.svg'
import { ReactComponent as StaffIcon } from './staff-icon.svg'
import { ReactComponent as PlayIcon } from './play.svg'
import { ReactComponent as Chevron } from './arrowDownIcon.svg'
import { ReactComponent as SingleOrderIcon } from './single-order.svg'
import { ReactComponent as RecuuringOrderIcon } from './cicled-arrow.svg'
import { ReactComponent as PlusIcon } from './plus.svg'
import { ReactComponent as SuccessIcon } from './success.svg'
import { ReactComponent as FailedIcon } from './failed.svg'
import { ReactComponent as EditOutlinedIcon } from './edit-outlined.svg'
import { ReactComponent as MaleIcon } from './male-icon.svg'
import { ReactComponent as FemaleIcon } from './female-icon.svg'
import { ReactComponent as Cross16x16 } from './cross-16x16.svg'
import { ReactComponent as CheckGrey16x16 } from './check-grey-16x16.svg'

import UserPhoto from './userPhoto.svg'

export {
  ArrowUpIcon,
  ClientIcon,
  PlayIcon,
  ReturnIcon,
  PhoneIcon,
  StaffIcon,
  MessagesIcon,
  FiltersIcon,
  CheckIcon,
  ChunkIcon,
  EyeIcon,
  LogoBigIcon,
  CrossIcon,
  KeyIcon,
  OrdersIcon,
  SearchIcon,
  SortIcon,
  LocationIcon,
  BellIcon,
  MapIcon,
  BurgerIcon,
  CalendarIcon,
  ArrowIcon,
  GiftIcon,
  ArrowRightIcon,
  WaterIcon,
  ToiletIcon,
  TimerIcon,
  PoopIcon,
  DogizCoinIcon,
  FoodIcon,
  UserPhoto,
  DetailsButtonIcon,
  BackButtonIcon,
  DeleteIcon,
  EditIcon,
  DocumentIcon,
  DownloadIcon,
  Chevron,
  RecuuringOrderIcon,
  SingleOrderIcon,
  PlusIcon,
  SuccessIcon,
  FailedIcon,
  EditOutlinedIcon,
  MaleIcon,
  FemaleIcon,
  CheckGrey16x16,
  Cross16x16,
}
