// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../assets/images/Background.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".styles_wrapper__eyJIO {\n  height: 100vh;\n  width: 100vw;\n  display: grid;\n  grid-template: 1fr/1fr 352px;\n  overflow: hidden;\n}\n\n.styles_map__xp5c0 {\n  overflow: hidden;\n  background: center/cover no-repeat url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n\n.styles_form__Glq91 {\n  display: flex;\n  flex-direction: column;\n  box-shadow: 0px 0px 70px rgba(67, 113, 143, 0.537449);\n  padding: 40px 36px 16px;\n}", "",{"version":3,"sources":["webpack://./src/components/loyauts/auth/styles.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,aAAA;EACA,4BAAA;EACA,gBAAA;AACF;;AAEA;EACE,gBAAA;EACA,0EAAA;AACF;;AAGA;EACE,aAAA;EACA,sBAAA;EACA,qDAAA;EACA,uBAAA;AAAF","sourcesContent":[".wrapper {\r\n  height: 100vh;\r\n  width: 100vw;\r\n  display: grid;\r\n  grid-template: 1fr / 1fr 352px;\r\n  overflow: hidden;\r\n}\r\n\r\n.map {\r\n  overflow: hidden;\r\n  background: center/cover no-repeat\r\n    url('../../../assets/images/Background.png');\r\n}\r\n\r\n.form {\r\n  display: flex;\r\n  flex-direction: column;\r\n  box-shadow: 0px 0px 70px rgba(67, 113, 143, 0.537449);\r\n  padding: 40px 36px 16px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "styles_wrapper__eyJIO",
	"map": "styles_map__xp5c0",
	"form": "styles_form__Glq91"
};
export default ___CSS_LOADER_EXPORT___;
