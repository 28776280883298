const getItem = (storageName: LocalStorageKeys) => {
  return localStorage.getItem(storageName)
}

const getItemJSON = <T>(storageName: LocalStorageKeys): T | null => {
  const val = localStorage.getItem(storageName)
  if (!val) return null
  return JSON.parse(val)
}

const setItem = (storageName: LocalStorageKeys, values: string) => {
  return localStorage.setItem(storageName, values)
}

const removeItem = (storageName: LocalStorageKeys) => {
  return localStorage.removeItem(storageName)
}

type LocalStorageKeys = keyof typeof LocalStorage

export enum LocalStorage {
  auth = 'auth',
  language = 'language',
  fcm = 'fcm',
  ordersFilters = 'orderFilters',
  paramsForGettiingOrderList = 'paramsForGettiingOrderList',
}

export { getItem, setItem, removeItem, getItemJSON }
