import { Image } from 'react-bootstrap'
import React, { MouseEventHandler } from 'react'
import { Text } from '..'
import s from './styles.module.scss'
import { setActivitiesToMapboxFormat } from '../../pages/walk-details/components/live-map/live-map.utils'
import { STATIC_IMAGES } from '../../services/api/api.config'
import cn from 'classnames'
import { useDispatch } from 'react-redux'
import { modalActions } from '../modal/modal.actions'
type Props = {
  name: string
  src?: string
  isStaticImage?: boolean
  className?: string
  isScalable?: boolean
}

export const Avatar = ({
  name,
  src,
  className,
  isStaticImage = false,
  isScalable = false,
}: Props) => {
  const imageSrc = isStaticImage ? `${STATIC_IMAGES}${src}` : src

  const dispatch = useDispatch()

  const handleClick: MouseEventHandler = (e) => {
    e.stopPropagation()
    dispatch(
      modalActions.openModal({
        type: 'image-zoomer',
        backdropColor: 'dark',
        details: {
          data: imageSrc,
        },
      })
    )
  }

  return src ? (
    <Image
      className={cn([s.avatar, className, { [s.scalable]: isScalable }])}
      src={imageSrc}
      onClick={(e) => {
        if (isScalable) {
          handleClick(e)
        } else {
          return
        }
      }}
    />
  ) : (
    <div className={cn([s.placeholder, className])}>
      <Text size="24" color="base">
        {name[0] ? name[0].toUpperCase() : ''}
      </Text>
    </div>
  )
}
