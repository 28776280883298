import { OrderPageStatuses } from './../../../pages/orders/constants'
import { CreateWorkerReq } from './types'
import { get, post, put } from '../../api'
import { WORKER_API } from '../../api/api.config'
import { AppDispatch } from '../../../store/store'
import { toastActions } from '../../../components/toast/toast.slice'
import { NavigateFunction } from 'react-router-dom'
import { IService } from '../../../app-types/entities-types/service.types'
import { OrderStatuses } from '../orders/constants'
import moment from 'moment'
import { IWorker } from '../../../app-types/entities-types/worker.types'

export const getWorkerByID = async (id: string) => {
  const url = '/worker/' + id
  try {
    const { data } = await get(url, { baseURL: WORKER_API })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
export const getAllPartnerWorkers = async ({
  id,
  params,
}: {
  id: string
  params?: {
    pageNumber: string | number
    pageSize: string | number
    workerName?: string
    workerId?: string | string[]
    showOnlyInactiveWorkers?: boolean
  }
}) => {
  const url = `/worker/${id}/partner-workers`
  try {
    const { data } = await get<{ result: IWorker[]; totalCount: number }>(url, {
      baseURL: WORKER_API,
      params,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getWorkerOrders = async ({
  id,
  params,
}: {
  id: string
  params: {
    status: keyof typeof OrderPageStatuses
    pageNumber: string | number
    pageSize: string | number
    sort?: 'name' | 'time' | 'service' | 'date' | 'walker' | 'price'
    forSort?: 1 | -1
  }
}) => {
  let paramsForReq: any = params
  if (params.status === OrderStatuses.ACCEPTED) {
    paramsForReq = {
      status: [OrderStatuses.ACCEPTED, OrderStatuses.LIVE],
    }
  }
  if (params.status === OrderPageStatuses.HISTORY) {
    paramsForReq = {
      status: [
        OrderStatuses.DECLINE_BY_SERVICE,
        OrderStatuses.DECLINE_BY_USER,
        OrderStatuses.CANCEL_BY_USER,
        OrderStatuses.CANCEL_BY_SERVICE,
      ],
    }
  }
  if (params.status === OrderPageStatuses.HISTORY) {
    paramsForReq = {
      status: [OrderStatuses.CANCEL_BY_USER_AFTER_8, OrderStatuses.FINISHED],
    }
  }
  if (params.status === OrderPageStatuses.TODAY) {
    const today = moment().startOf('day').format('YYYY-MM-DD')

    paramsForReq = {
      dateFrom: today,
      dateTo: today,
      status: [
        OrderStatuses.ACCEPTED,
        OrderStatuses.PENDING,
        OrderStatuses.LIVE,
      ],
    }
  }

  paramsForReq = { ...params, ...paramsForReq }

  const url = `/worker/${id}/orders`
  try {
    const { data } = await get(url, {
      baseURL: WORKER_API,
      params: paramsForReq,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
export const getWorkerTypes = async () => {
  const url = '/worker-type/'
  try {
    const { data } = await get(url, { baseURL: WORKER_API })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
export const getWorkerCountries = async ({
  params,
}: {
  params: { country: string }
}) => {
  const url = '/worker/countries'
  try {
    const { data } = await get(url, { baseURL: WORKER_API, params })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
export const getWorkerCities = async ({
  country,
  city,
}: {
  country?: string
  city?: string
}) => {
  const url = '/worker/cities'
  try {
    const { data } = await get(url, {
      baseURL: WORKER_API,
      params: { country, city },
    })
    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const createWorker = async (
  {
    body,
    navigate,
  }: { body: CreateWorkerReq | {}; navigate: NavigateFunction },
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = `/worker/`
  try {
    const { status } = await post(url, body, { baseURL: WORKER_API })

    if (status === 200) {
      dispatch(
        toastActions.showToast({
          key: 'worker-create',
          type: 'success',
        })
      )
      navigate('../staff')
    }
  } catch (err: any) {
    dispatch(
      toastActions.showToast({
        key: 'worker-create',
        type: 'failed',
      })
    )
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const updateWorker = async (
  {
    id,
    body,
    navigate,
  }: { id: string; body: CreateWorkerReq | {}; navigate: NavigateFunction },
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = '/worker/' + id
  try {
    const { status } = await put(url, body, { baseURL: WORKER_API })

    if (status === 200) {
      dispatch(
        toastActions.showToast({
          key: 'worker-update',
          type: 'success',
        })
      )
      navigate('../staff')
    }
  } catch (err: any) {
    dispatch(
      toastActions.showToast({
        key: 'worker-update',
        type: 'failed',
      })
    )
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const softDeleteWorker = async (
  reqBody: { workerId: string },
  dispatch: AppDispatch,
  navigate: NavigateFunction
) => {
  const url = `/worker/soft-delete`
  try {
    const { status } = await put(url, reqBody, { baseURL: WORKER_API })

    if (status === 200) {
      dispatch(
        toastActions.showToast({
          key: 'worker-delete',
          type: 'success',
        })
      )
      navigate('../staff')
    }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    dispatch(
      toastActions.showToast({
        key: 'worker-delete',
        type: 'failed',
      })
    )
    throw { errorData, status }
  }
}

export const getWorkerServices = async () => {
  const url = '/worker/services'
  try {
    const {
      data: { services },
    } = await get<{ services: IService[] }>(url, { baseURL: WORKER_API })
    return services
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const changeWorkerActive = async (body: {
  workerId: string
  inactive: boolean
}) => {
  const url = '/worker/change-inactive'

  try {
    await put(url, body, { baseURL: WORKER_API })
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
