import { OrderTotalCountsRes } from './../../services/requests/orders/types'
import { MapCoord } from './../../services/requests/map/types'
import {
  getOrdersThunk,
  getOrderByIDThunk,
  resolveOrdersThunk,
  resolveOrderByIDThunk,
  resolveUpdateOrderStatusThunk,
  updateOrderStatusThunk,
  updateOrderWorkerThunk,
  resolveUpdateOrderWorkerThunk,
  resolveGroupsThunk,
  getOrdersByGroupsThunk,
  resolveUpdateBulkOrderStatusThunk,
  updateBulkOrderStatusThunk,
  resolveUpdateDiscountThunk,
  updateDiscountThunk,
  getCountsThunk,
  resolveGetCountsThunk,
  resolveGetMapCoordsBySearchThunk,
  getMapCoordsBySearchThunk,
  resolveUpdateBulkWorkerThunk,
  updateBulkWorkerThunk,
  resolveGetTotalCountsThunk,
  getTotalCountsThunk,
  updateAdminNoteThunk,
  resolveUpdateAdminNoteThunk,
  resolveResetOrderThunk,
  resetOrderThunk,
  resolveGetOrderAfterPushReceivedThunk,
  getOrderAfterPushReceivedThunk,
  bulkResetOrdersThunk,
} from './orders.thunks'
import type { GroupOrders, Order, OrdersThunkConfig } from './types'
import { createSlice, createEntityAdapter, Dictionary } from '@reduxjs/toolkit'
import { OrderStatuses } from '../../services/requests/orders/constants'
import {
  cleanupOrders,
  resolveCleanupOrders,
  resolveToggleBulk,
  toggleBulk,
  selectBulk,
  cleanupMap,
  selectAllBulk,
  clearBulkState,
  resolveSelectAllBulk,
  resolveClearBulkState,
  resolveSelectBulk,
  resolveCleanupMap,
  setFilters,
  clearFilters,
  resolveSetFilters,
  resolveClearFilters,
} from './orders.actions'

const orderAdapterConfig = {
  selectId: (order: Order) => order._id,
}

export const ordersDismissedAdapter =
  createEntityAdapter<Order>(orderAdapterConfig)

export const ordersPendingAdapter =
  createEntityAdapter<Order>(orderAdapterConfig)

export const ordersTodayAdapter = createEntityAdapter<Order>(orderAdapterConfig)

export const ordersAcceptedAdapter =
  createEntityAdapter<Order>(orderAdapterConfig)

export const ordersHistoryAdapter =
  createEntityAdapter<Order>(orderAdapterConfig)

export const orderGroupsAdapter = createEntityAdapter<GroupOrders>({
  selectId: (group) => group.groupId,
})

const orderState = {
  loading: null as null | boolean,
  totalCount: null as null | number,
  error: null as null | string,
}

export interface IOrderFilters {
  orderId: string[] | undefined
  clientId: string[] | undefined
  dogId: string[] | undefined
  workerId: string[] | undefined
  serviceTypeId: string[] | undefined
  startDate: string | undefined
  endDate: string | undefined
}

const initialState = {
  selectedOrder: null as null | Order,
  loading: null as null | boolean,
  totalCountsLoading: null as null | boolean,
  totalCounts: null as OrderTotalCountsRes | null,
  countsLoading: null as null | boolean,
  error: null as null | string,
  bulk: [] as Order['id'][][],
  isBulk: false,
  allBulkTrigger: false,
  map: {
    inOrders: {} as Dictionary<MapCoord>,
    loading: null as null | boolean,
  },
  counts: null as null | {
    pending: number
    accepted: number
    live: number
    history: number
    total: number
  },
  groups: {
    ...orderGroupsAdapter.getInitialState({
      loading: null as null | boolean,
      error: null as null | string,
    }),
  },
  orders: {
    pending: {
      ...ordersPendingAdapter.getInitialState({
        ...orderState,
      }),
    },
    dismissed: {
      ...ordersDismissedAdapter.getInitialState({
        ...orderState,
      }),
    },
    today: {
      ...ordersTodayAdapter.getInitialState({
        ...orderState,
      }),
    },
    accepted: {
      ...ordersAcceptedAdapter.getInitialState({
        ...orderState,
      }),
    },
    history: {
      ...ordersHistoryAdapter.getInitialState({
        ...orderState,
      }),
    },
  },
  filters: {
    orderId: undefined as string[] | undefined,
    clientId: undefined as string[] | undefined,
    dogId: undefined as string[] | undefined,
    workerId: undefined as string[] | undefined,
    serviceTypeId: undefined as string[] | undefined,
    startDate: undefined as string | undefined,
    endDate: undefined as string | undefined,
  },
}

export const ordersThunkConfig: OrdersThunkConfig = {
  [OrderStatuses.PENDING]: {
    adapter: ordersPendingAdapter,
    prop: 'pending',
  },
  DISMISSED: {
    adapter: ordersDismissedAdapter,
    prop: 'dismissed',
  },
  [OrderStatuses.ACCEPTED]: {
    adapter: ordersAcceptedAdapter,
    prop: 'accepted',
  },
  TODAY: {
    adapter: ordersTodayAdapter,
    prop: 'today',
  },
  HISTORY: {
    adapter: ordersHistoryAdapter,
    prop: 'history',
  },
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resolveCleanupMap(builder)
    resolveGetMapCoordsBySearchThunk(builder)
    resolveUpdateDiscountThunk(builder)
    resolveGetCountsThunk(builder)
    resolveGroupsThunk(builder)
    resolveSelectAllBulk(builder)
    resolveToggleBulk(builder)
    resolveSelectBulk(builder)
    resolveCleanupOrders(builder)
    resolveOrdersThunk(builder)
    resolveOrderByIDThunk(builder)
    resolveUpdateOrderStatusThunk(builder)
    resolveUpdateOrderWorkerThunk(builder)
    resolveUpdateBulkOrderStatusThunk(builder)
    resolveUpdateBulkWorkerThunk(builder)
    resolveGetTotalCountsThunk(builder)
    resolveUpdateAdminNoteThunk(builder)
    resolveResetOrderThunk(builder)
    resolveGetOrderAfterPushReceivedThunk(builder)
    resolveSetFilters(builder)
    resolveClearFilters(builder)
    builder.addCase(bulkResetOrdersThunk.pending, (state) => {
      state.loading = true
    })
    builder.addCase(bulkResetOrdersThunk.fulfilled, (state, { payload }) => {
      const { ids, pageStatus } = payload
      const { adapter, prop } = ordersThunkConfig[pageStatus]
      adapter.removeMany(state.orders[prop], ids)
      state.loading = false
    })
    builder.addCase(bulkResetOrdersThunk.rejected, (state, action) => {
      state.error = action.error.message as string | null
      state.loading = false
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    })
    resolveClearBulkState(builder)
  },
})

export type OrdersState = typeof initialState

export const ordersReducer = ordersSlice.reducer

export const ordersActions = {
  cleanup: cleanupOrders,
  cleanupMap,
  selectBulk,
  toggleBulk,
  selectAllBulk,
  clearBulkState,
  setFilters,
  clearFilters,
}

export const ordersApi = {
  getOrdersByGroups: getOrdersByGroupsThunk,
  getTotalCounts: getTotalCountsThunk,
  getOrders: getOrdersThunk,
  getOrderByID: getOrderByIDThunk,
  getCounts: getCountsThunk,
  getMapCoordsBySearch: getMapCoordsBySearchThunk,
  updateDiscount: updateDiscountThunk,
  updateBulkOrderStatus: updateBulkOrderStatusThunk,
  updateBulkOrderWorker: updateBulkWorkerThunk,
  updateStatus: updateOrderStatusThunk,
  updateWorker: updateOrderWorkerThunk,
  updateAdminNote: updateAdminNoteThunk,
  resetOrder: resetOrderThunk,
  bulkResetOrders: bulkResetOrdersThunk,
  getOrderAfterPushReceived: getOrderAfterPushReceivedThunk,
}
