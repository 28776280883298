import { StylesConfig } from 'react-select'
import { ThemeColors } from '../../../app-types/_enums/colors'

export interface ISuperSelectOption<L = string | number, V = string | number> {
  label: L
  value: V
}

export const selectStyles: StylesConfig = {
  option: (base, state) => ({
    ...base,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: '14px',
    lineHeight: '18px',
    padding: '9px 12px',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    backgroundColor: 'transparent',
    color: ThemeColors['neutral-black'],
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    overflowX: 'hidden',

    '&:hover': {
      backgroundColor: ThemeColors['neutral-light-gray'],
    },
    '&:before': {
      content: '""',
      display: 'flex',
      minWidth: '16px',
      height: '16px',
      border: `2px solid ${ThemeColors['primary-green-70']}`,
      borderRadius: '50%',
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      left: 12 + 4 + 'px',
      top: 'calc(50% - 4px)',
      display: 'flex',
      width: '8px',
      height: '8px',
      backgroundColor: state.isSelected
        ? ThemeColors['primary-green-70']
        : 'transparent',
      borderRadius: '50%',
    },
  }),

  dropdownIndicator: (base, state) => ({
    ...base,
    transition: 'all 0.15s linear',
    transformOrigin: 'center center',
    transform: state.selectProps.menuIsOpen ? 'rotateX(180deg)' : 'rotateX(0)',
    '&>svg': {
      fill: ThemeColors['dashboard-neo-gray-900'],
      height: '12px',
      width: '12px',
    },
  }),

  clearIndicator: (base) => ({
    ...base,
    '&>svg': {
      fill: ThemeColors['dashboard-neo-gray-900'],
    },
  }),

  input: (base) => ({
    ...base,
    fontSize: '14px',
    fontFamily: 'Roboto',
    fontWeight: 400,
  }),

  placeholder: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
    padding: '4px',
    fontWeight: 400,
    color: ThemeColors['neutral-gray-50'],
  }),

  valueContainer: (base) => ({
    ...base,
    display: 'flex',
    flexWrap: 'nowrap',
    alignItems: 'center',
    overflowX: 'auto',
    margin: '0 0 0 4px',
    padding: '0',
    '::-webkit-scrollbar-thumb': {
      backgroundColor: ThemeColors['primary-green-70'],
      borderRadius: '1px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: ThemeColors['neutral-gray-30'],
      borderRadius: '1px',
    },

    '::-webkit-scrollbar': {
      height: '2px',
    },
  }),

  singleValue: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
    lineHeight: '14px',
    fontWeight: 400,
    padding: '0 8px',
  }),

  multiValue: (base) => ({
    ...base,
    display: 'flex',
    minWidth: 'min-content',
    maxWidth: '100px',
    backgroundColor: ThemeColors['neutral-light-gray'],
    marginRight: '4px',
  }),

  multiValueLabel: (base) => ({
    ...base,
    maxWidth: '100px',
    fontFamily: 'Roboto',
    fontSize: '14px',
    fontWeight: 400,
  }),

  multiValueRemove: (base) => ({
    ...base,
    cursor: 'pointer',
    transition: 'all 0.1s linear',
    '&>svg': {
      fill: ThemeColors['secondary-red-100'],
    },
    '&:hover': {
      backgroundColor: ThemeColors['neutral-gray-30'],
    },
  }),

  menu: (base) => ({
    ...base,
    zIndex: 10,
  }),

  menuList: (base) => ({
    ...base,
    zIndex: 100,
    '::-webkit-scrollbar-thumb': {
      backgroundColor: ThemeColors['primary-green-70'],
      borderRadius: '2px',
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: ThemeColors['neutral-gray-30'],
      borderRadius: '2px',
    },

    '::-webkit-scrollbar': {
      width: '4px',
    },
  }),

  noOptionsMessage: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
  }),

  loadingIndicator: (base) => ({
    ...base,
    padding: '0 4px',
    gap: '2px',
    '&>span': {
      width: '4px',
      height: '4px',
      '&:nth-of-type(n)': {
        marginLeft: '2px',
      },
    },
  }),

  loadingMessage: (base) => ({
    ...base,
    fontFamily: 'Roboto',
    fontSize: '14px',
  }),
}
