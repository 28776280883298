import {
  changeWorkerActiveThunk,
  createWorkerThunk,
  getAllPartnerWorkersThunk,
  getWorkerByIdThunk,
  getWorkerOrdersThunk,
  resolveAllPartnerWorkersThunk,
  resolveCrateWorkerThunk,
  resolveUpdateWorkerThunk,
  resolveWorkerByIdThunk,
  resolveWorkerOrdersThunk,
  updateWorkerThunk,
} from './staff.thunks'
import {
  clearFilters,
  resolveClearFilters,
  setFilters,
  resolveSetFilters,
  clearSelectedWorker,
} from './staff.actions'
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit'
import { Order } from '../orders/types'
import { IWorker } from '../../app-types/entities-types/worker.types'

const workerAdapterConfig = {
  selectId: (worker: IWorker) => worker._id,
}
export const staffAdapter = createEntityAdapter<IWorker>(workerAdapterConfig)

export interface IStaffFilters {
  workerId: string | undefined
  workerName: string | undefined
}

const initialState = {
  selectedWorker: null as any | IWorker,
  loading: null as null | boolean,
  baseLoading: null as null | boolean,
  error: null as null | string,
  workers: {
    workers: null as null | Array<IWorker>,
    total: null as null | number,
  },
  walksLoading: null as null | boolean,
  walks: {
    walks: null as null | Order[],
    total: null as null | number,
  },
  filters: {
    workerId: undefined,
    workerName: undefined,
  } as IStaffFilters,
}

const staffSlice = createSlice({
  name: 'staff',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    resolveWorkerByIdThunk(builder)
    resolveAllPartnerWorkersThunk(builder)
    resolveCrateWorkerThunk(builder)
    resolveUpdateWorkerThunk(builder)
    resolveWorkerOrdersThunk(builder)
    resolveSetFilters(builder)
    resolveClearFilters(builder)

    builder.addCase(clearSelectedWorker, (state) => {
      state.selectedWorker = null
    })
  },
})

export type staffState = typeof initialState

export const staffReducer = staffSlice.reducer
export const staffActions = {
  setFilters,
  clearFilters,
  clearSelectedWorker,
}

export const staffApi = {
  getWorkerByID: getWorkerByIdThunk,
  getAllPartnerWorkers: getAllPartnerWorkersThunk,
  createWorker: createWorkerThunk,
  updateWorker: updateWorkerThunk,
  getWorkerOrders: getWorkerOrdersThunk,
  changeWorkerActive: changeWorkerActiveThunk,
}
