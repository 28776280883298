import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { debounce } from '../helpers'

import { ISuperSelectOption } from '../components/forms/super-select/utils'
import { IState } from '../store/states/states.types'
import { statesApi, statesSelectors } from '../store/states/states.slice'

const mapStatesToSelectOptions = (
  states: Array<IState> | null
): Array<ISuperSelectOption> => {
  return states
    ? states.map(({ _id, name }) => ({ label: name, value: _id }))
    : []
}

export const useStatesOptions = (countryId: string) => {
  const dispatch = useDispatch()
  const { data, isLoading, error } = useSelector(statesSelectors.getAllState)

  const [searchedState, setSearchedState] = useState('')

  const onStateSearch = useCallback(
    debounce((value: string) => {
      setSearchedState(value)
    }, 800),
    []
  )

  useEffect(() => {
    if (!countryId || !searchedState) return
    dispatch(
      statesApi.getStates({
        params: { country: countryId, state: searchedState },
      })
    )
  }, [searchedState, countryId, dispatch])

  return {
    onStateSearch,
    states: mapStatesToSelectOptions(data),
    isLoading,
    isError: error,
  }
}
