import s from './styles.module.scss'

type Props = {
  accepted: number
  pending: number
  live: number
  history: number
  total: number
}

const TodaysOrder = ({ accepted, pending, live, history, total }: Props) => {
  return (
    <div className={s['todays-order']}>
      <p className={s['todays-order__title']}>Today`s orders: {total}</p>
      <div className={s['todays-order__content']}>
        <div className={s['todays-order__row']}>
          <div className={s['todays-order__metrics']}>
            <p className={s['todays-order__metrics__title']}>Pending:</p>
            <p className={s['todays-order__metrics__value--pending']}>
              {pending}
            </p>
          </div>
          <div className={s['todays-order__metrics']}>
            <p className={s['todays-order__metrics__title']}>Accepted:</p>
            <p className={s['todays-order__metrics__value--accepted']}>
              {accepted}
            </p>
          </div>
        </div>

        <div className={s['todays-order__row']}>
          <div className={s['todays-order__metrics']}>
            <p className={s['todays-order__metrics__title']}>Live:</p>
            <p className={s['todays-order__metrics__value--live']}>{live}</p>
          </div>
          <div className={s['todays-order__metrics']}>
            <p className={s['todays-order__metrics__title']}>History:</p>
            <p className={s['todays-order__metrics__value--live']}>{history}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TodaysOrder
