import { createSlice } from '@reduxjs/toolkit'
import { resetCountries } from './countries.actions'
import { countriesSelectors as selectors } from './countries.selectors'
import { getCountriesThunk } from './countries.thunks'

import { ICountry } from './countries.types'

const initialState = {
  isLoading: false,
  error: '',
  data: null as null | Array<ICountry>,
  total: 0,
}

export const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCountriesThunk.pending, (state) => {
      state.error = ''
      state.total = 0
      state.isLoading = true
    })

    builder.addCase(getCountriesThunk.fulfilled, (state, { payload }) => {
      state.error = ''
      state.data = payload.countries
      state.total = payload.countries.length
      state.isLoading = false
    })

    builder.addCase(getCountriesThunk.rejected, (state, { payload }) => {
      state.error = payload?.errorData.message || 'failed'
      state.data = null
      state.total = 0
      state.isLoading = false
    })

    builder.addCase(resetCountries, () => {
      return initialState
    })
  },
})

export const countriesReducer = countriesSlice.reducer

export const countriesSelectors = selectors

export const countriesActions = {
  reset: resetCountries,
}

export const countriesApi = {
  getCountries: getCountriesThunk,
}
