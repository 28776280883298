import { Order } from './types'
import {
  IOrderFilters,
  orderGroupsAdapter,
  OrdersState,
  ordersThunkConfig,
} from './orders.slice'
import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit'
import type { OrderReqStatus } from '../../services/requests/orders/types'

export const cleanupOrders = createAction<{
  status: OrderReqStatus
}>('orders/cleanup')

export const resolveCleanupOrders = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(cleanupOrders, (state, action) => {
    const { adapter, prop } = ordersThunkConfig[action.payload.status]
    adapter.removeAll(state.orders[prop])
    state.isBulk = false
    state.bulk = []
    orderGroupsAdapter.removeAll(state.groups)
  })
}

export const toggleBulk = createAction<{ isBulk: boolean }>('orders/bulk')

export const resolveToggleBulk = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(toggleBulk, (state, action) => {
    const { isBulk } = action.payload
    state.isBulk = isBulk
    if (!isBulk) {
      state.bulk = []
    }
  })
}

export const selectAllBulk = createAction<{
  ids: Order['id'][]
  pageNumber: number
}>('orders/select-bulk-all')

export const resolveSelectAllBulk = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(selectAllBulk, (state, { payload }) => {
    const { ids, pageNumber } = payload
    if (state.bulk[pageNumber]) {
      if (ids.length > 0) {
        state.bulk[pageNumber] = ids
      } else {
        state.bulk[pageNumber] = []
      }
    } else {
      state.bulk[pageNumber] = ids
    }
  })
}

export const clearBulkState = createAction('orders/clear-bulk-state')

export const resolveClearBulkState = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(clearBulkState, (state) => {
    state.bulk = []
    state.isBulk = false
  })
}

export const selectBulk = createAction<{ id: Order['id']; pageNumber: number }>(
  'orders/select-bulk'
)

export const resolveSelectBulk = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(selectBulk, (state, { payload }) => {
    const { id, pageNumber } = payload
    if (state.bulk[pageNumber]) {
      if (state.bulk[pageNumber].includes(id)) {
        state.bulk[pageNumber] = state.bulk[pageNumber].filter((i) => i !== id)
      } else {
        state.bulk[pageNumber] = [...state.bulk[pageNumber], id]
      }
    } else {
      state.bulk[pageNumber] = [id]
    }
  })
}

export const cleanupMap = createAction('orders/map')

export const resolveCleanupMap = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(cleanupMap, (state) => {
    state.map.inOrders = {}
    state.map.loading = null
  })
}

export const setFilters = createAction<IOrderFilters>('orders/set-filters')

export const resolveSetFilters = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(setFilters, (state, action) => {
    state.filters = action.payload
  })
}

export const clearFilters = createAction('orders/clear-filters')

export const resolveClearFilters = (
  builder: ActionReducerMapBuilder<OrdersState>
) => {
  builder.addCase(clearFilters, (state) => {
    state.filters = {
      clientId: undefined,
      dogId: undefined,
      orderId: undefined,
      serviceTypeId: undefined,
      workerId: undefined,
      startDate: undefined,
      endDate: undefined,
    }
  })
}
