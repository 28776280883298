import { PopUpSizeType, PositionType } from './order-popup.types'

export const calculatePopupPosition = (
  popUpSize: PopUpSizeType,
  eventPosition: PositionType
) => {
  const documentHeight = document.body.clientHeight
  const documentWidth = document.body.clientWidth

  let popUpX, popUpY
  //calculating X
  if (documentWidth - eventPosition.x <= popUpSize.width) {
    popUpX = documentWidth - popUpSize.width - 103
  } else if (eventPosition.x - 273 <= popUpSize.width / 2) {
    popUpX = 283
  } else {
    popUpX = eventPosition.x - popUpSize.width / 2
  }
  //calculating Y
  if (documentHeight - eventPosition.y <= popUpSize.height + 10) {
    popUpY = eventPosition.y - popUpSize.height - 50
  } else {
    popUpY = eventPosition.y + 30
  }

  return { x: popUpX, y: popUpY }
}
