import { AppDispatch } from '../../../store/store'
import React, { EventHandler, useState } from 'react'
import { Accordion, Image, useAccordionButton } from 'react-bootstrap'
import { Text } from '../../../components'

import s from '../styles.module.scss'
import { ArrowUpIcon, OrdersIcon } from '../../../assets/icons'
import cn from 'classnames'
import { MenuData } from '../constants'

import { Link, useNavigate } from 'react-router-dom'

type Props = {
  eventKey: string
  isActive: boolean
  setCollapsed: React.Dispatch<React.SetStateAction<string>>
  collapsed: string
  item: MenuData[number]
  onClick: (activeEventKey: string) => void
}

export const MenuItem = ({
  eventKey,
  onClick,
  collapsed,
  item,
  setCollapsed,
  isActive = false,
}: Props) => {
  const { icon, title, tabs, link } = item
  const navigate = useNavigate()
  const [activeTab, setActiveTab] = useState(() => (isActive ? 0 : -1))

  const decoratedOnClick = useAccordionButton(eventKey, (e) => {
    setCollapsed((prev) => (prev !== eventKey ? eventKey : '-1'))
    if (link) {
      onClick(eventKey)
      navigate(link)
    }
  })

  return (
    <>
      <div
        onClick={decoratedOnClick}
        role="button"
        className={cn([
          'd-flex align-items-center',
          s.item,
          { [s.active]: isActive },
          { [s.collapsed]: collapsed !== eventKey },
        ])}
      >
        {icon}
        <Text
          size="14"
          className={s.menuText}
          family="roboto-slab"
          color={isActive ? 'base-major' : 'secondary-minor'}
        >
          {title}
        </Text>
        {tabs && <ArrowUpIcon className={cn([s.arrow])} />}
      </div>
      <Accordion.Collapse eventKey="0">
        <>
          {tabs &&
            tabs.map(({ title, count, link }, ind) => (
              <Link
                key={title}
                to={link}
                role="button"
                onClick={() => {
                  setActiveTab(ind)
                  onClick(eventKey)
                }}
                className={cn([
                  'd-flex',
                  s.tab,
                  { [s.activeTab]: isActive && ind === activeTab },
                ])}
              >
                <Text size="14">{title}</Text>
                {count && (
                  <Text size="14" className={s.count}>
                    {count}
                  </Text>
                )}
              </Link>
            ))}
        </>
      </Accordion.Collapse>
    </>
  )
}
