import { AuthType } from './../../store/auth/types'
import axios from 'axios'

import { BASE_URL, WORKER_API } from './api.config'
import { getItemJSON, setItem } from '../../helpers/local-storage'
import { Headers } from '.'
// multipart
export const formDataHeaders = {
  accept: '*/*',
  ContentType: 'multipart/form-data',
  'Access-Control-Allow-Origin': '*',
}

// for multiple requests
let isRefreshing = false
let failedQueue = [] as any[]

// failed requests queue
const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error)
    } else {
      prom.resolve(token)
    }
  })

  failedQueue = []
}

const apiClient = axios.create({
  baseURL: BASE_URL,
})

apiClient.interceptors.request.use(
  (config: any) => {
    /*
     * Setup headers
     * */
    const token = getItemJSON<AuthType>('auth')
    const contentType = config.ContentType
      ? config.ContentType
      : 'application/json'
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token.accessToken
    }
    config.headers['Access-Control-Allow-Credentials'] = true
    config.headers['Content-Type'] = contentType

    /*
     * Decrement "pageNumber" because backend pagination starts from 0
     * */
    const params = config.params

    if (params && params.pageNumber) {
      params.pageNumber -= 1
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  }
)

apiClient.interceptors.response.use(
  (response) => {
    return response
  },
  function (error) {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject })
        })
          .then((token) => {
            originalRequest.headers['Authorization'] = 'Bearer ' + token
            return axios(originalRequest)
          })
          .catch((err) => {
            return Promise.reject(err)
          })
      }

      originalRequest._retry = true
      isRefreshing = true

      const token = getItemJSON<AuthType>('auth')
      if (token)
        axios.defaults.headers.common['Authorization'] =
          'Bearer ' + token.refreshToken

      return new Promise(function (resolve, reject) {
        axios
          .post(
            WORKER_API + '/auth/update-token',
            {
              refreshToken: token?.refreshToken,
            },
            { headers: Headers.Auth }
          )
          .then((res) => {
            if (res.status === 200) {
              setItem('auth', JSON.stringify(res.data.auth))

              axios.defaults.headers.common['Authorization'] =
                'Bearer ' + res.data.auth.accessToken
              originalRequest.headers['Authorization'] =
                'Bearer ' + res.data.auth.accessToken
              processQueue(null, res.data.auth.accessToken)
              resolve(axios(originalRequest))
            }
          })
          .catch((err) => {
            processQueue(err, null)
            reject(err)
            post('auth/logout', '', {
              baseURL: WORKER_API,
              headers: Headers.Auth,
            })
            localStorage.clear()
            window.location.replace('/login')
          })
          .finally(() => {
            isRefreshing = false
          })
      })
    }
    return Promise.reject(error)
  }
)

const { get, post, put, delete: destroy } = apiClient

export { get, post, put, destroy }
export { Headers } from './api.config'
