import { UnboxPromise } from './../../helpers/types'
import {
  getPartnerClients,
  getClientById,
  getClientOrders,
  addCoinsByPartner,
  getClientSubscriptions,
  cancelClientSubscription,
  changeClientAddress,
} from './../../services/requests/clients/clients'
import { clientsState } from './clients.slice'
import { createThunkResolver } from '../../helpers/index'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import {
  IAddCoinsByPartnerReq,
  IUpdateClientAddressReq,
} from '../../services/requests/clients/types'
import { IResponseError } from '../../app-types/response-types/response-error'
import { IClientSubsription } from '../../app-types/entities-types/clients.types'
import { ICancelClientSubscriptionReq } from '../../services/requests/clients/types'
import { toastActions } from '../../components/toast/toast.slice'

// get partner clients
export const getPartnerClientsThunk = createAsyncThunk(
  'clients/GET_PARTNER_CLIENTS',
  getPartnerClients
)
const getPartnerClientsThunkResolver = createThunkResolver<
  clientsState,
  UnboxPromise<ReturnType<typeof getPartnerClients>>
>(getPartnerClientsThunk)

export const resolvePartnerClients = (
  builder: ActionReducerMapBuilder<clientsState>
) =>
  getPartnerClientsThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.clients.clients = [...action.payload.result]
      state.clients.total = action.payload.totalCount
    })
    .onRejected()

// get client by id
export const getClientByIdThunk = createAsyncThunk(
  'clients/GET_CLIENT_BY_ID',
  getClientById
)
const getClientByIdThunkResolver = createThunkResolver<
  clientsState,
  UnboxPromise<ReturnType<typeof getClientById>>
>(getClientByIdThunk)

export const resolveClientById = (
  builder: ActionReducerMapBuilder<clientsState>
) =>
  getClientByIdThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.selectedClient = action.payload
    })
    .onRejected()

// get client orders
export const getClientOrdersThunk = createAsyncThunk(
  'clients/GET_CLIENT_ORDERS',
  getClientOrders
)
const getClientOrdersResolver = createThunkResolver<
  clientsState,
  UnboxPromise<ReturnType<typeof getClientOrders>>
>(getClientOrdersThunk, 'walksLoading')

export const resolveClientOrders = (
  builder: ActionReducerMapBuilder<clientsState>
) =>
  getClientOrdersResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      state.clientWalks.walks = [...action.payload.result]
      state.clientWalks.total = action.payload.totalCount
    })
    .onRejected()

export const addCoinsByPartnerThunk = createAsyncThunk<
  undefined,
  IAddCoinsByPartnerReq,
  { rejectValue: IResponseError }
>(
  'clients/ADD_COINS_BY_PARTNER',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      await addCoinsByPartner(args)

      dispatch(getClientByIdThunk(args.userId))
    } catch (error) {
      return rejectWithValue(error as IResponseError)
    }
  }
)

export const getClientSubscriptionsThunk = createAsyncThunk<
  IClientSubsription[],
  string,
  { rejectValue: IResponseError }
>('clients/GET_CLIENT_SUBSCRIPTIONS', async (args, { rejectWithValue }) => {
  try {
    const subscriptions = await getClientSubscriptions(args)
    return subscriptions
  } catch (error) {
    return rejectWithValue(error as IResponseError)
  }
})

export const cancelClientSubscriptionThunk = createAsyncThunk<
  any,
  ICancelClientSubscriptionReq,
  { rejectValue: IResponseError }
>(
  'clients/CANCEL_CLIENT_SUBSCRIPTIONS',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      await cancelClientSubscription(args)
      dispatch(
        toastActions.showToast({
          key: 'client-subscription-cancelling',
          type: 'success',
        })
      )
      return args.subscriptionId
    } catch (error) {
      dispatch(
        toastActions.showToast({
          key: 'client-subscription-cancelling',
          type: 'failed',
        })
      )

      return rejectWithValue(error as IResponseError)
    }
  }
)

export const changeClientAddressThunk = createAsyncThunk<
  any,
  IUpdateClientAddressReq,
  { rejectValue: IResponseError }
>(
  'clients/CHANGE_CLIENT_ADDRESS',
  async (args, { dispatch, rejectWithValue }) => {
    try {
      await changeClientAddress(args)

      dispatch(
        toastActions.showToast({
          key: 'change-client-address',
          type: 'success',
        })
      )

      dispatch(getClientByIdThunk(args.id))
    } catch (error) {
      dispatch(
        toastActions.showToast({
          key: 'change-client-address',
          type: 'failed',
        })
      )

      return rejectWithValue(error as IResponseError)
    }
  }
)
