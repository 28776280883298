import en from './en.json'
import clientsPage from './clients-page.json'
import staffPage from './staff-page.json'
import general from './general.json'
import modals from './modals.json'
import orders from './orders.json'
import toasts from './toasts.json'

const translation = {
  ...en,
  ...general,
  ...clientsPage,
  ...staffPage,
  ...modals,
  ...orders,
  ...toasts
}
export default translation
