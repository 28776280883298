import { createAsyncThunk } from '@reduxjs/toolkit'
import { toastActions } from '../../components/toast/toast.slice'

import { IResponseError } from '../../app-types/response-types/response-error'
import { IGetStatesReq, IGetStatesRes } from './states.types'
import { getStates } from './states.requests'

export const getStatesThunk = createAsyncThunk<
  IGetStatesRes,
  IGetStatesReq,
  { rejectValue: IResponseError }
>('states', async (args, { dispatch, rejectWithValue }) => {
  try {
    const data = await getStates(args)
    return data
  } catch (error) {
    dispatch(
      toastActions.showToast({
        key: 'states-status-get',
        type: 'failed',
      })
    )

    return rejectWithValue(error as IResponseError)
  }
})
