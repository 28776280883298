import { getWorkers } from './../../services/requests/workers/workers'
import { UpdatedStatusRes } from '../../services/requests/orders/types'
import { UnboxPromise } from '../../helpers/types'
import { createThunkResolver } from '../../helpers/index'
import { ActionReducerMapBuilder, createAsyncThunk } from '@reduxjs/toolkit'
import { OrderStatuses } from '../../services/requests/orders/constants'
import { workersAdapter, WorkersState } from './worker.slice'

export const geWorkersThunk = createAsyncThunk('workers/by-partner', getWorkers)
const getWorkersThunkResolver = createThunkResolver<
  WorkersState,
  UnboxPromise<ReturnType<typeof getWorkers>>
>(geWorkersThunk)

export const resolveWorkersThunk = (
  builder: ActionReducerMapBuilder<WorkersState>
) =>
  getWorkersThunkResolver(builder)
    .onPending()
    .onFulfilled((state, action) => {
      workersAdapter.setAll(state, action.payload)
    })
    .onRejected()
