import moment from 'moment'
import { AxiosResponse } from 'axios'

import { modalActions } from './../../../components/modal/modal.actions'
import { ordersApi } from './../../../pages/orders/orders.slice'
import { Order } from './../../../pages/orders/types'
import {
  GetOrdersByGroupIdsReq,
  GetOrdersByGroupIdsRes,
  GetOrdersByGroupReq,
  GetOrdersReq,
  ICreateNewOrderReq,
  IGetOrderPriceCounterReq,
  IGetOrderPriceCounterRes,
  IResetBulkOrderReq,
  IResetOrderReq,
  OrderCountsRes,
  OrdersPaginationRes,
  OrdersRes,
  OrderTotalCountsRes,
  UpdatedBulkOrderStatusReq,
  updateDiscountReq,
  UpdatedStatusReq,
  UpdatedStatusRes,
  UpdatedWorkerBulkReq,
  UpdatedWorkerReq,
} from './types'
import { get, post, put } from '../../api'
import { OrderStatuses } from './constants'
import { BASE_URL, WORKER_API } from '../../api/api.config'
import { AppDispatch, AppThunkApi } from '../../../store/store'
import { toastActions } from '../../../components/toast/toast.slice'
import { OrderPageStatuses } from '../../../pages/orders/constants'

export const getOrders = async ({
  pageNumber,
  status = OrderStatuses.PENDING,
  pageSize = 50,
  sort = 'date',
  forSort = 1,
  startDate,
  endDate,
  clientId,
  dogId,
  orderId,
  workerId,
  serviceTypeId,
}: GetOrdersReq) => {
  let params: any = { status }

  if (status === OrderStatuses.ACCEPTED) {
    params = {
      status: [OrderStatuses.ACCEPTED, OrderStatuses.LIVE],
    }
  }
  if (status === 'DISMISSED') {
    params = {
      status: [
        OrderStatuses.DECLINE_BY_SERVICE,
        OrderStatuses.DECLINE_BY_USER,
        OrderStatuses.CANCEL_BY_USER,
        OrderStatuses.CANCEL_BY_SERVICE,
      ],
    }
  }
  if (status === 'HISTORY') {
    params = {
      status: [OrderStatuses.CANCEL_BY_USER_AFTER_8, OrderStatuses.FINISHED],
    }
  }
  if (status === 'TODAY') {
    const today = moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')
    const tomorrow = moment()
      .startOf('day')
      .add(1, 'day')
      .format('yyyy-MM-DDTHH:mm:ss')

    params = {
      startDate: today,
      endDate: tomorrow,
      status: [
        OrderStatuses.ACCEPTED,
        OrderStatuses.PENDING,
        OrderStatuses.LIVE,
      ],
    }
  }
  if ((startDate || endDate) && status !== 'TODAY') {
    params = {
      ...params,
      pageSize,
      pageNumber,
      sort,
      forSort,
      startDate: moment(startDate).format('yyyy-MM-DDTHH:mm:ss'),
      endDate: moment(endDate).add(1, 'day').format('yyyy-MM-DDTHH:mm:ss'),
    }
  }
  params = {
    ...params,
    pageSize,
    pageNumber,
    sort,
    forSort,
    clientId,
    dogId,
    workerId,
    orderId,
    serviceTypeId,
  }

  const url = `/order`
  const { data } = await get<OrdersPaginationRes>(url, {
    baseURL: WORKER_API,
    params,
  })

  return { data, status }
}

export const getOrderByID = async (orderID: string | undefined) => {
  const url = `/order/${orderID}`
  const { data } = await get<Order>(url, { baseURL: WORKER_API })

  return data
}

export const updateOrderStatus = async (
  {
    orderId,
    updatedStatus,
    oldStatus,
    pageStatus,
    groupId = null,
    isGroupCard,
    cancelReason,
  }: UpdatedStatusReq,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url =
    groupId && isGroupCard
      ? `/order/status/group/${groupId}`
      : `/order/status/${orderId}`

  let reqData: any = {}
  if (updatedStatus === OrderStatuses.LIVE)
    reqData = { liveDate: new Date().toISOString() }
  if (updatedStatus === OrderStatuses.FINISHED)
    reqData = { endDate: new Date().toISOString() }
  if (groupId && oldStatus) reqData.statusToUpdate = oldStatus

  reqData = { ...reqData, status: updatedStatus, cancelReason }

  const { data } = await put<UpdatedStatusRes>(url, reqData, {
    baseURL: WORKER_API,
  })

  dispatch(
    toastActions.showToast({
      key: 'order-status-update',
      type: 'success',
    })
  )
  // if status updating from modal window
  dispatch(modalActions.closeModal())

  const updatedOrder = await getOrderByID(orderId)

  return {
    data,
    orderId,
    status: updatedStatus,
    groupId,
    isGroupCard,
    pageStatus,
    updatedOrder,
  }
}

export const updateBulkOrderStatus = async (
  { orders, pageStatus, groups, cancelReason }: UpdatedBulkOrderStatusReq,
  { dispatch, getState }: AppThunkApi
) => {
  const url = '/order/status'

  const requests = [] as Promise<AxiosResponse<UpdatedStatusRes, any>>[]

  orders.forEach(({ orderIds, updatedStatus }) => {
    let reqData: any = {}
    if (updatedStatus === OrderStatuses.LIVE)
      reqData = { liveDate: new Date().toISOString() }
    if (updatedStatus === OrderStatuses.FINISHED)
      reqData = { endDate: new Date().toISOString() }
    reqData = { ...reqData, status: updatedStatus, cancelReason }

    const params = { id: orderIds }

    requests.push(
      put<UpdatedStatusRes>(url, reqData, {
        params,
        baseURL: WORKER_API,
      })
    )
  })

  groups &&
    groups.forEach(({ groupId, updatedStatus }) => {
      const groupUrl = `/order/status/group/${groupId}`

      let reqData: any = {}
      if (updatedStatus === OrderStatuses.LIVE)
        reqData = { liveDate: new Date().toISOString() }
      if (updatedStatus === OrderStatuses.FINISHED)
        reqData = { endDate: new Date().toISOString() }
      reqData = { ...reqData, status: updatedStatus, cancelReason }

      requests.push(
        put<UpdatedStatusRes>(groupUrl, reqData, {
          baseURL: WORKER_API,
        })
      )
    })

  await Promise.all(requests)

  dispatch(
    ordersApi.getOrders({
      status: pageStatus,
      pageNumber: 0,
      ...getState().orders.filters,
    })
  )

  dispatch(
    toastActions.showToast({
      key: 'order-status-update',
      type: 'success',
    })
  )
}

export const updateOrderWorker = async (
  { orderId, workerId, groupId, pageStatus }: UpdatedWorkerReq,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = groupId
    ? `/order/worker/group/${groupId}`
    : `/super-admin/order/worker/${orderId}`
  const { data } = await put<null>(
    url,
    { worker: workerId },
    { baseURL: groupId ? WORKER_API : BASE_URL }
  )

  let updatedOrderGroup:
    | {
        groupId: string
        orders: OrdersRes
        master: string | undefined
      }[]
    | null = null

  if (groupId) {
    updatedOrderGroup = await getOrdersByGroupIds({
      groupMasters: { [groupId]: orderId },
      pageStatus,
    })
  }

  dispatch(
    toastActions.showToast({
      key: 'order-worker-update',
      type: 'success',
    })
  )

  return { data, orderId, updatedOrderGroup: updatedOrderGroup?.[0] }
}

export const updateBulkOrderWorker = async (
  { orderIds, pageStatus, pageNumber, workerId }: UpdatedWorkerBulkReq,
  { dispatch, getState }: AppThunkApi
) => {
  const requests = [] as Promise<AxiosResponse<null, any>>[]

  orderIds.forEach((orderId) => {
    let req = { url: `/super-admin/order/worker/${orderId}`, api: BASE_URL }
    requests.push(
      put<null>(req.url, { worker: workerId }, { baseURL: req.api })
    )
  })

  let isError = false
  try {
    await Promise.all(requests)
  } catch (error) {
    isError = true
  }

  dispatch(
    toastActions.showToast({
      key: 'order-worker-update',
      type: isError ? 'failed' : 'success',
    })
  )

  await dispatch(
    ordersApi.getOrders({
      status: pageStatus,
      pageNumber,
      ...getState().orders.filters,
    })
  )

  return { pageStatus }
}

export const getOrdersByGroup = async ({
  groupMasters,
  pageStatus,
}: GetOrdersByGroupReq) => {
  const url = `/order/`
  const requests = [] as Promise<AxiosResponse<OrdersRes, any>>[]

  let params: any
  if (pageStatus === OrderStatuses.PENDING) {
    params = {
      status: OrderStatuses.PENDING,
    }
  }
  if (pageStatus === OrderStatuses.ACCEPTED) {
    params = {
      status: [OrderStatuses.ACCEPTED, OrderStatuses.LIVE],
    }
  }
  if (pageStatus === 'DISMISSED') {
    params = {
      status: [
        OrderStatuses.DECLINE_BY_SERVICE,
        OrderStatuses.DECLINE_BY_USER,
        OrderStatuses.CANCEL_BY_USER,
        OrderStatuses.CANCEL_BY_SERVICE,
      ],
    }
  }
  if (pageStatus === 'HISTORY') {
    params = {
      status: [OrderStatuses.CANCEL_BY_USER_AFTER_8, OrderStatuses.FINISHED],
    }
  }
  if (pageStatus === 'TODAY') {
    const today = moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')
    const tomorrow = moment()
      .startOf('day')
      .add(1, 'day')
      .format('yyyy-MM-DDTHH:mm:ss')

    params = {
      startDate: today,
      endDate: tomorrow,
      status: [
        OrderStatuses.ACCEPTED,
        OrderStatuses.PENDING,
        OrderStatuses.LIVE,
      ],
    }
  }

  const groupIds = Object.keys(groupMasters)

  groupIds.forEach((groupId) => {
    const reqParams = { groupId, ...params }

    requests.push(
      get<OrdersRes>(url, { baseURL: WORKER_API, params: reqParams })
    )
  })

  const responses = await Promise.all(requests)

  const groups = responses.map((ordersByGroup, ind) => {
    return {
      groupId: groupIds[ind],
      orders: ordersByGroup.data,
      master: groupMasters[groupIds[ind]],
    }
  })

  return groups
}

export const updateDiscount = async (
  { orderId, discount, reasonId, pageStatus }: updateDiscountReq,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = `/order/discount/${orderId}`

  const { status } = await put<null>(
    url,
    {
      coins: discount,
      reasonId,
    },
    {
      baseURL: WORKER_API,
    }
  )

  if (status !== 204) {
    dispatch(
      toastActions.showToast({
        key: 'order-discount-update',
        type: 'failed',
      })
    )
  }

  const updatedOrder = await getOrderByID(orderId)

  dispatch(
    toastActions.showToast({
      key: 'order-discount-update',
      type: 'success',
    })
  )

  return { orderId, discount, pageStatus, updatedOrder }
}

export const getOrderCounts = async () => {
  const url = `/worker/partner-order-count`

  const { data } = await get<OrderCountsRes>(url, {
    baseURL: WORKER_API,
  })

  return { ...data }
}

export const getOrderTotalCounts = async () => {
  const url = `/order/order-counts`

  const { data } = await get<OrderTotalCountsRes>(url, {
    baseURL: WORKER_API,
  })

  return data
}

export const updateAdminNote = async (
  {
    orderId,
    pageStatus,
    adminNote,
  }: {
    orderId: string
    adminNote: string
    pageStatus: string
  },
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = 'super-admin/order/admin-note/' + orderId
  const { status } = await put(url, { adminNote })

  if (status !== 200 && status !== 204) {
    dispatch(
      toastActions.showToast({
        key: 'order-admin-note-update',
        type: 'failed',
      })
    )
  }

  const updatedOrder = await getOrderByID(orderId)

  dispatch(
    toastActions.showToast({
      key: 'order-admin-note-update',
      type: 'success',
    })
  )
  return { orderId, pageStatus, updatedOrder }
}

export const getOrderPriceCounter = async (body: IGetOrderPriceCounterReq) => {
  const url = '/order/counter-price'

  try {
    const { data } = await post<IGetOrderPriceCounterRes>(url, body, {
      baseURL: WORKER_API,
    })

    return data
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const createNewOrder = async (body: ICreateNewOrderReq) => {
  const url = '/order'
  try {
    await post(url, body, { baseURL: WORKER_API })
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const resetOrder = async (
  { id, pageStatus }: IResetOrderReq,
  { dispatch }: { dispatch: AppDispatch }
) => {
  const url = '/order/reset/' + id
  try {
    const { status } = await put(url, undefined, { baseURL: WORKER_API })

    if (status !== 200 && status !== 204) {
      dispatch(
        toastActions.showToast({
          key: 'order-reseting',
          type: 'failed',
        })
      )
    } else {
      dispatch(
        toastActions.showToast({
          key: 'order-reseting',
          type: 'success',
        })
      )
    }

    return { id, pageStatus }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const bulkResetOrders = async ({
  ids,
  pageStatus,
}: IResetBulkOrderReq) => {
  const url = '/order/reset/'

  try {
    const requests = [] as Promise<AxiosResponse<any, any>>[]

    ids.forEach((id) => {
      requests.push(put<any>(url + id, undefined, { baseURL: WORKER_API }))
    })

    await Promise.all(requests)

    return { ids, pageStatus }
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}

export const getOrderAfterPushReceived = async ({
  orderId,
  pageStatus,
  groupId,
}: {
  orderId: string
  groupId: string | undefined
  pageStatus: keyof typeof OrderPageStatuses
}) => {
  if (
    !orderId &&
    groupId &&
    window.location.pathname.includes(pageStatus.toLowerCase())
  ) {
    setTimeout(() => {
      window.location.reload()
    }, 1000)
  } else {
    const url = `/order/${orderId}`
    const { data } = await get<Order>(url, { baseURL: WORKER_API })
    return { data, pageStatus }
  }
}

export const getOrdersByGroupIds = async ({
  groupMasters,
  pageStatus,
  sort = 'date',
  forSort = 1,
}: GetOrdersByGroupIdsReq) => {
  let params: any

  if (pageStatus === OrderStatuses.PENDING) {
    params = {
      status: OrderStatuses.PENDING,
    }
  }
  if (pageStatus === OrderStatuses.ACCEPTED) {
    params = {
      status: [OrderStatuses.ACCEPTED, OrderStatuses.LIVE],
    }
  }
  if (pageStatus === 'DISMISSED') {
    params = {
      status: [
        OrderStatuses.DECLINE_BY_SERVICE,
        OrderStatuses.DECLINE_BY_USER,
        OrderStatuses.CANCEL_BY_USER,
        OrderStatuses.CANCEL_BY_SERVICE,
      ],
    }
  }
  if (pageStatus === 'HISTORY') {
    params = {
      status: [OrderStatuses.CANCEL_BY_USER_AFTER_8, OrderStatuses.FINISHED],
    }
  }
  if (pageStatus === 'TODAY') {
    const today = moment().startOf('day').format('yyyy-MM-DDTHH:mm:ss')
    const tomorrow = moment()
      .startOf('day')
      .add(1, 'day')
      .format('yyyy-MM-DDTHH:mm:ss')

    params = {
      startDate: today,
      endDate: tomorrow,
      status: [
        OrderStatuses.ACCEPTED,
        OrderStatuses.PENDING,
        OrderStatuses.LIVE,
      ],
    }
  }

  params = { ...params, groupId: Object.keys(groupMasters), sort, forSort }

  const url = '/order/orders-by-group-ids'

  try {
    const { data } = await get<GetOrdersByGroupIdsRes>(url, {
      baseURL: WORKER_API,
      params,
    })
    const groups = Object.keys(data).map((group) => {
      return {
        groupId: group,
        orders: data[group],
        master: groupMasters[group],
      }
    })

    return groups
  } catch (err: any) {
    const { data: errorData, status } = err.response
    throw { errorData, status }
  }
}
