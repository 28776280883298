import uniqid from 'uniqid'

const generateDeviceID = () => {
  const deviceID = uniqid()

  localStorage.setItem('deviceID', deviceID)

  return deviceID
}

const staticImages = 'static/images/'
const BASE_URL = process.env.REACT_APP_API_URL as string
const STATIC_IMAGES: string = `${process.env.REACT_APP_API}/${staticImages}`
const WORKER_API = BASE_URL + '/app-worker'
const RSA_PUBLIC_KEY = process.env.REACT_APP_RSA_PUBLIC_KEY?.replace(
  /\\n/g,
  '\n'
) as string

const Headers = {
  Auth: {
    'Content-Type': 'application/json',
    accept: 'application/x-www-form-urlencoded',
    platform: 'WEB',
    deviceId: localStorage.getItem('deviceID') || generateDeviceID(),
  },
}

export { BASE_URL, STATIC_IMAGES, WORKER_API, Headers, RSA_PUBLIC_KEY }
