import { t } from 'i18next'
import { AriaRole, FC, MutableRefObject } from 'react'

type Props = {
  children: string
  elRef?: MutableRefObject<HTMLParagraphElement>
  className?: string
  onClick?: () => void
  weight?: 'bold' | 'normal' | '500'
  size?: '12' | '13' | '14' | '24' | '16'
  family?: 'roboto' | 'roboto-slab'
  role?: AriaRole
  isNoTranslate?: boolean
  color?:
    | 'main'
    | 'base'
    | 'secondary'
    | 'secondary-minor'
    | 'base-major'
    | 'alt'
    | 'alt-major'
    | 'point'
    | 'inactive'
    | 'inactive-major'
    | 'neutral-grey-90'
    | 'primary-purple-100'
}

export const Text = ({
  children,
  elRef,
  isNoTranslate = false,
  onClick,
  className = '',
  size = '13',
  weight = 'normal',
  role,
  family = 'roboto',
  color = 'main',
}: Props) => {
  return (
    <p
      role={role}
      ref={elRef}
      onClick={onClick}
      className={`${className} c-${color} ff-${family} fs-${size}px fw-${weight}`}
    >
      {isNoTranslate ? children : t(children)}
    </p>
  )
}
