import { BaseThunkAPI } from '@reduxjs/toolkit/dist/createAsyncThunk'
import { configureStore } from '@reduxjs/toolkit'
import { createSelectorCreator } from 'reselect'

import rootReducer from './root-reducer'

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: true,
      serializableCheck: false,
    }),
})

// export const store = createStore(
//     rootReducer,
//     composeWithDevTools(applyMiddleware(thunk))
// );

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch

export type AppThunkApi = BaseThunkAPI<RootState, any, AppDispatch>
